import { Directive, HostListener, Input, SimpleChanges } from '@angular/core';
import { NgControl, NgModel } from '@angular/forms';
import { filter, first } from 'rxjs/operators';
import { LocalService } from 'src/services/local.service';
import { LoggingService } from 'src/services/logging.service';

@Directive({
  selector: '[persistLocal]',
})
export class persistLocal {
  @Input() persistLocal: string;

  private magicObject = { magic: 101 };

  constructor(private control: NgControl, private local: LocalService, private _model: NgModel, private log: LoggingService) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.local
      .isReady()
      .pipe(
        filter((ready) => ready),
        first()
      )
      .subscribe(() => {
        if (!this.persistLocal) return;
        const init = this.local.get(this.persistLocal, this.magicObject);
        if (init !== this.magicObject) {
          const val = this.parseValue(init);
          setTimeout(() => {
            //give us a time slot to reconfig this control!
            this.control.valueAccessor.writeValue(val);
            this.control.viewToModelUpdate(val);
          }, 1);
        }
      });
  }

  private formatDate(d: Date) {
    return '__//DATE//' + d.toISOString();
  }

  private parseValue(v) {
    return typeof v === 'string' && v.startsWith('__//DATE//') ? new Date(v.split('__//DATE//')[1]) : v;
  }

  ngOnInit() {
    this._model.valueChanges.subscribe((value) => {
      if (value instanceof Date) {
        this.local.set(this.persistLocal, this.formatDate(value));
      } else {
        this.local.set(this.persistLocal, value);
      }
    });
  }

  @HostListener('ngModelChange', ['$event'])
  ngModelChange(value: any) {
    if (value instanceof Date) {
      this.local.set(this.persistLocal, this.formatDate(value));
    } else {
      this.local.set(this.persistLocal, value);
    }
  }
}
