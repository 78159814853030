import dayjs from 'dayjs';
import clone from 'fast-clone';
import { Observable, timer } from 'rxjs';
import { catchError, delay, first, mergeMap } from 'rxjs/operators';

const _mEnum = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];

export const monthList = [
  { code: 'JAN', label: 'January' },
  { code: 'FEB', label: 'February' },
  { code: 'MAR', label: 'March' },
  { code: 'APR', label: 'April' },
  { code: 'MAY', label: 'May' },
  { code: 'JUN', label: 'June' },
  { code: 'JUL', label: 'July' },
  { code: 'AUG', label: 'August' },
  { code: 'SEP', label: 'September' },
  { code: 'OCT', label: 'October' },
  { code: 'NOV', label: 'November' },
  { code: 'DEC', label: 'December' },
];

export enum CalendarLength {
  DAY = 0,
  MON_WEEK = 1,
  SUN_WEEK = 2,
  MONTH = 3,
}

/**
 * copy of the definition from the backend
 */
export enum DepartmentName {
  FRONT_OFFICE = 'frontOffice',
  UNIFORMED_SERVICES = 'uniformedServices',
  CLUB_LOUNGE = 'clubLounge',
  HOUSEKEEPING = 'housekeeping',
  LAUNDRY = 'laundry',
  RESTAURANTS = 'restaurants',
  RETAIL_OUTLETS = 'retailOutlets',
  OUTSIDE_CATERING = 'outsideCatering',
  ROOM_SERVICE = 'roomService',
  BARS = 'bars',
  MINI_BARS = 'miniBars',
  KITCHEN = 'kitchen',
  STEWARDING = 'stewarding',
  C_AND_E = 'cAndE',
  LEISURE_AND_SPA = 'leisureAndSpa',
  ENGINEERING = 'engineering',
  SWITCHBOARD = 'switchboard',
  FINANCE = 'finance',
  IT = 'it',
  EXECUTIVE_OFFICE = 'executiveOffice',
  PURCHASING = 'purchasing',
  RESERVATIONS = 'reservations',
  SECURITY = 'security',
  HUMAN_RESOURCES = 'humanResources',
  SALES_AND_MARKETING = 'salesAndMarketing',
  MICE_SALES = 'miceSales',
  F_AND_B = 'foodAndBeverage',
  EMPLOYEE_CAFETERIA = 'employeeCafeteria',
  EMPLOYEE_HOUSING = 'employeeHousing',
  MARINE = 'marine',
  OTHER = 'other',
  UNKNOWN = 'unknown',
}

/**
 * Check whether a department is open, an outsourced department is only fully closed if ratio of outsourcing (outsourceRatio) is non-zero
 * @param departmentConfig
 * @returns
 */
const departmentIsOpen = ({ enabled, outsourced, outsourceRatio }: { enabled: boolean; outsourced?: boolean; outsourceRatio?: number }) =>
  enabled && (outsourced ? outsourceRatio > 0 : true);

export interface IDepartmentConfig {
  departmentName: string;
  enabled: boolean;
  outsourced?: boolean;
  outsourceRatio?: number;
  servedBy?: { departmentName: string; outletIndex?: number; outletType? };
  subDeptConfig?: {
    kitchen: boolean;
    stewarding: boolean;
    kitchenServedBy?: { departmentName: string; outletIndex?: number; outletType? };
    stewardingServedBy?: { departmentName: string; outletIndex?: number; outletType? };
  };
}

export const createDeptExistsFn = (departmentConfig: IDepartmentConfig[]) => {
  const config = departmentConfig.reduce((prev, { departmentName, ...entry }) => {
    prev.set(departmentName, entry);
    return prev;
  }, new Map<string, Omit<IDepartmentConfig, 'departmentName'>>());
  return (departmentName: string, ignoreOutlets = false) => {
    if (DepartmentName[departmentName] === DepartmentName.UNKNOWN) {
      return false;
    }
    if (
      DepartmentName[departmentName] === DepartmentName.RESTAURANTS ||
      DepartmentName[departmentName] === DepartmentName.BARS ||
      DepartmentName[departmentName] === DepartmentName.RETAIL_OUTLETS
    ) {
      return !ignoreOutlets;
    }
    const entry = config.get(departmentName);
    if (!entry) {
      return false;
    }
    if (departmentIsOpen(entry)) {
      if (entry.servedBy) {
        return entry.servedBy.departmentName === departmentName && !entry.servedBy.outletIndex && !entry.servedBy.outletType;
      }
      return true;
    }
    return false;
  };
};

export const createSubDeptGetFn = (departmentConfig: IDepartmentConfig[]) => {
  const config = departmentConfig.reduce((prev, { departmentName, ...entry }) => {
    prev.set(departmentName, entry);
    return prev;
  }, new Map<string, Omit<IDepartmentConfig, 'departmentName'>>());
  return (departmentName: string) => {
    if (DepartmentName[departmentName] === DepartmentName.UNKNOWN) {
      return {};
    }
    if (DepartmentName[departmentName] === DepartmentName.RESTAURANTS || DepartmentName[departmentName] === DepartmentName.BARS) {
      return {};
    }
    const entry = config.get(departmentName);
    if (!entry) {
      return {};
    }
    if (departmentIsOpen(entry) && entry.subDeptConfig) {
      if (entry.servedBy) {
        if (entry.servedBy.departmentName !== departmentName || entry.servedBy.outletIndex || entry.servedBy.outletType) {
          return {};
        }
      }
      return {
        kitchen: entry.subDeptConfig.kitchen && !entry.subDeptConfig.kitchenServedBy,
        stewarding: entry.subDeptConfig.stewarding && !entry.subDeptConfig.stewardingServedBy,
      };
    }
    return {};
  };
};

export const orderByOutlet = (a: { outletType?; outletIndex?: number }, b: { outletType?; outletIndex?: number }) => {
  if (a.outletType && b.outletType) {
    if (a.outletType === b.outletType) {
      return a.outletIndex < b.outletIndex ? -1 : 1;
    } else if (a.outletType === 'RESTAURANT') {
      return -1;
    }
    return 1;
  } else if (!a.outletType && b.outletType) {
    return -1;
  }
  return 1;
};

/**
 * partial copy of the definition from the backend
 */
export enum Right {
  READ = 'read',
  WRITE = 'write',
  APPROVE = 'approve',
}

/**
 * partial copy of the definition from the backend
 */
export enum Scope {
  REGION_ADMIN = 'region_admin',
  SCHEDULE = 'sched',
  SCHEDULE_ACTUAL = 'sched_act',
  SCHEDULE_ADMIN = 'sched_admin',
  SCHEDULE_CAPTURE = 'sched_cap',
  SCHEDULE_FORECAST = 'sched_fore',
  SCHEDULE_MANNING = 'sched_man',
  SCHEDULE_USER = 'sched_user',
  USER = 'user_admin',
}

export const cloneShifts = (shifts) => {
  return shifts.map((s) => cloneShift(s));
};

export const cloneShift = (shift) => {
  return {
    absenceType: shift.absenceType,
    breaks: shift.breaks,
    capturedShifts: deepClone(shift.capturedShifts),
    comments: shift.comments,
    departmentLabel: shift.departmentLabel,
    departmentName: shift.departmentName,
    subDeptName: shift.subDeptName,
    end: shift.end,
    hotel: shift.hotel,
    id: shift.id,
    otherDep: shift.otherDep,
    otherHotel: shift.otherHotel,
    outletIndex: shift.outletIndex,
    outletType: shift.outletType,
    personId: shift.personId,
    postApprovalUpdate: shift.postApprovalUpdate,
    quantity: shift.quantity,
    rowOffset: shift.rowOffset,
    shiftDay: dayjs(shift.shiftDay),
    shiftEndDay: dayjs(shift.shiftEndDay),
    start: shift.start,
    type: shift.type,
    updatedAfterSnapshot: shift.updatedAfterSnapshot,
  };
};

export const mapMonthEnum = (m: number) => _mEnum[m - 1];

export const mapMonthEnumToNumber = (m: string) => _mEnum.findIndex((d) => d == m);

/**
 * This is optomized for object which do not have circular references
 * and will fail if it receives an object with a circular reference
 * @param object
 * @returns deep clones object
 */
export const deepClone = <T>(object: T): T => clone(object);

export const dayLength = 24 * 60 * 60 * 1000;

export const dayOnly = (d) => +d - (+d % dayLength);

/** add a random delau before calling the observable */
export const randomDelayObservable = <T>(obs: Observable<T>, maxDelayMs = 500) =>
  timer(maxDelayMs * Math.random()).pipe(
    first(),
    mergeMap(() => obs)
  );

/** call observable and retry on first failure */
export const runAndRetry = <T>(obs: Observable<T>, delayInitialCall = false, maxDelayMs = 500) => {
  const newObs = obs.pipe(catchError(() => randomDelayObservable(obs, maxDelayMs)));
  return delayInitialCall ? randomDelayObservable(newObs, maxDelayMs) : newObs;
};
