import { Component, OnInit, Output, Input, EventEmitter, forwardRef, OnDestroy } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs';
import { RegionDataService } from './../../services/region-data.service';

@Component({
  selector: 'app-role-drop-down',
  templateUrl: './role-drop-down.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RoleDropDownComponent),
      multi: true,
    },
  ],
})
export class RoleDropDownComponent implements ControlValueAccessor, OnInit, OnDestroy {
  constructor(private data: RegionDataService) {}

  @Output() onChange = new EventEmitter<any>();
  @Input() disabled = false;
  @Input() showClear = false;
  @Input() placeholder = 'Select a Role';
  @Input() includeDisabled = false;

  private role$: Subscription;
  private propagateChange = null;
  roles: any[] = [];
  selectedRole: any = null;
  showLoading = false;

  writeValue(value: any) {
    this.selectedRole = value;
  }

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched() {}

  ngOnInit() {
    this.showLoading = true;
    this.role$ = this.data.getRoles(this.includeDisabled).subscribe((result) => {
      this.roles = result;
      this.showLoading = false;
    });
  }

  ngOnDestroy() {
    if (this.role$) {
      this.role$.unsubscribe();
      this.role$ = undefined;
    }
  }

  select() {
    this.propagateChange(this.selectedRole);
    this.onChange.emit(this.selectedRole);
  }
}
