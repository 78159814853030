import dayjs from 'dayjs';

export interface FullDepartment {
  hotelId?: string;
  name: string;
  class: string;
  label: string;
  subDeptName?: 'KITCHEN' | 'STEWARDING';
  outletIndex: number | null;
  outletType: 'BAR' | 'RESTAURANT' | 'RETAIL' | null;
  defaultOrder: number;
}

export interface ManningRole {
  name: string;
  label: string;
  jobBand: number;
}

export interface ManningContract {
  id: string;
  role: ManningRole;
  jobBand: number;
  payRate?: number;
  hotelId?: string;
  holidexCode?: string;
  department: FullDepartment | null;
  otherDepartments: FullDepartment[] | null;
  start: Date;
  end: Date;
  contractedHours: number;
  title: string;
  workDays: number;
  isActive: boolean;
  isValid: boolean;
  deleteMe?: boolean;
  casual: boolean;
  overlap?: boolean;
  isOpen?: boolean;
}

export interface ManningPerson {
  accountId: string;
  hasAccount: boolean;
  accountStatus: 'Yes' | 'No';
  accountEnabled: 'Yes' | 'No';
  id: string;
  firstName: string;
  lastName: string;
  email?: string;
  checkInId?: string;
  holidayDays: number;
  publicHolidayDays: number;
  sickDays: number;
  contracts: ManningContract[];
  activeContract?: ManningContract;
  activeDepartmentLabel?: string;
  activeRoleLabel?: string;
  activeContractHours?: number;
  activeWorkDays?: number;
  activeJobBand?: number;
  activeTitle?: string;
  allContractsValid: boolean;
  leavingDate?: Date;
  contractsEndDate?: Date;
  leaveStartDate?: Date;
  leaveEndDate?: Date;
  leaving: boolean;
  statusText: string;
  personnelNo?: string;
  hotelId?: string;
  holidexCode?: string;
  agency: boolean;
}

export const calculateManningContracts = (p: ManningPerson) => {
  p.allContractsValid = true;
  p.contracts = [...p.contracts.sort((a, b) => (a.start && a.start < b.start ? -1 : 1))];
  p.contractsEndDate = p.contracts?.length && p.contracts[p.contracts.length - 1].end;
  // check out the validity on each contract which is not being deleted
  const contracts = p.contracts.filter((c) => !c.deleteMe);
  if (p.agency) {
    p.holidayDays = 0;
    p.sickDays = 0;
  }
  let start: Date;
  let newStart: Date;
  contracts.forEach((c) => {
    c.isValid = !!(c.role && c.department && c.start != null && c.title);
    if (p.agency) {
      c.contractedHours = 0;
      c.workDays = 0;
      c.jobBand = 999;
    } else if (!(c.jobBand > 0 && c.jobBand <= 10)) {
      c.isValid = false;
    }
    if (!c.isValid) p.allContractsValid = false;
    c.isActive = false;
    const cLength = c.end ? +c.end - +c.start : undefined;
    if (start) {
      const startInterval = +c.start - +start;
      start = c.start;
      c.start = dayjs(newStart).add(startInterval, 'millisecond').startOf('day').toDate();
    } else {
      start = c.start;
      c.start = dayjs(c.start).startOf('day').toDate();
    }
    newStart = c.start;
    c.end = c.end ? dayjs(c.start).add(cLength, 'millisecond').toDate() : undefined;
  });

  if (contracts.length > 1) {
    let endDate = contracts[0].end;
    for (let i = 1; i < contracts.length; i++) {
      if (!endDate) {
        contracts[i].overlap = true;
        p.allContractsValid = false;
        break;
      } else if (endDate >= contracts[i].start) {
        contracts[i].overlap = true;
        p.allContractsValid = false;
        break;
      } else {
        contracts[i].overlap = false;
      }
      endDate = contracts[i].end;
    }
  }
  const now = new Date();
  // go through the contracts and find an active contract
  const ac: ManningContract = contracts.find((c) => c.isValid && c.start < now && (!c.end || c.end > now));
  let cc = contracts[0];

  if (ac) {
    p.activeContract = ac;
    p.activeContract.isActive = true;
  } else if (contracts.some((c) => c.isValid)) {
    // there is no active contract so find the closest future one
    const index = contracts.findIndex((c) => c.isValid && c.start > now);
    if (index >= 0) {
      p.activeContract = p.contracts[index];
    } else {
      // no future contract so use the first one
      p.activeContract = contracts.filter((c) => c.isValid)[0];
    }
    p.activeContract.isActive = false;
  }
  if (p.activeContract) {
    p.activeContractHours = p.activeContract.contractedHours;
    p.activeWorkDays = p.activeContract.workDays;
    p.activeDepartmentLabel = p.activeContract.department?.label || '';
    p.activeRoleLabel = (p.activeContract.role?.label || '') + (p.agency ? ' [Agency]' : ''); // work around for https://sudoorgza.sentry.io/issues/5774349725/?project=5764862
    p.activeJobBand = p.activeContract.jobBand;
    p.activeTitle = p.activeContract.title;
  } else {
    p.activeContractHours = 0;
    p.activeWorkDays = 0;
    p.activeDepartmentLabel = 'No contract';
    p.activeRoleLabel = 'No contract';
    p.activeJobBand = 1;
    p.activeTitle = '';
  }
  return p;
};

const _statusCodes = [
  { code: 'ACTIVE', label: 'Active' },
  // { code:'INACTIVE', label: 'Inactive'},
  { code: 'LEAVER', label: 'Leaver' },
  { code: 'LONG_TERM_LEAVE', label: 'Long Leave' },
];

export function getManningStatusCodes() {
  return _statusCodes;
}

export function getManningStatusLabel(code: string) {
  return _statusCodes.find((c) => c.code == code)?.label;
}

export function fullDepartmentIsEqual(d1: FullDepartment, d2: FullDepartment) {
  return (
    d1.name == d2.name &&
    (d1.outletType ? d1.outletType == d2.outletType && d1.outletIndex == d2.outletIndex : !d2.outletType) &&
    (d1.subDeptName ? d1.subDeptName === d2.subDeptName : !d2.subDeptName)
  );
}
