import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { filter, first, mergeMap } from 'rxjs/operators';
import { UIService } from 'src/services/ui.service';
import { AuthError, AuthErrorType, AuthService } from '../../services/auth.service';
import { environment } from 'src/environments/environment';
import { from, Subscription, timer, zip } from 'rxjs';
import { UserService } from 'src/services/user.service';
import { ApolloError } from '@apollo/client/errors';
import { CentralDataService } from 'src/services/central-data.service';
import dayjs from 'dayjs';
import { MessageService } from 'primeng/api';
import { SentryService } from 'src/services/sentry.service';
import { LoggingService } from 'src/services/logging.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  @ViewChild('email', { static: true }) emailControl: ElementRef;
  public user = '';
  public password = '';
  public newPassword = '';
  public confirmPassword = '';
  public updatePassword = false;
  public resetPassword = false;
  public saving = false;
  public authenticating = true;
  public needsExternalAuthentication = false;
  public usePasswordLogin = true;
  public errorMessage = null;
  public saveSignIn = false;
  public defaultUrl: string;
  public passwordUpdated = false;
  public passwordResetRequested = false;
  private isAlive$: Subscription;
  authenticated$: Subscription;
  public isAlive = false;
  public message: string;

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private userService: UserService,
    private router: Router,
    private logService: LoggingService,
    private uiService: UIService,
    private centralData: CentralDataService,
    private messageService: MessageService,
    private sentryService: SentryService
  ) {}

  ngOnInit() {
    this.saveSignIn = this.authService.getPersistentLogin();
    if (this.authService.useExternalAuth()) {
      this.usePasswordLogin = false;
      this.needsExternalAuthentication = true;
      this.authenticated$ = this.authService.isAuthenticated().subscribe((authenticated) => {
        this.needsExternalAuthentication = !authenticated;
      });
    } else {
      this.usePasswordLogin = true;
      this.needsExternalAuthentication = false;
    }
    if (this.saveSignIn) {
      try {
        this.authenticating = true;
        if (!this.authService.useExternalAuth()) {
          this.messageService.add({
            key: 'top-center',
            severity: 'info',
            summary: 'Checking authentication',
            life: 3000,
          });
          timer(3000)
            .pipe(first())
            .subscribe(() => {
              this.authenticating = false;
            });
        } else {
          this.authenticating = false;
        }
      } catch (error) {
        this.sentryService.sendMessage('error while checking authentication', 'warning', { error, stack: error.stack });
        this.authenticating = false;
      }
    } else {
      this.authenticating = false;
    }

    this.defaultUrl = !window.location.origin.startsWith(environment.webUrl) ? environment.webUrl : undefined;
    if (environment.ignoreUnavailable) {
      this.isAlive = true;
    } else {
      this.isAlive$ = this.centralData.watchIsAlive().subscribe((res) => {
        this.isAlive = res;
        if (!res) {
          this.message =
            'You seem to be having internet issues connecting to the site. If your connection is unstable that may lead to other errors.';
        }
      });
    }
    this.route.queryParams.pipe(first()).subscribe(async (params) => {
      const { autoLogout, code, ...queryParams } = params || {};
      if (this.authService.useExternalAuth()) {
        this.authenticating = true;
        if (code) {
          try {
            this.messageService.add({
              key: 'bottom-right',
              severity: 'info',
              summary: 'Checking your account...',
              life: 3000,
            });
            await this.authService.login(new URL(window.location.href), undefined, this.saveSignIn).then(() => {
              timer(500)
                .pipe(first())
                .subscribe(() => {
                  this.authenticating = false;
                });
            });
          } catch (error) {
            this.authenticating = false;
            if (error instanceof AuthError) {
              if (error.errorType === AuthErrorType.MISSING_DATA) {
                this.clearQueryParams();
                return;
              } else if (error.errorType === AuthErrorType.INTERNAL_ERROR) {
                this.uiService.acknowledgeError(
                  'Unable to check your credentials please try again later',
                  () => this.clearQueryParams(),
                  'Internal error'
                );
              } else if (error.errorType === AuthErrorType.DISABLED) {
                this.uiService.acknowledgeError(
                  'The account has been disabled please contact the system administrators for more information',
                  () => this.clearQueryParams(),
                  'Account Disabled'
                );
              } else if (error.errorType === AuthErrorType.INVALID_EXP) {
                if (error.wrappedError) {
                  this.sentryService.sendMessage(
                    'Invalid JWT exp',
                    'warning',
                    { error: error.wrappedError, errorMessage: error.wrappedError.message, errorStack: error.wrappedError.stack },
                    'login-jwt-error'
                  );
                }
                this.uiService.acknowledgeError(
                  'The expiry time of your login is invalid, please check the date/time of your computer.',
                  () => this.clearQueryParams(),
                  'Login expiry error'
                );
              } else {
                this.uiService.acknowledgeError(
                  this.authService.useExternalAuth()
                    ? `Unable to login in please check that you have an account on ${environment.names.schedulerFull}`
                    : 'Unable to log in please check your username and password',
                  () => this.clearQueryParams(),
                  'Authentication error'
                );
              }
              return;
            }
            this.sentryService.showAndSendError(
              error,
              'Login error',
              this.authService.useExternalAuth()
                ? `Unable to login in please check that you have an account on ${environment.names.schedulerFull}`
                : 'Unable to log in please check your username and password',
              { useExternalAuth: this.authService.useExternalAuth() },
              'login-sso-error'
            );
          }
        } else {
          if (environment.login.autoSSO) {
            this.route.data.pipe(first()).subscribe((data) => {
              if (data['other'] ? !data['other']['manual'] : true) {
                this.messageService.add({
                  key: 'bottom-right',
                  severity: 'info',
                  summary: 'Checking your account...',
                  life: 3000,
                });
                this.authService.ssoLoginRequest(this.saveSignIn).then(
                  () => {
                    this.authenticating = false;
                  },
                  (err) => {
                    this.logService.info(err);
                    this.messageService.add({
                      key: 'top-center',
                      severity: 'warn',
                      summary: 'Unable to authenticate',
                      detail: 'Please wait a minute and retry logging in',
                      sticky: true,
                    });
                    this.authenticating = false;
                  }
                );
              } else {
                this.authenticating = false;
              }
            });
          }
        }
      } else if (autoLogout) {
        // we need to delay this slightly so that all the init checks can run before showing the message
        timer(10)
          .pipe(
            first(),
            mergeMap(() => {
              return from(this.authService.signOut());
            })
          )
          .subscribe(() => {
            this.logService.debug('auto logout');
            this.router.navigate(['/login/manual'], { queryParams });
            this.uiService.acknowledgeAction(
              'Your were automatically logged out, please log in again to continue.',
              () => undefined,
              'Logged out'
            );
          });
      }

      this.authService
        .isAuthenticating()
        .pipe(
          filter((busy) => !busy),
          first()
        )
        .subscribe(
          () => {
            this.authService
              .isAuthorized()
              .pipe(
                filter((loggedIn) => loggedIn),
                first()
              )
              .subscribe(() => {
                this.authenticating = false;
                this.saving = false;
                this.route.queryParams.pipe(first()).subscribe((params) => {
                  this.authService
                    .currentUser()
                    .pipe(filter((data) => !!data?.email))
                    .subscribe((data) => {
                      this.messageService.clear('bottom-right');
                      this.messageService.add({
                        key: 'bottom-right',
                        severity: 'success',
                        summary: `Successfully logged in as ${data.email}`,
                      });
                    });
                  if (params.returnUrl && !params.returnUrl.match(/(login|password)/)) {
                    this.router.navigate([params.returnUrl]);
                  } else {
                    this.router.navigate(['/home']);
                  }
                });
              });
          },
          () => {
            this.authenticating = false;
          }
        );
    });

    zip(this.route.paramMap, this.route.data)
      .pipe(first())
      .subscribe(([params, data]) => {
        if (data && data['other'] && data['other']['newPassword']) {
          this.passwordUpdated = true;
        }
        if (data && data['other'] && data['other']['changePassword']) {
          this.user = params.get('email')?.trim().toLowerCase();
          if (environment.useSchedulerPasswordChange) {
            this.updatePassword = true;
          } else {
            this.passwordChange();
          }
        }
        if (data && data['other'] && data['other']['resetPassword']) {
          this.user = params.get('reqemail')?.trim().toLowerCase();
          if (environment.useSchedulerPasswordChange) {
            this.updatePassword = true;
            this.resetPassword = true;
          } else {
            this.forgotPassword();
          }
        }
      });
  }

  ngOnDestroy() {
    this.messageService.clear('top-center');
    if (this.isAlive$ && !this.isAlive$.closed) {
      this.isAlive$.unsubscribe();
    }
    if (this.authenticated$) {
      this.authenticated$.unsubscribe();
    }
    this.isAlive$ = undefined;
    this.authenticated$ = undefined;
  }

  async login() {
    this.saving = true;
    this.uiService.info('Logging in...');
    this.errorMessage = '';
    try {
      await this.authService.login(this.user?.trim().toLowerCase(), this.password, this.saveSignIn);
    } catch (error) {
      this.saving = false;
      if (error instanceof AuthError) {
        if (error.errorType === AuthErrorType.INTERNAL_ERROR) {
          this.uiService.error('Internal error', 'Unable to check your credentials please try again later');
        } else if (error.errorType === AuthErrorType.DISABLED) {
          this.uiService.error(
            'Account Disabled',
            'The account has been disabled please contact the system administrators for more information'
          );
        } else if (error.errorType === AuthErrorType.TOO_MANY_REQUESTS) {
          this.uiService.error(
            'Account Temporarily Disabled',
            'The account has temporarily been disabled due to too many failed login attempts. Please reset your password or try again later.'
          );
        } else if (error.errorType === AuthErrorType.INVALID_EXP) {
          if (error.wrappedError) {
            this.sentryService.sendMessage(
              'Invalid JWT exp',
              'warning',
              { error: error.wrappedError, errorMessage: error.wrappedError.message, errorStack: error.wrappedError.stack },
              'login-jwt-error'
            );
          }
          this.uiService.error(
            'Login expiry error',
            'The expiry time of your login is invalid, please check the date/time of your computer.'
          );
        } else {
          this.uiService.error(
            'Authentication error',
            this.authService.useExternalAuth()
              ? `Unable to login in please check that you have an account on ${environment.names.schedulerFull}`
              : 'Unable to log in please check your username and password'
          );
        }
        return;
      }
      this.sentryService.showAndSendError(
        error,
        'Login error',
        'Unable to log in',
        { useExternalAuth: this.authService.useExternalAuth() },
        'login-error'
      );
    }
  }

  clearQueryParams() {
    this.router.navigate([], { queryParams: { code: null, state: null }, queryParamsHandling: 'merge' });
  }

  changePassword() {
    this.uiService.info('Changing password...');
    this.saving = true;
    this.authService.login(this.user?.trim().toLowerCase(), this.password, this.saveSignIn).then(
      (status) => {
        this.userService
          .changePassword(this.user?.trim().toLowerCase(), this.password, this.newPassword)
          .pipe(first())
          .subscribe((res) => {
            this.password = '';
            this.newPassword = '';
            this.confirmPassword = '';
            this.uiService.info('Password changed. Please log in again with your new password.');
            this.updatePassword = false;
            this.saving = false;
          });
      },
      (error) => {
        this.saving = false;
        if (error?.code === 'auth/internal-error') {
          this.uiService.error('Internal error', 'Unable to check your credentials please try again later');
        } else if (error?.code === 'auth/user-disabled') {
          this.uiService.error(
            'Account Disabled',
            'The account has been disabled please contact the system administrators for more information'
          );
        } else if (error?.code === 'auth/too-many-requests') {
          this.uiService.error(
            'Account Temporarily Disabled',
            'The account has temporarily been disabled due to too many failed login attempts. Please use the password reset or try again later'
          );
        } else if (error?.code === 'auth/user-not-found') {
          this.uiService.error('Invalid Account', `"${this.user?.trim().toLowerCase()}" does not have an account for this system.`);
        } else {
          this.uiService.error(
            'Authentication error',
            this.authService.useExternalAuth()
              ? `Unable to login in please check that you have an account on ${environment.names.schedulerFull}`
              : 'Unable to log in please check your username and password'
          );
        }
      }
    );
  }

  async requestSSOLogin() {
    await this.authService.ssoLoginRequest(this.saveSignIn, true);
  }

  passwordChange() {
    if (environment.useSchedulerPasswordChange) {
      this.resetPassword = false;
      this.password = undefined;
      this.updatePassword = true;
    } else {
      this.uiService.confirmAction(
        `Do you want to change your password? You will be directed to the ${environment.names.befFull} password change page.`,
        () => {
          const url = `${environment.wotUrl}/change-password${this.user ? '/' + this.user.trim().toLowerCase() : ''}`;
          window.open(url, '_parent');
        }
      );
    }
  }

  isValidPassword() {
    if (!this.newPassword) {
      return true;
    }
    if (this.newPassword.length < 8) {
      return false;
    }
    return (
      this.newPassword.match(/[0-9]/) &&
      this.newPassword.match(/[a-z]/) &&
      this.newPassword.match(/[A-Z]/) &&
      this.newPassword.match(/[^0-9a-zA-Z]/)
    );
  }

  forgotPassword() {
    if (environment.useSchedulerPasswordChange) {
      this.resetPassword = true;
      this.updatePassword = false;
    } else {
      this.uiService.confirmAction(
        `Do you want to reset your password? You will be directed to the ${environment.names.befFull} password reset page.`,
        () => {
          const url = `${environment.wotUrl}/reset-password/request${this.user ? '/' + this.user.trim().toLowerCase() : ''}`;
          window.open(url, '_parent');
        }
      );
    }
  }

  passwordReset() {
    if (this.user.length < 5 || !this.user.includes('@')) {
      this.uiService.acknowledgeError(
        'Please enter a valid email address so our system can send you a password reset link',
        () => undefined,
        'No Email Address'
      );
      return;
    }
    this.saving = true;
    this.userService
      .requestPasswordReset(this.user.trim().toLowerCase())
      .pipe(first())
      .subscribe(
        () => {
          this.uiService.acknowledgeAction(
            'An email has been sent to you with instructions on how to reset your password',
            () => undefined,
            'Password reset requested'
          );
          this.saving = false;
          this.passwordResetRequested = true;
        },
        (error) => {
          this.saving = false;
          if (error instanceof ApolloError) {
            if (error.graphQLErrors && error.graphQLErrors.length > 0) {
              if (error.graphQLErrors.some((err) => err?.message === 'Too many password reset requests')) {
                this.uiService.acknowledgeError(
                  `There have been too many password reset requests for this account. Please wait till ${dayjs()
                    .add(20, 'minute')
                    .format('HH:mm')} and then retry`,
                  undefined,
                  'Too many password reset requests'
                );
                return;
              }
              if (error.graphQLErrors.some((err) => err?.message === 'Password cannot be reused')) {
                this.uiService.acknowledgeError('You need to change your password to a new password');
                return;
              }
              if (error.graphQLErrors.some((err) => err.message === 'Invalid reset ID')) {
                this.uiService.acknowledgeError(
                  'You seem to have used an expired password reset link. Please request a new password reset.',
                  undefined,
                  'Invalid reset request'
                );
                this.password = undefined;
                return;
              }
              if (error.graphQLErrors.some((err) => err?.message === 'Password reset temporarily disabled')) {
                this.uiService.acknowledgeError(
                  `The account has temporarily been disabled. Please wait till ${dayjs()
                    .add(30, 'minute')
                    .format('HH:mm')} and then request a new password reset.`,
                  undefined,
                  'Password reset temporarily disabled'
                );
                this.password = undefined;
                return;
              }
            }
          }
          this.uiService.acknowledgeError('We were unable to send you a password reset, please check your email address and retry');
        }
      );
  }

  saveSignInChanged(event) {
    this.authService.setPersistentLogin(this.saveSignIn);
  }
}
