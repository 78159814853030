import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { FullDepartment, ManningContract } from './../../commontypes/manning';
import { UIService } from 'src/services/ui.service';
import dayjs from 'dayjs';

@Component({
  selector: 'app-manning-contract',
  styleUrls: ['./manning-contract.component.scss'],
  templateUrl: './manning-contract.component.html',
})
export class ManningContractComponent implements OnInit {
  showOpen = false;
  _contract: ManningContract;
  department: FullDepartment;
  otherDepartments: FullDepartment[];
  clusterDepartments: { [holidex: string]: FullDepartment[] } = {};
  @Input() homeHotelId: string;
  @Input() currency: string;
  holidexCode: string = '';
  @Input() cluster?: { name; hotels: Array<{ id: string; label: string; holidexCode: string }> };
  otherClusterHotels?: Array<{ id: string; label: string; holidexCode: string }>;
  @Input() set contract(val: ManningContract) {
    // this.hotelId = val.department?.hotelId;
    this.department = val.department;
    val.otherDepartments = val.otherDepartments?.filter((v) => !!v) || []; //remove null departments (bad data?)
    if (this.homeHotelId) {
      this.otherDepartments = val.otherDepartments?.filter(({ hotelId }) => hotelId === this.homeHotelId);
    } else {
      this.otherDepartments = val.otherDepartments;
    }
    if (val.role && (!val.title || val.title.length === 0)) {
      val.title = val.role.label;
    }
    this._contract = val;
    this.checkActiveContract();
  }
  @Input() disabled = false;
  @Input() agency = false;
  @Output() onChange = new EventEmitter<ManningContract>();
  @Output() onDelete = new EventEmitter<ManningContract>();

  constructor(private uiService: UIService) {}

  ngOnInit() {
    this.checkActiveContract();
    const filterContractsByHotelId = (hotelId) => this._contract.otherDepartments?.filter((dept) => dept && dept.hotelId === hotelId);
    if (this._contract?.otherDepartments) {
      if (this.homeHotelId) {
        this.otherDepartments = filterContractsByHotelId(this.homeHotelId);
      } else {
        this.otherDepartments = this._contract.otherDepartments;
      }
    }
    if (this.cluster) {
      this.otherClusterHotels = this.cluster.hotels.filter(({ id }) => id !== this.homeHotelId);
      this.holidexCode = this.cluster.hotels.find(({ id }) => id === this.homeHotelId)?.holidexCode;
      this.clusterDepartments = this.cluster.hotels.reduce((prev, hotel) => {
        if (hotel.id !== this.homeHotelId) {
          prev[hotel.holidexCode] = filterContractsByHotelId(hotel.id) || [];
        }
        return prev;
      }, {} as { [holidex: string]: FullDepartment[] });
    }
  }

  checkActiveContract() {
    if (this._contract && (this._contract.isActive || !this._contract.isValid))
      //display the active contract and any invalid contracts
      this.showOpen = true;
  }

  removeContract() {
    this.uiService.confirmActionDanger('Remove this Contract?', 'Remove', () => {
      if (!this._contract.id) {
        this._contract.id = 'delete';
      }
      this.onDelete.emit(this._contract);
    });
  }

  isJobBandValid() {
    return Number(this._contract.jobBand) > 0 && Number(this._contract.jobBand) <= 10;
  }

  hotelChange() {
    if (this.department?.hotelId !== this.homeHotelId) {
      this.department = null;
    }
  }

  contractChange() {
    if (this._contract.role && !this._contract.jobBand) {
      if (this._contract.role.jobBand > 0 && this._contract.role.jobBand <= 10) {
        this._contract.jobBand = this._contract.role.jobBand;
      } else {
        this._contract.jobBand = null;
      }
    }
    if (this._contract?.end) {
      this._contract.end = dayjs(this._contract.end).endOf('day').toDate();
    }
    if (this._contract.role && (!this._contract.title || this._contract.title.length === 0)) {
      this._contract.title = this._contract.role.label;
    }
    if (typeof this._contract.payRate !== 'number' || this._contract.payRate < 0) {
      this._contract.payRate = null;
    }
    this.onChange.emit(this._contract);
  }

  toggle({ collapsed }: any) {
    this._contract.isOpen = !collapsed;
  }

  depChange() {
    this._contract.department = this.department;
    this._contract.hotelId = this.department.hotelId;
    this.onChange.emit(this._contract);
  }

  clusterDeptChange(holidex) {
    const hotel = this.cluster.hotels.find(({ holidexCode }) => holidexCode === holidex);
    if (hotel) {
      this._contract.otherDepartments = [
        ...(this._contract.otherDepartments?.filter(({ hotelId }) => hotelId !== hotel.id) || []),
        ...(this.clusterDepartments[holidex] || []),
      ];
    }
    this.onChange.emit(this._contract);
  }

  otherChange() {
    this._contract.otherDepartments = [
      ...this.otherDepartments,
      ...(this.homeHotelId ? this._contract.otherDepartments?.filter(({ hotelId }) => hotelId !== this.homeHotelId) || [] : []),
    ];
    this.onChange.emit(this._contract);
  }
}
