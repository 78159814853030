<p-table
  [value]="value"
  [columns]="def.cols"
  [loading]="isLoading"
  #dt
  selectionMode="single"
  (onRowSelect)="selectAction($event)"
  styleClass="p-datatable-gridlines p-datatable-striped"
  [scrollable]="true"
  scrollHeight="flex"
  [paginator]="paginator"
  [rows]="pageRows"
>
  <ng-template pTemplate="caption" *ngIf="def.heading">
    <div class="p-d-flex p-ai-center p-jc-between">
      {{ def.heading }}
      <div style="margin-left: auto"><ng-content select="[top-header]"></ng-content></div>
      <div style="padding-left: 1em">
        <button *ngIf="def.showAdd" type="button" pButton icon="pi pi-plus" (click)="addAction()" [label]="def.addLabel"></button>
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="header" let-columns>
    <tr *ngIf="!def.heading && def.showAdd">
      <!-- the caption template won't be showing and it doesn't work async so we add a row at the top of the table to contain the add button -->
      <td [attr.colspan]="def.cols.length" style="padding: 8px">
        <div style="display: flex">
          <div style="padding-top: 1em">
            <span>Showing {{ dt.filteredValue ? dt.filteredValue.length : dt.totalRecords }} of {{ dt.totalRecords }} Records</span>
          </div>
          <div style="margin-left: auto"><ng-content select="[topheader]"></ng-content></div>
          <div style="padding-left: 1em">
            <button *ngIf="def.showAdd" type="button" pButton icon="pi pi-plus" (click)="addAction()" [label]="def.addLabel"></button>
            <help-overlay *ngIf="def.helpfiles" [helpfiles]="def.helpfiles"></help-overlay>
          </div>
        </div>
      </td>
    </tr>
    <tr style="width: 20em">
      <th *ngFor="let col of columns" [pSortableColumn]="col.value" [pSortableColumnDisabled]="!col.sort">
        {{ col.label }}
        <p-sortIcon [field]="col.value" *ngIf="col.sort"></p-sortIcon>
      </th>
    </tr>
    <tr *ngIf="def.colFilters">
      <th *ngFor="let col of columns">
        <app-text-filter *ngIf="col.filter === 'text'"
           [defaultFilter]="col.defaultFilter || ''"
           (filterChange)="filterTbl($event,col.value,'contains')"
        >
        </app-text-filter>
        <!--
        <span class="p-input-icon-right" *ngIf="col.filter === 'text'">
          <input          
          style="width: 100%"
          [value]="col.defaultFilter || ''"
          pInputText
          type="text"
          (input)="filterTbl($event.target.value, col.value, 'contains')"
          <i class="pi pi-times" (click)="clearfilter()" ></i>
        />        
        </span> -->
        
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData let-columns="columns">
    <tr [pSelectableRow]="rowData">
      <td *ngFor="let col of columns">{{ rowData[col.value] }}</td>
    </tr>
  </ng-template>
</p-table>
