<div *ngIf="inDisplay&&(!showAsBlock)" class="marker shiftdisplay p-d-flex p-jc-around p-ai-center" [ngStyle]="{ 'left.%': startPos, 'width.%' : width, 'top.rem': topOffset }"
  [ngClass]="[finalShortType || 'UNKNOWN',shift.otherDep?'OTHERDEP':'std',isCaptured?'CAPTURE':'NOCAPTURE',isPreDraft?'PREDRAFT':'DRAFT',isCapturedImperfect?'CAPTUREIMPERFECT':'NOCAPTURE',isAbsence?'CAPTUREABSENCE':'NOCAPTURE',isCapturedLocked()?'LOCKED':'DRAFT',isPM?'PM':'NOPM']"
   (click)="clicked()"
  [pTooltip]="toolTipText()" tooltipPosition='bottom' (contextmenu)="onRightClick($event)">
  <div class='type' *ngIf="!isChanged">{{(shift.type=='AGENCY')?(cQty?cQty:shift.quantity):finalShortType}}<span class="commentmark" *ngIf="shift.comments">*</span></div>
  <div class='type' *ngIf="isChanged"><s>{{(shift.type=='AGENCY')?(cQty?cQty:shift.quantity):shortCode}}</s> {{finalShortType}}</div>
  <div class="start" *ngIf="showFullDetail&&showLeft&&(!isStartImperfect)">{{start| dayjs: 'HH:mm'}}</div>
  <div class="start" *ngIf="showFullDetail&&showLeft&&isStartImperfect">{{cStart| dayjs: 'HH:mm'}} <s> {{start| dayjs: 'HH:mm'}}</s></div>
  <div class="end" *ngIf="showFullDetail&&showRight&&(!isEndImperfect)">{{end| dayjs: 'HH:mm'}}</div>
  <div class="end" *ngIf="showFullDetail&&showRight&&isEndImperfect"><s>{{end| dayjs: 'HH:mm'}} </s> {{cEnd| dayjs: 'HH:mm'}}</div>
</div>

<div *ngIf="inDisplay&&(showAsBlock)" class="block shiftdisplay p-d-flex p-jc-around p-ai-center"
  [ngClass]="[finalShortType || 'UNKNOWN',shift.otherDep?'OTHERDEP':'std',isCaptured?'CAPTURE':'NOCAPTURE',isPreDraft?'PREDRAFT':'DRAFT',isCapturedImperfect?'CAPTUREIMPERFECT':'NOCAPTURE',isAbsence?'CAPTUREABSENCE':'NOCAPTURE',isCapturedLocked()?'LOCKED':'DRAFT',isPM?'PM':'NOPM']"
    (click)="clicked()"
    [ngStyle]="{'top.rem': topOffset }"
  [pTooltip]="toolTipText()" tooltipPosition='bottom' (contextmenu)="onRightClick($event)">
  <div class='type' *ngIf="!isChanged">{{(shift.type=='AGENCY')?(cQty?cQty:shift.quantity):finalShortType}}<span class="comment" *ngIf="shift.comments">{{shift.comments}}</span></div>
  <div class='type' *ngIf="isChanged"><s>{{(shift.type=='AGENCY')?(cQty?cQty:shift.quantity):shortCode}}</s> {{finalShortType}}</div>
  <div class="start" *ngIf="showFullDetail&&showLeft&&(!isStartImperfect)">{{start| dayjs: 'HH:mm'}}</div>
  <div class="start" *ngIf="showFullDetail&&showLeft&&isStartImperfect">{{cStart| dayjs: 'HH:mm'}} <s> {{start| dayjs: 'HH:mm'}}</s></div>
  <div class="end" *ngIf="showFullDetail&&showRight&&(!isEndImperfect)">{{end| dayjs: 'HH:mm'}}</div>
  <div class="end" *ngIf="showFullDetail&&showRight&&isEndImperfect"><s>{{end| dayjs: 'HH:mm'}} </s> {{cEnd| dayjs: 'HH:mm'}}</div>
</div>
