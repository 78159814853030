<p-overlayPanel #op appendTo="body">
    <ng-template pTemplate>
        <div class=help>
        <p-selectButton  [options]="helpfiles" [(ngModel)]="selFile" optionLabel="description" name="bar" (ngModelChange)="showDoc()">
        </p-selectButton>
        <embed *ngIf="showUrl" [src]="showUrl" />
        </div>
    </ng-template>
</p-overlayPanel>

<button pButton pRipple type="button" icon="pi pi-info-circle"
   class="p-button-rounded p-button-text p-button-lg"
   (click)="op.toggle($event); showDoc();">
 </button>
