import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SentryService } from './sentry.service';

@Injectable({
  providedIn: 'root',
})
export class LoggingService {
  private _logLevel = 0;
  constructor(private sentry: SentryService) {
    this._logLevel = environment.logLevel;
  }

  set logLevel(level: number) {
    console.log(`new log level "${level < 0 ? 'none' : level === 0 ? 'error' : level === 1 ? 'info' : 'debug'}"`);
    this._logLevel = level;
  }
  get logLevel() {
    return this._logLevel;
  }

  /**
   * we ignore the *data* in production (if the data is required use *trace*)
   * @param message
   * @param data ignored in production
   */
  debug(message, ...data: any[]) {
    if (this._logLevel >= 2) {
      if (environment.production) {
        this.sentry.sendMessage(message, 'debug');
      } else {
        console.log(`[${Date.now()}] ` + message, ...data);
      }
    }
  }
  /**
   * we ignore the *data* in production (if the data is required use *trace*)
   * @param message
   * @param data ignored in production
   */
  info(message: string, ...data: any[]) {
    if (this._logLevel >= 1) {
      if (environment.production) {
        this.sentry.sendMessage(message, 'info');
      } else {
        console.log(`[${Date.now()}] ` + message, ...data);
      }
    }
  }
  /**
   * data is sent to sentry in production so be careful
   * @param message
   * @param data
   */
  trace(message: string, ...data: any[]) {
    if (this._logLevel >= 1) {
      if (environment.production) {
        this.sentry.sendMessage(message, 'info', data);
      } else {
        console.log(`[${Date.now()}] ` + message, ...data);
      }
    }
  }
  error(message, ...data: any[]) {
    if (this._logLevel >= 0) {
      if (environment.production) {
        this.sentry.sendMessage(message, 'error', data);
      } else {
        console.info(`[${Date.now()}] ` + message, ...data);
      }
    }
  }
}
