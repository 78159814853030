<ng-container *ngIf="ui.confirmOps">
  <p-dialog [header]="ui.confirmOps.title" showEffect="fade" modal="true" [closable]="false" [visible]="ui.confirmOps">
      <div class="content p-d-flex p-ai-center" >
        <i class='pi' [ngClass]="ui.confirmOps.icon"></i>
        <div>{{ui.confirmOps.message}}</div>
      </div>
      <ng-container *ngFor="let b of ui.confirmOps.options">
        <button  type="button" pButton [icon]="'pi '+b.icon" (click)="clicked(b);" [label]="b.text" class="p-button-lg" [ngClass]="b.class?b.class:'p-button-primary'"></button>
      </ng-container>
  </p-dialog>
</ng-container>
