import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular-ivy';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { SentryErrorHandler } from './services/sentry-error-handler.service';

if (environment.sentryDsn) {
  Sentry.init({
    dsn: environment.sentryDsn,
    integrations: [
      new Sentry.BrowserTracing({
        tracingOrigins: environment.sentryTracingOrigins,
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],
    tracesSampleRate: environment.sentrySampleRate,
    release: environment.release,
    environment: environment.sentryEnv || 'production',
    enabled: true,
    ignoreErrors: [/Observable cancelled prematurely/i],
    beforeSend: (event, hint) => {
      const { accept, lastOperation, ignoreError } = SentryErrorHandler.errorFilter(hint);
      if (lastOperation) {
        Sentry.addBreadcrumb({
          type: 'query',
          category: 'api',
          message: `${accept ? '[Last] ' : ''}Operation->${lastOperation.operationName}`,
          data: { lastOperation: { ...lastOperation }, ignoreError },
        });
      }
      if (accept) {
        return event;
      }
      return null;
    },
  });
}

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
