<app-shift-display (shiftClicked)="modifyShift($event)"
                   (cellAddClicked)="addCellShift($event)"
                   (colAddClicked)="addColShift($event)"
                   (colSaveClicked)="saveColShift($event)"
                   (colApproveClicked)="approveColShift($event)"
                   (exportClicked)="export($event)"
                   [showColApprove]="canApprove"
                   [extraCommand]="canWrite?'Submit to GM':null"
                   extraCommandIcon="pi-save"
                   (extraCommandClicked)="saveAll($event)"
                   [extraCommand2]="canApprove?'Approve All':null"
                   extraCommandIcon2="pi-check-square"
                   (extraCommandClicked2)="approveAll($event)"
                   (shiftMoved)="shiftMoved($event)"
                   [allowMultDepartments]="true"
                   [helpfiles]="[{file:'schedule_instruction', description:'Instructions'},{file:'schedule_scheduling', description:'Scheduling Process'}]"
></app-shift-display>
<app-scheduled-shift [shift]="editShift" (onChange)="saveEditShift()" (onCancel)="cancelEditShift()" (onRemove)="removeEditShift()">
</app-scheduled-shift>
<app-clone-shifts (onDone)="saveCloneShifts($event)" (onCancel)="cancelCloneShifts()" [cloneEv]="cloneEv">
</app-clone-shifts>
