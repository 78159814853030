<app-display-table
  [value]="devices"
  [def]="tblDef"
  [isLoading]="loading"
  (add)="addRow()"
  (rowSelected)="editRow($event)"    
>
</app-display-table>
<ng-container *ngIf="editDevice">
  <p-dialog header="Device Details" [(visible)]="editDevice" closable="false" responsive showEffect="fade" modal="true">
    <div class="content">
      <div>
        <div class="p-field">
          <label
            >Name
            <input
              type="text"
              pInputText
              [(ngModel)]="editDevice.name"
              required
          /></label>
          <p-message
            *ngIf="editDevice.name.length<5"
            severity="error"
            text="Must be at least 5 characters."
          ></p-message>
        </div>
        <div class="p-field">
          <label>Description
            <input type="text" pInputText [(ngModel)]="editDevice.description"/>
          </label>
        </div>
        <div class="p-field" *ngIf="editDevice.id">
          <label>Disabled
            <p-checkbox binary="true" [(ngModel)]="editDevice.disabled" ></p-checkbox>
          </label>
        </div>
        
      </div>
    </div>
    <p-footer>
      <button
        *ngIf="editDevice.id"
        type="button"
        pButton
        icon="pi pi-minus"
        (click)="removeDevice()"
        label="Remove"
        [disabled]="savingEdit"
        class="p-button-danger"
      ></button>
      <button
        type="button"
        pButton
        icon="pi pi-times"
        (click)="editDevice=null"
        label="Cancel"
        class="p-button-secondary"
      ></button>
      <button
        *ngIf="!editDevice.id"
        type="button"
        pButton
        [icon]="savingEdit ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
        (click)="saveDevice(true)"
        label="Create and Generate Link"
      ></button>
      <button
        *ngIf="editDevice.id"
        type="button"
        pButton
        [icon]="savingEdit ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
        (click)="createDeviceLink()"
        label="Generate New Link"
      ></button>
      <button
        *ngIf="editDevice.id"
        type="button"
        pButton
        [icon]="savingEdit ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
        (click)="saveDevice(false)"
        label="Update"
      ></button>
    </p-footer>
  </p-dialog>
</ng-container>
