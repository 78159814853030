import { Injectable } from '@angular/core';

import { Workbook, Worksheet } from 'exceljs';

@Injectable({
  providedIn: 'root',
})
export class XLSService {

  private workbook: Workbook;
  private worksheet: Worksheet;

  constructor() {

  }

  startSheet() {
    this.workbook = new Workbook();
    this.worksheet = this.workbook.addWorksheet("data");
  }

  setColumnWidths(widths) {
    this.worksheet.columns = widths.map(w => ({ width: w }));
  }

  hideColumn(index) {
    this.worksheet.getColumn(index).hidden = true;
  }

  setColumnAlignment(startC: number, endC: number, hAlign: any = 'center', vAlign: any = 'middle', wrap:boolean =false) {
    for (let i = startC; i <= endC; i += 1)
      this.worksheet.getColumn(i).alignment = {
        vertical: vAlign,
        horizontal: hAlign,
        wrapText: wrap,
      }
  }

  addRow(data, bgCol = null) {
    let row = this.worksheet.addRow(data);
    if (bgCol) {
      row.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: bgCol },
      };
      row.border = {
        top: { style: 'thin', color: { argb: 'DDDDDD' } },
        left: { style: 'thin', color: { argb: 'DDDDDD' } },
        bottom: { style: 'thin', color: { argb: 'DDDDDD' } },
        right: { style: 'thin', color: { argb: 'DDDDDD' } },
      }
    }
    return row;
  }

  addTitleRow(title, bgCol, center = false, cellW = 10) {
    let r = this.worksheet.addRow([title])
    r.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: bgCol },
    };
    r.font = {
      size: 12,
      bold: true,
    }
    if (center)
      r.alignment = { vertical: 'middle', horizontal: 'center' };
    this.worksheet.mergeCells(r.number, 1, r.number, cellW);
    return r;
  }

  mergeCells(r1:number, c1:number, r2:number, c2:number) {
    this.worksheet.mergeCells(r1, c1, r2, c2);
  }

  setVAlignMiddle(r1, c1) {
    this.worksheet.getCell(r1, c1).alignment = {
      vertical: 'middle'
    }
  }

  async outputSheet(filename) {
    this.outputBuffer(await this.workbook.xlsx.writeBuffer(), filename)
  }

  outputBuffer(buffer, filename) {
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    // browser download logic
    const a: any = document.createElement('a');
    document.body.appendChild(a);
    a.style = 'display: none';
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(url);
  }

  async openFile(file) {
    this.workbook = new Workbook();
    let buffer = await file.arrayBuffer();
    await this.workbook.xlsx.load(buffer)
    this.worksheet = this.workbook.worksheets[0];
  }

  public getCellVal(col: number, row: number) {
    return this.worksheet.getRow(row).getCell(col).value;
  }

  public numRows() {
    return this.worksheet.rowCount;
  }

}
