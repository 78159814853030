<p-dropdown *ngIf="!multi"
  [options]="departments"
  [(ngModel)]="selectedDepartment"
  filter="true"
  optionLabel="label"
  [placeholder]="placeholder"
  (onChange)="select()"
  [style]="{ width: '15em' }"
  [disabled]="disabled"
  [showClear]="showClear"
  resetFilterOnHide="true"
  appendTo="body"
>
</p-dropdown>
<p-multiSelect *ngIf="multi"
  [options]="departments"
  [(ngModel)]="selectedDepartment"
  filter="true"
  optionLabel="label"
  [placeholder]="placeholder"
  (onChange)="select()"
  [style]="{ width: '15em' }"
  [disabled]="disabled"
  [showClear]="showClear"
  resetFilterOnHide="true"
  appendTo="body"
>
</p-multiSelect>

<p-progressBar *ngIf="showLoading" mode="indeterminate" [style]="{ height: '6px' }"></p-progressBar>
