import { Component, OnInit, Input,  ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';

/* 
This component shows a large nice version of a menu component
Top level items will show as boxes, within them lie buttons
deeper levels will be exposed as pop ups
*/

@Component({
  selector: 'app-menu-button',
  styleUrls: ['./menu-button.component.scss'],
  templateUrl: './menu-button.component.html',
})
export class MenuButtonComponent implements OnInit {

  @Input() menu: MenuItem;

  @ViewChild('menupop', { static: true }) menupop: any;


  constructor(private router:Router) {}


  ngOnInit() {
  }

  open(e) {
    if (this.menu.command)
      this.menu.command({item:this.menu});
    else if (this.menu.routerLink) {
      this.router.navigate(this.menu.routerLink);
    } else if (this.menu.items) { //pop up a submenu
       this.menupop.toggle(e);
    }
  }


}
