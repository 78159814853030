import { Injectable } from '@angular/core';
import dayjs from 'dayjs';

import gql from 'graphql-tag';
import { distinctUntilChanged, filter, first, map, mergeMap, switchMap } from 'rxjs/operators';
import { makeISODateOnly } from 'src/commontypes/drivers';
import { deepClone, randomDelayObservable } from 'src/commontypes/util';
import { ILinkContext, LinkService } from './link.service';
import { LoggingService } from './logging.service';
import { environment } from 'src/environments/environment';
import { forkJoin, Observable, Subject, of, concat, combineLatest, timer } from 'rxjs';
import { RegionDataService } from './region-data.service';
import { CentralDataService } from './central-data.service';
import { ApolloQueryResult } from '@apollo/client/core';

const MAX_SHIFT_DAYS = 7;
const MAX_CAPTURED_SHIFT_DAYS = 4;

type DepartmentClass = 'NON_OPS' | 'F_AND_B' | 'ROOMS';

interface IBefReportResult {
  id;
  departments: Array<{
    departmentName: string;
    subDeptName?: string;
    division: DepartmentClass;
    total: {
      base: number;
      flex: number;
      prmHours: number;
      ratioHours: number;
      structure: number;
      structureProductiveHours?: number;
      exceptionProductiveHours?: number;
      productiveHours: number;
      exception: number;
    };
  }>;
  outlets: Array<{
    outletType: string;
    outletIndex: number;
    departments: Array<{
      departmentName: string;
      division: DepartmentClass;
      total: {
        base: number;
        flex: number;
        prmHours: number;
        ratioHours: number;
        structure: number;
        structureProductiveHours?: number;
        exceptionProductiveHours?: number;
        productiveHours: number;
        exception: number;
      };
    }>;
  }>;
}

@Injectable({
  providedIn: 'root',
})
export class ScheduleDataService {
  constructor(
    private linkService: LinkService,
    private regionData: RegionDataService,
    private log: LoggingService,
    private central: CentralDataService
  ) {}

  private sliceInterval = (start: Date, end: Date, maxDaysPerSlice: number) => {
    const days = dayjs(end).diff(start, 'day') + 1;
    const numSlices = Math.ceil(days / maxDaysPerSlice);
    const slices = [
      { s: makeISODateOnly(dayjs(start).toDate()), e: makeISODateOnly(dayjs(start).toDate()) },
      ...Array(numSlices - 1)
        .fill(undefined)
        .map((_, idx) => ({
          s: makeISODateOnly(
            dayjs(start)
              .add(idx * maxDaysPerSlice + 1, 'day')
              .toDate()
          ),
          e: makeISODateOnly(
            dayjs(start)
              .add((idx + 1) * maxDaysPerSlice, 'day')
              .toDate()
          ),
        })),
    ];
    if (dayjs(slices[slices.length - 1].e).isBefore(makeISODateOnly(end))) {
      slices.push({
        s: makeISODateOnly(
          slices.length > 0
            ? dayjs(slices[slices.length - 1].e)
                .add(1, 'day')
                .toDate()
            : start
        ),
        e: makeISODateOnly(end),
      });
    }
    return slices;
  };

  public getScheduledShifts(
    start: Date,
    end: Date,
    hotelIdin: string = null,
    deptsIn: { name; outletType?; outletIndex?; subDeptName? }[],
    inclClusterShifts = false
  ) {
    const depts = deptsIn.map((d) => ({
      departmentName: d.name,
      outletType: d.outletType,
      outletIndex: d.outletIndex,
      subDeptName: d.subDeptName,
    }));
    const createDeptKey = (dept: { departmentName; outletType?; outletIndex?; subDeptName? }) =>
      `${dept.departmentName}-${dept.outletIndex || ''}-${dept.outletType || ''}-${dept.subDeptName || ''}`;

    const deptSet = depts.reduce((prev, d) => {
      prev.add(createDeptKey(d));
      return prev;
    }, new Set<string>());
    const deptExists = (dept: { departmentName; outletType?; outletIndex?; subDeptName? }) => deptSet.has(createDeptKey(dept));

    // first get the link and then wait on the hotel otherwise we might get a hotel from the wrong region
    const slices = this.sliceInterval(start, end, MAX_SHIFT_DAYS);
    const $shiftData = new Subject<{ data: any[]; personnelRefreshed: boolean }>();
    this.linkService
      .GQLRegion(false)
      .pipe(
        mergeMap((link) => this.linkService.awaitCurrentHotel(hotelIdin).pipe(map((hotelId) => ({ hotelId, link })))),
        first()
      )
      .subscribe(({ link, hotelId }) => {
        const fetchPersonnelObs = (fetchPolicy: 'cache-only' | 'network-only') => {
          return concat(
            of(undefined),
            link
              .query({
                query: gql`
          query getPersonsForShifts($hotelId: ID!, $inclClusterShifts: Boolean) {
            getPersons(hotelId: $hotelId, includeCluster: $inclClusterShifts) {
              id
              firstName
              lastName
              personnelNo
              roleName
              jobBand
              agency
              status
              leavingDate
              contracts {
                id
                start
                end
                department { ...${fragments.contractDept.name} }
                otherDepartments { ...${fragments.contractDept.name} }
                contractedHours
                workDays
                title
                roleName
              }
            }
          }
          ${fragments.contractDept.definition}
        `,
                variables: {
                  hotelId,
                  inclClusterShifts,
                  betweenAgency: {
                    start: makeISODateOnly(start),
                    end: makeISODateOnly(end),
                  },
                },
                fetchPolicy,
              })
              .pipe(
                first(),
                map((res: any) => {
                  if (!res || !res.data || !res.data.getPersons) {
                    return undefined;
                  }
                  const { data } = res;
                  const peeps: Map<number, any> = deepClone(data.getPersons as any[])
                    .filter(({ status }) => status !== 'PLANNING_ONLY')
                    .reduce((prev, person) => {
                      prev.set(Number(person.id), {
                        shifts: [],
                        ...person,
                        leaving: person.status === 'LEAVER' || person.status === 'TERMINATED',
                      });
                      return prev;
                    }, new Map<number, any>());
                  return peeps;
                })
              )
          );
        };

        const agencyObs = forkJoin(
          depts.map((dept) =>
            concat(
              of<ApolloQueryResult<{ agencyShifts }>>(undefined),
              link.query<{ agencyShifts }>({
                query: gql`
          query getAgencyShifts($hotelId: ID!,
            $betweenAgency: BetweenInput!,
            $departmentName: DepartmentName, $outletType: ServiceOutletType, $outletIndex: Int, $subDeptName: DepartmentName
            ) {
            agencyShifts: allShifts(
              hotelId: $hotelId, between: $betweenAgency, type: AGENCY
              departmentName: $departmentName, outletIndex: $outletIndex, outletType: $outletType, subDeptName: $subDeptName
              ) {
              ...${fragments.shift.name}
            }
          }
          ${fragments.shift.definition}
        `,
                variables: {
                  hotelId,
                  betweenAgency: {
                    start: makeISODateOnly(start),
                    end: makeISODateOnly(end),
                  },
                  ...dept,
                },
                fetchPolicy: 'network-only',
              })
            )
          )
        ).pipe(
          map((perDeptAgencyShifts) => {
            // combine the individual department shifts into one array
            const allAgencyShifts = perDeptAgencyShifts.reduce((p, e) => {
              p.push(...(e?.data?.agencyShifts || []));
              return p;
            }, []);
            return allAgencyShifts;
          }),
          map((allAgencyShifts) => {
            let as = allAgencyShifts
              ? allAgencyShifts.filter((s) => !s.personId && deptExists(s)).sort((a, b) => +dayjs(a.start) - +dayjs(b.start))
              : null;
            return {
              //add agency user
              firstName: '',
              id: null,
              lastName: 'AGENCY',
              isAgency: true,
              contracts: [
                {
                  contractedHours: 40,
                  workDays: 5,
                },
              ],
              shifts: as ? deepClone(as) : [],
            };
          })
        );
        // we split the loading up into the following queries
        // cachedPersons (employees) - which we will retrieve from the cache
        // actualPersons (employees) - which we check on the network to ensure that personnel data is up to date
        // agency shifts for the whole period (we treat AGENCY as another employee)
        // non-agency shifts which we split into separate queries containing at most MAX_SHIFT_DAYS days
        // each of these queries starts off as undefined and then we combine them when we have at least received
        // the employees and agency shifts
        const obs: [
          Observable<Map<number, any>>,
          Observable<Map<number, any>>,
          Observable<{ firstName; id; lastName; isAgency; contracts; shifts: any[] }>,
          ...Array<Observable<Map<number, any[]>>>
        ] = [fetchPersonnelObs('cache-only'), fetchPersonnelObs('network-only'), agencyObs];
        const shiftObs = slices.map(({ s, e }) =>
          concat(
            of<undefined>(undefined),
            link
              .query<{ allShiftsByDepartments? }>({
                query: gql`
            query getShifts($hotelId: ID!, $between: BetweenInput!, $departments: [ShiftDepartmentInput!]!) {
              allShiftsByDepartments(hotelId: $hotelId, between: $between, includeCluster: true, departments: $departments) {
                  ...${fragments.shift.name}
              }
            }
            ${fragments.shift.definition}
          `,
                variables: {
                  hotelId,
                  between: {
                    start: s,
                    end: e,
                  },
                  departments: depts,
                },
                fetchPolicy: 'network-only',
              })
              .pipe(
                first(),
                map(({ data }) => {
                  const shiftMap: Map<number, any[]> = deepClone((data?.allShiftsByDepartments as any[]) || [])
                    .sort((a, b) => +dayjs(a.start) - +dayjs(b.start))
                    .reduce((prev, shift) => {
                      if (shift.personId) {
                        const personId = Number(shift.personId);
                        const shifts = prev.get(personId) || [];
                        shifts.push(shift);
                        prev.set(personId, shifts);
                      }
                      return prev;
                    }, new Map<number, any[]>());
                  return shiftMap;
                })
              )
          )
        );
        obs.push(...shiftObs);
        const queries$ = combineLatest(obs).subscribe(
          (
            results: [
              Map<number, any>,
              Map<number, any>,
              { firstName; id; lastName; isAgency; contracts; shifts: any[] },
              ...Array<Map<number, any[]>>
            ]
          ) => {
            if (results.slice(1).some((res) => !!res) && $shiftData.observers.length === 0) {
              $shiftData.complete();
              queries$.unsubscribe();
              return;
            }
            const [cachedPersons, actualPersons, agencyPerson, ...shiftResults] = results;
            const persons: Map<'AGENCY' | number, any> = actualPersons || cachedPersons;
            if (!persons) {
              return;
            }
            if (!agencyPerson) {
              return;
            }
            persons.set('AGENCY', agencyPerson);
            const data = Array.from(persons.keys()).reduce((prev, personId) => {
              const person = persons.get(personId);

              if (!person.shifts) {
                person.shifts = [];
              }
              if (personId === 'AGENCY') {
                prev.push(person);
                return prev;
              }
              shiftResults
                .filter((res) => !!res)
                .forEach((shiftMap) => {
                  const shifts = shiftMap.get(personId) || [];
                  const existingShiftIds = new Set(person.shifts.map((personShift) => Number(personShift.id)));
                  const additionalShifts = shifts.filter((shift) => !existingShiftIds.has(Number(shift.id)));
                  if (additionalShifts?.length) {
                    person.shifts.push(...additionalShifts);
                  }
                });

              prev.push(person);
              return prev;
            }, []);
            $shiftData.next({ data, personnelRefreshed: !!actualPersons });
            if (actualPersons && results.every((res) => !!res)) {
              $shiftData.complete();
              queries$.unsubscribe();
            }
          }
        );
      });
    return $shiftData;
  }

  public getScheduledAndCapturedShifts(
    start: Date,
    end: Date,
    hotelIdin: string = null,
    dept: { name; outletType?; outletIndex?; subDeptName? },
    inclClusterShifts = false
  ) {
    // first get the link and then wait on the hotel otherwise we might get a hotel from the wrong region

    const deptVar: { departmentName; outletType?; outletIndex?; subDeptName? } = { departmentName: dept.name };
    if (dept.outletType) {
      deptVar.outletIndex = dept.outletIndex;
      deptVar.outletType = dept.outletType;
    }
    if (dept.subDeptName) {
      deptVar.subDeptName = dept.subDeptName;
    }
    const slices = this.sliceInterval(start, end, MAX_CAPTURED_SHIFT_DAYS);
    const $shiftData = new Subject<{ data: any[]; personnelRefreshed: boolean }>();
    this.linkService
      .GQLRegion(false)
      .pipe(mergeMap((link) => this.linkService.awaitCurrentHotel(hotelIdin).pipe(map((hotelId) => ({ hotelId, link })))))
      .subscribe(({ link, hotelId }) => {
        const fetchPersonnelObs = (fetchPolicy: 'cache-only' | 'network-only') => {
          return concat(
            of(undefined),
            link
              .query({
                query: gql`
                  query getPersonsForCapturedShifts($hotelId: ID!, $inclClusterShifts: Boolean) {
                    getPersons(hotelId: $hotelId, includeCluster: $inclClusterShifts) {
                      id
                      firstName
                      lastName
                      personnelNo
                      roleName
                      jobBand
                      agency
                      status
                      leavingDate
                      contracts {
                        id
                        start
                        end
                        department { ...${fragments.contractDept.name} }
                        otherDepartments { ...${fragments.contractDept.name} }
                        contractedHours
                        workDays
                        title
                        roleName
                      }
                    }
                  }
                  ${fragments.contractDept.definition}
                `,
                variables: {
                  hotelId,
                  inclClusterShifts,
                },
                fetchPolicy,
              })
              .pipe(
                map((res: any) => {
                  if (!res || !res.data || !res.data.getPersons) {
                    return undefined;
                  }
                  const { data } = res;
                  const peeps: Map<number, any> = deepClone(data.getPersons as any[])
                    .filter(({ status }) => status !== 'PLANNING_ONLY')
                    .reduce((prev, person) => {
                      prev.set(Number(person.id), { ...person, leaving: person.status === 'LEAVER' || person.status === 'TERMINATED' });
                      return prev;
                    }, new Map<number, any>());
                  return peeps;
                })
              )
          );
        };

        const agencyObs = concat(
          of(undefined),
          link
            .query<{ agencyShifts }>({
              query: gql`
          query getAgencyShifts(
            $hotelId: ID!,
            $betweenAgency: BetweenInput!
            $departmentName: DepartmentName!, $outletType: ServiceOutletType, $outletIndex: Int, $subDeptName: DepartmentName
            ) {
            agencyShifts: allShifts(
              hotelId: $hotelId, between: $betweenAgency, type: AGENCY,
              departmentName: $departmentName, outletIndex: $outletIndex, outletType: $outletType, subDeptName: $subDeptName
              ) {
                      ...${fragments.shift.name}
                      capturedShifts {
                        ...${fragments.capturedShift.name}
                      }
                    }
          }
          ${fragments.shift.definition}
          ${fragments.capturedShift.definition}
        `,
              variables: {
                hotelId,
                betweenAgency: {
                  start: makeISODateOnly(start),
                  end: makeISODateOnly(end),
                },
                ...deptVar,
              },
              fetchPolicy: 'network-only',
            })
            .pipe(
              first(),
              map(({ data }) => {
                let as = data?.agencyShifts
                  ? data.agencyShifts.filter((s) => !s.personId).sort((a, b) => +dayjs(a.start) - +dayjs(b.start))
                  : null;
                return {
                  //add agency user
                  firstName: '',
                  id: null,
                  lastName: 'AGENCY',
                  isAgency: true,
                  contracts: [
                    {
                      contractedHours: 40,
                      workDays: 5,
                    },
                  ],
                  shifts: as ? deepClone(as) : [],
                };
              })
            )
        );
        // we split the loading up into the following queries
        // cachedPersons (employees) - which we will retrieve from the cache
        // actualPersons (employees) - which we check on the network to ensure that personnel data is up to date
        // agency shifts for the whole period (we treat AGENCY as another employee)
        // non-agency shifts which we split into separate queries containing at most MAX_SHIFT_DAYS days
        // each of these queries starts off as undefined and then we combine them when we have at least received
        // the employees and agency shifts
        const obs: [
          Observable<Map<number, any>>,
          Observable<Map<number, any>>,
          Observable<{ firstName; id; lastName; isAgency; contracts; shifts: any[] }>,
          ...Array<Observable<Map<number, any[]>>>
        ] = [fetchPersonnelObs('cache-only'), fetchPersonnelObs('network-only'), agencyObs];
        const shiftObs = slices.map(({ s, e }, idx) =>
          concat(
            of<undefined>(undefined),
            link
              .query<{ allShifts }>({
                query: gql`
            query getCapturedShifts(
                $hotelId: ID!, $between: BetweenInput!,
                $departmentName: DepartmentName!, $outletType: ServiceOutletType, $outletIndex: Int, $subDeptName: DepartmentName
              ) {
              allShifts(
                  hotelId: $hotelId, between: $between, includeCluster: true,
                  departmentName: $departmentName, outletIndex: $outletIndex, outletType: $outletType, subDeptName: $subDeptName
                ) {
                ...${fragments.shift.name}
                capturedShifts {
                  ...${fragments.capturedShift.name}
                }
              }
            }
            ${fragments.shift.definition}
            ${fragments.capturedShift.definition}
          `,
                variables: {
                  hotelId,
                  between: {
                    start: s,
                    end: e,
                  },
                  ...deptVar,
                },
                fetchPolicy: 'network-only',
              })
              .pipe(
                first(),
                map(({ data }) => {
                  const shiftMap: Map<number, any[]> = deepClone((data?.allShifts as any[]) || [])
                    .sort((a, b) => +dayjs(a.start) - +dayjs(b.start))
                    .reduce((prev, shift) => {
                      if (shift.personId) {
                        const personId = Number(shift.personId);
                        const shifts = prev.get(personId) || [];
                        shifts.push(shift);
                        prev.set(personId, shifts);
                      }
                      return prev;
                    }, new Map<number, any[]>());
                  return shiftMap;
                })
              )
          )
        );
        obs.push(...shiftObs);
        const queries$ = combineLatest(obs).subscribe(
          (
            results: [
              Map<number, any>,
              Map<number, any>,
              { firstName; id; lastName; isAgency; contracts; shifts: any[] },
              ...Array<Map<number, any[]>>
            ]
          ) => {
            if (results.slice(1).some((res) => !!res) && $shiftData.observers.length === 0) {
              $shiftData.complete();
              queries$.unsubscribe();
              return;
            }
            const [cachedPersons, actualPersons, agencyPerson, ...shiftResults] = results;
            const persons: Map<'AGENCY' | number, any> = actualPersons || cachedPersons;
            if (!persons) {
              return;
            }
            if (!agencyPerson) {
              return;
            }
            persons.set('AGENCY', agencyPerson);

            const data = Array.from(persons.keys()).reduce((prev, personId) => {
              const person = persons.get(personId);

              if (!person.shifts) {
                person.shifts = [];
              }
              if (personId === 'AGENCY') {
                prev.push(person);
                return prev;
              }
              shiftResults
                .filter((res) => !!res)
                .forEach((shiftMap) => {
                  const shifts = shiftMap.get(personId) || [];
                  const existingShiftIds = new Set(person.shifts.map((personShift) => Number(personShift.id)));
                  const additionalShifts = shifts.filter((shift) => !existingShiftIds.has(Number(shift.id)));
                  if (additionalShifts?.length) {
                    person.shifts.push(...additionalShifts);
                  }
                });
              prev.push(person);
              return prev;
            }, []);
            $shiftData.next({ data, personnelRefreshed: !!actualPersons });
            if (actualPersons && results.every((res) => !!res)) {
              queries$.unsubscribe();
              $shiftData.complete();
            }
          }
        );
      });
    return $shiftData;
  }

  public updateShift(personId, shiftId, shift, detail, unplanned, hotelIdIn = null) {
    this.log.debug('updateShift', detail);
    return this.linkService.awaitCurrentHotel(hotelIdIn).pipe(
      mergeMap((hotelId) => {
        if (!shiftId) return this.createShift(hotelId, personId, shift, detail, unplanned);
        else return this.updateShiftP(hotelId, personId, shiftId, shift, detail);
      })
    );
  }

  private createShift(hotelId: string, personId: string, shift, detail, unplanned) {
    let shiftI = {
      type: detail.type,
      absenceType: detail.absenceType,
      start: detail.start,
      end: detail.end,
      department: {
        hotelId: hotelId,
        departmentName: detail.departmentName || shift.departmentName,
        subDeptName: detail.subDeptName || shift.subDeptName,
        ...(shift.outletType && {
          //includes outlet details if they are present
          outlet: {
            type: shift.outletType,
            index: shift.outletIndex,
          },
        }),
        ...(detail.outletType && {
          //includes outlet details if they are present
          outlet: {
            type: detail.outletType,
            index: detail.outletIndex,
          },
        }),
      },
      breaks: +detail.breaks, //remove any cruft
      comments: detail.comments || null,
      quantity: +detail.quantity,
    };
    return this.linkService.GQLRegion().pipe(
      first(),
      mergeMap((link) =>
        link
          .mutate({
            mutation: gql`
              mutation createShift($hotelId: ID!, $personId: ID, $data: ShiftInput!, $unplanned: Boolean) {
                createShift(hotelId: $hotelId, personId: $personId, data: $data, unplanned: $unplanned) {
                  ...${fragments.shift.name}
                }
              }
              ${fragments.shift.definition}
            `,
            variables: {
              hotelId,
              personId,
              unplanned,
              data: shiftI,
            },
          })
          .pipe(map(({ data }: any) => data.createShift))
      )
    );
  }

  private updateShiftP(hotelId, personId, shiftId, shift, detail) {
    let shiftI = {
      department: {
        hotelId: hotelId,
        departmentName: detail.departmentName,
        subDeptName: detail.subDeptName,
        ...(detail.outletType && {
          //includes outlet details if they are present
          outlet: {
            type: detail.outletType,
            index: detail.outletIndex,
          },
        }),
      },
      type: detail.type,
      absenceType: detail.absenceType,
      start: detail.start,
      end: detail.end,
      breaks: +detail.breaks,
      comments: detail.comments || null,
      quantity: +detail.quantity,
    };

    return this.linkService.GQLRegion().pipe(
      first(),
      mergeMap((link) =>
        link
          .mutate({
            mutation: gql`
              mutation updateShift($id: ID!, $personId: ID, $data: ShiftUpdateInput!) {
                updateShift(id: $id, personId: $personId, data: $data) {
                  ...${fragments.shift.name}
                }
              }
              ${fragments.shift.definition}
            `,
            variables: {
              id: shiftId,
              personId: personId,
              data: shiftI,
            },
          })
          .pipe(map(({ data }: any) => data.updateShift))
      )
    );
  }

  public deleteShift(id, shift) {
    return this.linkService.GQLRegion().pipe(
      first(),
      mergeMap((link) =>
        link
          .mutate({
            mutation: gql`
              mutation deleteShift($shiftId: ID!) {
                deleteShift(shiftId: $shiftId, confirm: true)
              }
            `,
            variables: {
              shiftId: id,
            },
          })
          .pipe(map(({ data }: any) => data.deleteShift))
      )
    );
  }

  public updateCapturedShift(shiftId, capShift) {
    if (!capShift.id) return this.createCapShift(shiftId, capShift);
    else return this.updateCapShift(capShift);
  }

  private createCapShift(shiftId: string, shift) {
    let shiftI = {
      start: shift.start,
      end: shift.end,
      breaks: +shift.breaks, //remove any cruft
      quantity: +shift.quantity,
      absence: !!shift.absence,
      cancel: !!shift.cancel,
      absenceComment: shift.absenceComment || null,
      absenceType: shift.absence ? shift.absenceType : null,
      status: shift.status,
      importComment: shift.importComment || null,
    };
    return this.linkService.GQLRegion().pipe(
      first(),
      mergeMap((link) =>
        link
          .mutate<{ createCapturedShift }>({
            mutation: gql`
              mutation createCapturedShift($shiftId: ID!, $data: CapturedShiftInput!) {
                createCapturedShift(shiftId: $shiftId, data: $data) {
                  ...${fragments.capturedShift.name}
                }
              }
              ${fragments.capturedShift.definition}
            `,
            variables: {
              shiftId,
              data: shiftI,
            },
          })
          .pipe(map(({ data }) => data?.createCapturedShift))
      )
    );
  }

  private updateCapShift(shift) {
    let shiftI = {
      start: shift.start,
      end: shift.end,
      breaks: +shift.breaks, //remove any cruft
      quantity: +shift.quantity,
      cancel: !!shift.cancel,
      absence: !!shift.absence,
      absenceComment: shift.absenceComment || null,
      absenceType: shift.absence ? shift.absenceType : null,
      importComment: shift.importComment || null,
      status: shift.status,
    };
    return this.linkService.GQLRegion(true).pipe(
      first(),
      mergeMap((link) =>
        link
          .mutate<{ updateCapturedShift }>({
            mutation: gql`
              mutation updateCapturedShift($id: ID!, $data: CapturedShiftUpdateInput!) {
                updateCapturedShift(capturedShiftId: $id, data: $data) {
                  ...${fragments.capturedShift.name}
                }
              }
              ${fragments.capturedShift.definition}
            `,
            variables: {
              id: shift.id,
              data: shiftI,
            },
          })
          .pipe(map(({ data }) => data?.updateCapturedShift))
      )
    );
  }

  /**
   * We remove the structure non-productive hours if they are available
   * @param data
   */
  calcOptimalHours(
    data: {
      base;
      flex;
      structure;
      structureProductiveHours?;
      exception;
      exceptionProductiveHours?;
      ratioHours?;
      productiveHours;
      prmHours;
    },
    division: string,
    departmentName: string
  ) {
    const useNonOpsOptimals = this.central.isProdOnlyDept(departmentName);
    if (useNonOpsOptimals && !data.base && !data.flex && !data.ratioHours) {
      // NON GOI  - send structure, and all exception productive hours
      const nonOpsOptimals = (data.structure || 0) + (data.exceptionProductiveHours || 0);
      return nonOpsOptimals < 0 ? 0 : nonOpsOptimals;
    }
    // Operational Department - do not send structure hours. Send productive hours and non productive exception hours
    const exceptionProd = data.exceptionProductiveHours || data.productiveHours - (data.structureProductiveHours || 0);
    if (typeof data.ratioHours === 'number') {
      const ratioOptimals = data.ratioHours + exceptionProd;
      return ratioOptimals < 0 ? 0 : ratioOptimals;
    }
    const optimals = data.productiveHours + data.flex + data.base + exceptionProd;
    return optimals < 0 ? 0 : optimals;
  }

  getActualOptimalHours(
    date: Date,
    departmentName: string,
    subDeptName?: string,
    outletType?: string,
    outletIndex?: number,
    hotelIdin: string = null
  ) {
    return this.linkService.awaitCurrentHotel(hotelIdin).pipe(
      mergeMap((hotelId: string) => {
        return this.linkService.GQLRegion().pipe(
          mergeMap((link) =>
            link
              .watchQuery<{ getBefDailyActualReport: IBefReportResult }>({
                query: gql`
                  query getBefDailyActualReport($hotelId: ID!, $date: CalendarDate!) {
                    getBefDailyActualReport(hotelId: $hotelId, date: $date) {
                      id
                      departments {
                        departmentName
                        subDeptName
                        division
                        total {
                          ...${fragments.reportTotal.name}
                        }
                      }
                      outlets {
                        outletType
                        outletIndex
                        departments {
                          departmentName
                          division
                          total {
                            ...${fragments.reportTotal.name}
                          }
                        }
                      }
                    }
                  }
                  ${fragments.reportTotal.definition}
                `,
                variables: {
                  hotelId,
                  date: makeISODateOnly(date),
                },
                fetchPolicy: 'cache-first',
                nextFetchPolicy: 'cache-and-network',
                pollInterval: environment.shiftOptimalValuesPollInterval,
              })
              .valueChanges.pipe(
                filter(({ loading, data }) => !loading && !!data),
                distinctUntilChanged(),
                map(({ data }) => {
                  let bef = data?.getBefDailyActualReport;
                  if (!bef) return 0;
                  if (!outletType) {
                    let dep = bef.departments.find(
                      (d) => d.departmentName == departmentName && (subDeptName ? subDeptName === d.subDeptName : !d.subDeptName)
                    );
                    return dep?.total ? this.calcOptimalHours(dep.total, dep.division, dep.departmentName) : 0;
                  } else {
                    let outlet = bef.outlets.find((o) =>
                      o.outletType ? o.outletType == outletType && o.outletIndex == outletIndex : !outletType
                    );
                    if (!outlet) return 0;
                    let dep = outlet.departments.find((d) => d.departmentName == departmentName);
                    return dep?.total ? this.calcOptimalHours(dep.total, dep.division, dep.departmentName) : 0;
                  }
                })
              )
          )
        );
      })
    );
  }

  getOptimalHours(
    date: Date,
    departmentName: string,
    subDeptName: string,
    outletType: string,
    outletIndex: number,
    hotelIdin: string = null
  ) {
    return this.linkService.awaitCurrentHotel(hotelIdin).pipe(
      mergeMap((hotelId: string) => {
        return this.linkService.GQLRegion().pipe(
          mergeMap((link) =>
            link
              .watchQuery<{ getBefDailyForecastReport: IBefReportResult }>({
                query: gql`
                  query getBefDailyForecastReport($hotelId: ID!, $date: CalendarDate!) {
                    getBefDailyForecastReport(hotelId: $hotelId, date: $date) {
                      id
                      departments {
                        departmentName
                        subDeptName
                        division
                        total {
                          ...${fragments.reportTotal.name}
                        }
                      }
                      outlets {
                        outletType
                        outletIndex
                        departments {
                          departmentName
                          division
                          total {
                            ...${fragments.reportTotal.name}
                          }
                        }
                      }
                    }
                  }
                  ${fragments.reportTotal.definition}
                `,
                variables: {
                  hotelId,
                  date: makeISODateOnly(date),
                },
                fetchPolicy: 'cache-first',
                nextFetchPolicy: 'cache-and-network',
                pollInterval: environment.shiftOptimalValuesPollInterval,
              })
              .valueChanges.pipe(
                filter(({ loading, data }) => !loading && !!data),
                distinctUntilChanged(),
                map(({ data }) => {
                  let bef = data?.getBefDailyForecastReport;
                  if (!bef) return 0;
                  if (!outletType) {
                    let dep = bef.departments.find(
                      (d) => d.departmentName == departmentName && (subDeptName ? subDeptName === d.subDeptName : !d.subDeptName)
                    );
                    return dep?.total ? this.calcOptimalHours(dep.total, dep.division, dep.departmentName) : 0;
                  } else {
                    let outlet = bef.outlets.find((o) =>
                      o.outletType ? o.outletType == outletType && o.outletIndex == outletIndex : !outletType
                    );
                    if (!outlet) return 0;
                    let dep = outlet.departments.find((d) => d.departmentName == departmentName);
                    return dep?.total ? this.calcOptimalHours(dep.total, dep.division, dep.departmentName) : 0;
                  }
                })
              )
          )
        );
      })
    );
  }

  getPersonsShiftSummary(start: Date, end: Date, hotelIdin: string = null) {
    return this.linkService.awaitCurrentHotel(hotelIdin).pipe(
      mergeMap((hotelId: string) => {
        return this.linkService.GQLRegion().pipe(
          first(),
          mergeMap((link) =>
            link
              .query({
                query: gql`
                  query getPersonShiftReports($hotelId: ID!, $start: CalendarDate!, $end: CalendarDate!) {
                    getPersonShiftReports(hotelId: $hotelId, start: $start, end: $end) {
                      person {
                        firstName
                        lastName
                        personnelNo
                        departmentLabel
                        holidayDays
                        publicHolidayDays
                        leavingDate
                        contractsEndDate
                        currentContract {
                          contractedHours
                          workDays
                          casual
                          start
                          end
                        }
                      }
                      shiftItems {
                        date
                        items {
                          holidexCode
                          departmentName
                          subDeptName
                          outletType
                          outletIndex
                          hours
                          breakHours
                          type
                          absenceType
                          times {
                            start
                            end
                          }
                        }
                      }
                      capturedItems {
                        date
                        items {
                          holidexCode
                          departmentName
                          subDeptName
                          outletType
                          outletIndex
                          hours
                          breakHours
                          type
                          absenceType
                          times {
                            start
                            end
                          }
                        }
                      }
                    }
                  }
                `,
                variables: {
                  hotelId,
                  start: dayjs(start).format('YYYY-MM-DD'),
                  end: dayjs(end).format('YYYY-MM-DD'),
                },
              })
              .pipe(
                first(),
                map(({ data }: any) => {
                  return data.getPersonShiftReports;
                })
              )
          )
        );
      })
    );
  }

  getDepartmentShiftSummary(start: Date, end: Date, hotelIdin: string = null) {
    const slices = this.sliceInterval(start, end, 10);

    return this.linkService.awaitCurrentHotel(hotelIdin).pipe(
      mergeMap((hotelId: string) => {
        return this.linkService.GQLRegion(false).pipe(
          first(),
          mergeMap((link) => {
            const obs = slices.map(({ s, e }) =>
              link
                .query({
                  query: gql`
                    query getDepartmentShiftReports($hotelId: ID!, $start: CalendarDate!, $end: CalendarDate!) {
                      getDepartmentShiftReports(hotelId: $hotelId, start: $start, end: $end) {
                        departmentName
                        subDeptName
                        outletType
                        outletIndex
                        befForecastItems {
                          date
                          hours
                        }
                        befActualItems {
                          date
                          hours
                        }
                        shiftItems {
                          date
                          items {
                            hours
                            breakHours
                            type
                            absenceType
                          }
                        }
                        capturedItems {
                          date
                          items {
                            hours
                            breakHours
                            type
                            absenceType
                          }
                        }
                      }
                    }
                  `,
                  variables: {
                    hotelId,
                    start: dayjs(s).format('YYYY-MM-DD'),
                    end: dayjs(e).format('YYYY-MM-DD'),
                  },
                })
                .pipe(
                  first(),
                  map(({ data }: any) => {
                    return data.getDepartmentShiftReports;
                  })
                )
            );
            return forkJoin(obs).pipe(
              map((res) => {
                return res.reduce((p, r) => {
                  //for each department
                  r.forEach((ad) => {
                    //merge the departments...
                    let found = p.find(
                      (d) => d.departmentName == ad.departmentName && d.outletType == ad.outletType && d.outletIndex == ad.outletIndex
                    );
                    if (!found) {
                      //add them to the list
                      found = {
                        departmentName: ad.departmentName,
                        subDeptName: ad.subDeptName,
                        outletType: ad.outletType,
                        outletIndex: ad.outletIndex,
                        befActualItems: [],
                        befForecastItems: [],
                        capturedItems: [],
                        shiftItems: [],
                      };
                      p.push(found);
                    }
                    found.befActualItems.push(...ad.befActualItems);
                    found.befForecastItems.push(...ad.befForecastItems);
                    found.capturedItems.push(...ad.capturedItems);
                    found.shiftItems.push(...ad.shiftItems);
                  });
                  return p;
                }, []);
              })
            );
          })
        );
      })
    );
  }

  public getBaseScheduleStatusWithStats(start: Date, end: Date, hotelIdin: string = null, useCache = false) {
    // first get the link and then wait on the hotel otherwise we might get a hotel from the wrong region
    return this.linkService.GQLRegion(false).pipe(
      switchMap((link) => this.linkService.awaitCurrentHotel(hotelIdin).pipe(map((hotelId) => ({ hotelId, link })))),
      switchMap(({ link, hotelId }) => this.regionData.getHotel(hotelId).pipe(map((hotel) => ({ hotelId, link, hotel })))),
      switchMap(({ link, hotelId, hotel }) =>
        link
          .query({
            query: gql`
              query getSchedules($hotelId: ID!, $between: BetweenInput!) {
                getSchedules(hotelId: $hotelId, between: $between) {
                  scheduleId: id # id can be null which messes with Apollo so we rename it
                  ...${fragments.baseScheduleStatus.name}
                  ...${fragments.scheduleForecastStats.name}
                  ...${fragments.scheduleForecastReport.name}
                }
              }
              ${fragments.baseScheduleStatus.definition}
              ${fragments.scheduleForecastStats.definition}
              ${fragments.scheduleForecastReport.definition}
            `,
            variables: {
              hotelId,
              between: {
                start: makeISODateOnly(start),
                end: makeISODateOnly(end),
              },
            },
            fetchPolicy: useCache ? 'cache-first' : 'network-only',
          })
          .pipe(map(({ data }: any) => ({ data: data.getSchedules, hotel })))
      )
    );
  }

  public getCapturedScheduleStatusWithStats(start: Date, end: Date, hotelIdin: string = null, useCache = false) {
    // first get the link and then wait on the hotel otherwise we might get a hotel from the wrong region
    return this.linkService.GQLRegion(false).pipe(
      switchMap((link) => this.linkService.awaitCurrentHotel(hotelIdin).pipe(map((hotelId) => ({ hotelId, link })))),
      switchMap(({ link, hotelId }) => this.regionData.getHotel(hotelId).pipe(map((hotel) => ({ hotelId, link, hotel })))),
      switchMap(({ link, hotelId, hotel }) =>
        link
          .query<{ getSchedules: any[] }>({
            query: gql`
              query getSchedulesAndCapture($hotelId: ID!, $between: BetweenInput!) {
                getSchedules(hotelId: $hotelId, between: $between) {
                  scheduleId: id # id can be null which messes with Apollo so we rename it
                  ...${fragments.baseScheduleStatus.name}
                  ...${fragments.scheduleForecastStats.name}
                  ...${fragments.scheduleForecastReport.name}
                  ...${fragments.scheduleActualStats.name}
                  ...${fragments.scheduleActualReport.name}
                }
              }
              ${fragments.baseScheduleStatus.definition}
              ${fragments.scheduleForecastStats.definition}
              ${fragments.scheduleForecastReport.definition}
              ${fragments.scheduleActualStats.definition}
              ${fragments.scheduleActualReport.definition}
            `,
            variables: {
              hotelId,
              between: {
                start: makeISODateOnly(start),
                end: makeISODateOnly(end),
              },
            },
            fetchPolicy: useCache ? 'cache-first' : 'network-only',
          })
          .pipe(
            map(({ data }) => ({
              data: data?.getSchedules.map(({ forecastReport, actualReport, ...rest }) => ({
                ...rest,
                forecastReport: forecastReport && {
                  ...forecastReport,
                  // we need to use the productive structure value if available
                  structure: forecastReport.structureProductiveHours || forecastReport.structure,
                },
                actualReport: actualReport && {
                  ...actualReport,
                  // we need to use the productive structure value if available
                  structure: actualReport.structureProductiveHours || actualReport.structure,
                },
              })),
              hotel,
            }))
          )
      )
    );
  }

  public getScheduleStatus(start: Date, end: Date, hotelIdin: string = null) {
    // first get the link and then wait on the hotel otherwise we might get a hotel from the wrong region

    return this.linkService.GQLRegion(false).pipe(
      mergeMap((link) => this.linkService.awaitCurrentHotel(hotelIdin).pipe(map((hotelId) => ({ hotelId, link })))),
      mergeMap(({ link, hotelId }) =>
        link
          .query({
            query: gql`
              query getSchedulesStatus($hotelId: ID!, $between: BetweenInput!) {
                getSchedules(hotelId: $hotelId, between: $between) {
                  scheduleId: id # id can be null which messes with Apollo so we rename it
                  ...${fragments.baseScheduleStatus.name}
                }
              }
              ${fragments.baseScheduleStatus.definition}
            `,
            variables: {
              hotelId,
              between: {
                start: makeISODateOnly(start),
                end: makeISODateOnly(end),
              },
            },
            fetchPolicy: 'network-only',
          })
          .pipe(map(({ data }: any) => data.getSchedules))
      )
    );
  }

  public setScheduleSubmittedForDays(
    department: {
      name: string;
      outletType: string;
      outletIndex: number;
      subDeptName: string;
    },
    startDay: Date | string,
    numDays: number,
    hotelIdin: string = null
  ) {
    const days = Array(numDays)
      .fill(undefined)
      .map((_, i) => dayjs(startDay).add(i, 'day').format('YYYY-MM-DD'));
    const multipleUpdates = days?.length > 1;
    const updates = days.map((day) =>
      multipleUpdates
        ? randomDelayObservable(this.setScheduleSubmitted(department, day, hotelIdin))
        : this.setScheduleSubmitted(department, day, hotelIdin)
    );
    return forkJoin(updates);
  }

  public setScheduleSubmitted(
    {
      name: departmentName,
      ...department
    }: {
      name: string;
      outletType: string;
      outletIndex: number;
      subDeptName: string;
    },
    dayin: Date | string,
    hotelIdin: string = null
  ) {
    const day = dayjs(dayin).format('YYYY-MM-DD');
    return this.linkService.awaitCurrentHotel(hotelIdin).pipe(
      mergeMap((hotelId: string) =>
        this.linkService.GQLRegion().pipe(
          first(),
          mergeMap((link) =>
            link
              .mutate({
                mutation: gql`
                  mutation setScheduleSubmitted(
                    $hotelId: ID!
                    $departmentName: DepartmentName!
                    $subDeptName: DepartmentName
                    $outletType: ServiceOutletType
                    $outletIndex: Int
                    $day: CalendarDate!
                  ) {
                    setScheduleSubmitted(
                      hotelId: $hotelId
                      departmentName: $departmentName
                      subDeptName: $subDeptName
                      outletType: $outletType
                      outletIndex: $outletIndex
                      day: $day
                    ) {
                      id
                    }
                  }
                `,
                variables: {
                  hotelId,
                  departmentName,
                  ...department,
                  day,
                },
              })
              .pipe(
                map(({ data }: any) => {
                  return data.setScheduleSubmitted;
                })
              )
          )
        )
      )
    );
  }

  public setScheduleApprovedForDays(
    department: {
      name: string;
      outletType: string;
      outletIndex: number;
      subDeptName: string;
    },
    startDay: Date | string,
    numDays: number,
    hotelIdin: string = null
  ) {
    const days = Array(numDays)
      .fill(undefined)
      .map((_, i) => dayjs(startDay).add(i, 'day').format('YYYY-MM-DD'));
    const multipleUpdates = days?.length > 1;
    const updates = days.map((day) =>
      multipleUpdates
        ? randomDelayObservable(this.setScheduleApproved(department, day, hotelIdin).pipe(first()))
        : this.setScheduleApproved(department, day, hotelIdin).pipe(first())
    );
    return forkJoin(updates);
  }

  public setScheduleApproved(
    {
      name: departmentName,
      ...department
    }: {
      name: string;
      outletType: string;
      outletIndex: number;
      subDeptName: string;
    },
    dayin: Date | string,
    hotelIdin: string = null
  ) {
    return this.linkService.awaitCurrentHotel(hotelIdin).pipe(
      mergeMap((hotelId: string) =>
        this.linkService.GQLRegion().pipe(
          first(),
          mergeMap((link) =>
            link
              .mutate({
                mutation: gql`
                  mutation setScheduleApproved(
                    $hotelId: ID!
                    $departmentName: DepartmentName!
                    $subDeptName: DepartmentName
                    $outletType: ServiceOutletType
                    $outletIndex: Int
                    $day: CalendarDate!
                  ) {
                    setScheduleApproved(
                      hotelId: $hotelId
                      departmentName: $departmentName
                      subDeptName: $subDeptName
                      outletType: $outletType
                      outletIndex: $outletIndex
                      day: $day
                    ) {
                      id
                    }
                  }
                `,
                variables: {
                  hotelId,
                  departmentName,
                  ...department,
                  day: dayjs(dayin).format('YYYY-MM-DD'),
                },
              })
              .pipe(map(({ data }: any) => data.setScheduleApproved))
          )
        )
      )
    );
  }

  public setScheduleCaptureSubmittedForDays(
    department: {
      name: string;
      outletType: string;
      outletIndex: number;
      subDeptName: string;
    },
    dayin: Date,
    numDays: number,
    hotelIdin: string = null
  ) {
    let updates = [];
    for (let i = 0; i < numDays; i += 1) {
      updates.push(
        numDays > 1
          ? randomDelayObservable(this.setScheduleCaptureSubmitted(department, dayjs(dayin).add(i, 'day').toDate(), hotelIdin))
          : this.setScheduleCaptureSubmitted(department, dayjs(dayin).add(i, 'day').toDate(), hotelIdin)
      );
    }
    return forkJoin(updates);
  }

  public setScheduleCaptureSubmitted(
    {
      name: departmentName,
      ...department
    }: {
      name: string;
      outletType: string;
      outletIndex: number;
      subDeptName: string;
    },
    dayin: Date,
    hotelIdin: string = null
  ) {
    return this.linkService.awaitCurrentHotel(hotelIdin).pipe(
      mergeMap((hotelId: string) =>
        this.linkService.GQLRegion().pipe(
          first(),
          mergeMap((link) =>
            link
              .mutate({
                mutation: gql`
                  mutation setScheduleCaptureSubmitted(
                    $hotelId: ID!
                    $departmentName: DepartmentName!
                    $subDeptName: DepartmentName
                    $outletType: ServiceOutletType
                    $outletIndex: Int
                    $day: CalendarDate!
                  ) {
                    setScheduleCaptureSubmitted(
                      hotelId: $hotelId
                      departmentName: $departmentName
                      subDeptName: $subDeptName
                      outletType: $outletType
                      outletIndex: $outletIndex
                      day: $day
                    ) {
                      id
                    }
                  }
                `,
                variables: {
                  hotelId,
                  departmentName,
                  ...department,
                  day: dayjs(dayin).format('YYYY-MM-DD'),
                },
              })
              .pipe(map(({ data }: any) => data.setScheduleCaptureSubmitted))
          )
        )
      )
    );
  }

  public setScheduleCaptureApprovedForDays(
    department: {
      name: string;
      outletType: string;
      outletIndex: number;
      subDeptName: string;
    },
    dayin: Date,
    numDays: number,
    hotelIdin: string = null
  ) {
    let updates = [];
    for (let i = 0; i < numDays; i += 1) {
      updates.push(
        numDays > 1
          ? randomDelayObservable(this.setScheduleCaptureApproved(department, dayjs(dayin).add(i, 'day').toDate(), hotelIdin))
          : this.setScheduleCaptureApproved(department, dayjs(dayin).add(i, 'day').toDate(), hotelIdin)
      );
    }
    return forkJoin(updates);
  }

  public setScheduleCaptureApproved(
    {
      name: departmentName,
      ...department
    }: {
      name: string;
      outletType: string;
      outletIndex: number;
      subDeptName: string;
    },
    dayin: Date,
    hotelIdin: string = null
  ) {
    return this.linkService.awaitCurrentHotel(hotelIdin).pipe(
      mergeMap((hotelId: string) =>
        this.linkService.GQLRegion().pipe(
          first(),
          mergeMap((link) =>
            link
              .mutate({
                mutation: gql`
                  mutation setScheduleCaptureApproved(
                    $hotelId: ID!
                    $departmentName: DepartmentName!
                    $subDeptName: DepartmentName
                    $outletType: ServiceOutletType
                    $outletIndex: Int
                    $day: CalendarDate!
                  ) {
                    setScheduleCaptureApproved(
                      hotelId: $hotelId
                      departmentName: $departmentName
                      subDeptName: $subDeptName
                      outletType: $outletType
                      outletIndex: $outletIndex
                      day: $day
                    ) {
                      id
                    }
                  }
                `,
                variables: {
                  hotelId,
                  departmentName,
                  ...department,
                  day: dayjs(dayin).format('YYYY-MM-DD'),
                },
              })
              .pipe(map(({ data }: any) => data.setScheduleCaptureApproved))
          )
        )
      )
    );
  }

  public setScheduleCaptureUnapproved(
    departmentName: string,
    subDeptName: string,
    outletType: string,
    outletIndex: number,
    dayin: Date,
    hotelIdin: string = null
  ) {
    return this.linkService.awaitCurrentHotel(hotelIdin).pipe(
      mergeMap((hotelId: string) =>
        this.linkService.GQLRegion().pipe(
          first(),
          mergeMap((link) =>
            link
              .mutate({
                mutation: gql`
                  mutation setScheduleCaptureUnapproved(
                    $hotelId: ID!
                    $departmentName: DepartmentName!
                    $subDeptName: DepartmentName
                    $outletType: ServiceOutletType
                    $outletIndex: Int
                    $day: CalendarDate!
                  ) {
                    setScheduleCaptureUnapproved(
                      hotelId: $hotelId
                      departmentName: $departmentName
                      subDeptName: $subDeptName
                      outletType: $outletType
                      outletIndex: $outletIndex
                      day: $day
                    ) {
                      id
                    }
                  }
                `,
                variables: {
                  hotelId,
                  departmentName,
                  outletType,
                  outletIndex,
                  subDeptName,
                  day: dayjs(dayin).format('YYYY-MM-DD'),
                },
              })
              .pipe(map(({ data }: any) => data.setScheduleCaptureUnapproved))
          )
        )
      )
    );
  }

  public getRecentPredraftCaptureShifts(hotelIdin: string = null) {
    return this.linkService.awaitCurrentHotel(hotelIdin).pipe(
      mergeMap((hotelId: string) =>
        this.linkService.GQLRegion().pipe(
          first(),
          mergeMap((link) =>
            link
              .query({
                query: gql`
                    query allCapturedShifts($hotelId:ID) {
                      allCapturedShifts(hotelId:$hotelId, status:PRE_DRAFT) {
                        ...${fragments.capturedShiftExtra.name}
                      }
                    }
                    ${fragments.capturedShiftExtra.definition}
              `,
                variables: {
                  hotelId,
                },
                fetchPolicy: 'network-only',
              })
              .pipe(
                map(({ data }: any) => {
                  return data.allCapturedShifts;
                })
              )
          )
        )
      )
    );
  }

  public getCheckIns(start: Date, end: Date, hotelIdin: string = null) {
    return this.linkService.GQLRegion(false).pipe(
      mergeMap((link) => this.linkService.awaitCurrentHotel(hotelIdin).pipe(map((hotelId) => ({ hotelId, link })))),
      mergeMap(({ link, hotelId }) =>
        link
          .query({
            query: gql`
              query getCheckIns($hotelId: ID!, $between: BetweenInput!) {
                allCheckIns(hotelId: $hotelId, between: $between) {
                  personnelId
                  checkInType
                  time
                }
              }
            `,
            variables: {
              hotelId,
              between: {
                start: makeISODateOnly(start),
                end: makeISODateOnly(end),
              },
            },
            fetchPolicy: 'network-only',
          })
          .pipe(map(({ data }: any) => data.allCheckIns))
      )
    );
  }
}

const fragments = {
  reportTotal: {
    name: 'ReportTotalFragment',
    definition: gql`
      fragment ReportTotalFragment on BefReportTotal {
        prmHours
        ratioHours
        flex
        base
        structure
        structureProductiveHours
        exception
        exceptionProductiveHours
        productiveHours
      }
    `,
  },
  baseScheduleStatus: {
    name: 'BaseScheduleStatusFragment',
    definition: gql`
      fragment BaseScheduleStatusFragment on Schedule {
        departmentOrder
        day
        departmentName
        subDeptName
        outletType
        outletIndex
        scheduleStatus
        captureStatus
        scheduleSubmit
        scheduleApprove
        scheduleAutoApprove
        captureSubmit
        captureApprove
        captureAutoApprove
      }
    `,
  },
  scheduleForecastStats: {
    name: 'ScheduleForecastStatsFragment',
    definition: gql`
      fragment ScheduleForecastStatsFragment on Schedule {
        scheduledHours # can be slow
        forecastHours
        postApprovalShiftUpdates # can be slow
      }
    `,
  },
  scheduleActualStats: {
    name: 'ScheduleActualStatsFragment',
    definition: gql`
      fragment ScheduleActualStatsFragment on Schedule {
        actualHours
        capturedHours # can be slow
        postApprovalShiftUpdates # can be slow
      }
    `,
  },
  scheduleForecastReport: {
    name: 'ScheduleForecastReportFragment',
    definition: gql`
      fragment ScheduleForecastReportFragment on Schedule {
        forecastReport {
          prmHours
          ratioHours
          structure
          structureProductiveHours
          exceptionProductiveHours
          productiveHours
          exception
          base
          flex
        } # can be slow
      }
    `,
  },
  scheduleActualReport: {
    name: 'ScheduleActualReportFragment',
    definition: gql`
      fragment ScheduleActualReportFragment on Schedule {
        actualReport {
          prmHours
          ratioHours
          structure
          structureProductiveHours
          exceptionProductiveHours
          productiveHours
          exception
          base
          flex
        } # can be slow
      }
    `,
  },
  contractDept: {
    name: 'ContractDepartmentFragment',
    definition: gql`
      fragment ContractDepartmentFragment on ContractDepartment {
        holidexCode
        departmentName
        subDeptName
        outletType
        outletIndex
      }
    `,
  },
  shift: {
    name: 'ShiftFragment',
    definition: gql`
      fragment ShiftFragment on Shift {
        hotel {
          id
          holidexCode
        }
        personId
        id
        start
        end
        departmentName
        subDeptName
        outletType
        outletIndex
        type
        absenceType
        breaks
        comments
        quantity
        updatedAfterSnapshot
        postApprovalUpdate
        updatedAt
      }
    `,
  },
  capturedShift: {
    name: 'CapturedShiftFragment',
    definition: gql`
      fragment CapturedShiftFragment on CapturedShift {
        id
        start
        end
        absence
        absenceComment
        absenceType
        cancel
        breaks
        quantity
        status
        importComment
        updatedAt
      }
    `,
  },
  capturedShiftExtra: {
    name: 'CapturedShiftExtraFragment',
    definition: gql`
      fragment CapturedShiftExtraFragment on CapturedShift {
        id
        start
        end
        absence
        absenceComment
        absenceType
        cancel
        breaks
        quantity
        status
        updatedAt
        shift {
          departmentName
          subDeptName
          outletType
          outletIndex
        }
      }
    `,
  },
};
