import { Injectable } from '@angular/core';
import { catchError, filter, first, map, mergeMap, tap } from 'rxjs/operators';

import { LinkService } from './link.service';
import { makeISODateOnly, SectionType } from 'src/commontypes/drivers';
import { mapMonthEnum } from 'src/commontypes/util';
import { LoggingService } from './logging.service';
import { forkJoin, of } from 'rxjs';
import { gql } from 'apollo-angular';

type OutletType = 'RESTAURANT' | 'BAR';

const mutationFetchPolicy: 'no-cache' | undefined = 'no-cache';
const queryFetchPolicy: 'cache-first' | 'network-only' | 'cache-only' | 'no-cache' | 'standby' = 'no-cache';

@Injectable({
  providedIn: 'root',
})
export class DriverDataService {
  constructor(private linkService: LinkService, private log: LoggingService) {}

  setDriverForecastDay(sectionType: SectionType, outletIndex: number, idate: Date, data: any, hotelIdIn?: string) {
    return this.linkService.awaitCurrentHotel(hotelIdIn).pipe(
      mergeMap((hotelId) => {
        let date = makeISODateOnly(idate);
        if (sectionType == 'ALLMAIN') return this.setAllMainDriverForecastDay(date, hotelId, data);
        else if (sectionType == 'HOTEL') return this.setHotelDriverForecastDay(date, hotelId, data);
        else if (sectionType === 'FRONTOFFICE') return this.setFrontOfficeDriverForecastDay(date, hotelId, data);
        else if (sectionType === 'CLUBLOUNGE') return this.setClubLoungeDriverForecastDay(date, hotelId, data);
        else if (sectionType == 'ROOMSERVICE') return this.setRoomServiceDriverForecastDay(date, hotelId, data);
        else if (sectionType == 'CANDB') return this.setCAndBDriverForecastDay(date, hotelId, data);
        else return this.setOutletDriverForecastDay(sectionType, outletIndex, date, hotelId, data);
      })
    );
  }

  setAllMainDriverForecastDay(date: string, hotelId: string, idata) {
    this.log.debug('setAllMainDriverForecastDay', idata);
    return this.linkService.GQLRegion(false).pipe(
      first(),
      mergeMap((link) =>
        link
          .mutate({
            mutation: gql`
                    mutation updateDriverDailyForecast($date:CalendarDate!,$hotelId:ID!,$roomsOccupied:Int!,$roomsAvailable:Int!,$guests:Int!,$departures:Int!,$arrivals:Int!,$conferences:DriverDataConferenceInput,$clubLounge:DriverDataClubLoungeInput,$roomService:DriverDataRoomServiceInput) {
                      updateDriverDailyForecast(date:$date,hotelId:$hotelId,roomsAvailable:$roomsAvailable,roomsOccupied:$roomsOccupied,guests:$guests,departures:$departures,arrivals:$arrivals,conferences:$conferences,clubLounge:$clubLounge,roomService:$roomService) {
                        ...${fragments.allmaindriverdayforecast.name}
                      }
                    }
                    ${fragments.allmaindriverdayforecast.definition}
              `,
            fetchPolicy: mutationFetchPolicy,
            variables: {
              hotelId,
              date,
              roomsOccupied: +idata.HOTELroomsOccupied || 0,
              roomsAvailable: +idata.HOTELroomsAvailable || 0,
              guests: +idata.HOTELguests || 0,
              arrivals: +idata.FRONTOFFICEarrivals || 0,
              departures: +idata.FRONTOFFICEdepartures || 0,
              conferences: {
                breakfast: +idata.CANDBbreakfast || 0,
                lunch: +idata.CANDBlunch || 0,
                dinner: +idata.CANDBdinner || 0,
                coffeeBreak: +idata.CANDBcoffeeBreak || 0,
                setup: +idata.CANDBsetup || 0,
                cleardown: +idata.CANDBcleardown || 0,
              },
              clubLounge: {
                arrivals: +idata.CLUBLOUNGEarrivals || 0,
                departures: +idata.CLUBLOUNGEdepartures || 0,
                occupied: +idata.CLUBLOUNGEoccupied || 0,
              },
              roomService: {
                breakfast: +idata.ROOMSERVICEbreakfast || 0,
                lunch: +idata.ROOMSERVICElunch || 0,
                dinner: +idata.ROOMSERVICEdinner || 0,
                lateNight: +idata.ROOMSERVICElateNight || 0,
              },
            },
          })
          .pipe(
            first(),
            map(({ data }: any) => {
              this.log.debug(data);
              return {
                ...data.allMainHotelDriverDailyForecast,
              };
            })
          )
      )
    );
  }

  setOutletDriverForecastDay(outletType: OutletType, outletIndex: number, date: string, hotelId: string, idata: any) {
    let data = {
      foodBreakfast: +idata.foodBreakfast || 0,
      foodLunch: +idata.foodLunch || 0,
      foodDinner: +idata.foodDinner || 0,
      foodLateNight: +idata.foodLateNight || 0,
      beverageBreakfast: +idata.beverageBreakfast || 0,
      beverageLunch: +idata.beverageLunch || 0,
      beverageDinner: +idata.beverageDinner || 0,
      beverageLateNight: +idata.beverageLateNight || 0,
    };
    this.log.debug('write data', outletType, outletIndex, date, data);
    return this.linkService.GQLRegion().pipe(
      first(),
      mergeMap((link) =>
        link
          .mutate({
            mutation: gql`
                    mutation updateOutletDriverDailyForecast($outletType: ServiceOutletType!,$outletIndex:Int!,$date:CalendarDate!,$hotelId:ID!,$data:OutletDriverDataInput!) {
                      updateOutletDriverDailyForecast(outletType:$outletType,outletIndex:$outletIndex,date:$date,hotelId:$hotelId,data:$data) {
                        ...${fragments.outletdriverdayforecast.name}
                      }
                    }
                    ${fragments.outletdriverdayforecast.definition}
              `,
            fetchPolicy: mutationFetchPolicy,
            variables: {
              hotelId,
              outletType,
              outletIndex,
              date,
              data,
            },
          })
          .pipe(
            first(),
            map(({ data }: any) => {
              return data.updateOutletDriverDailyForecast;
            })
          )
      )
    );
  }

  setHotelDriverForecastDay(date: string, hotelId: string, idata: { roomsOccupied; roomsAvailable; guests }) {
    return this.linkService.GQLRegion().pipe(
      first(),
      mergeMap((link) =>
        link
          .mutate({
            mutation: gql`
                    mutation updateHotelDriverDailyForecast($date:CalendarDate!,$hotelId:ID!,$roomsOccupied:Int!,$roomsAvailable:Int!,$guests:Int!) {
                      updateHotelDriverDailyForecast(date:$date,hotelId:$hotelId,roomsAvailable:$roomsAvailable,roomsOccupied:$roomsOccupied,guests:$guests) {
                        ...${fragments.hoteldriverdayforecast.name}
                      }
                    }
                    ${fragments.hoteldriverdayforecast.definition}
              `,
            fetchPolicy: mutationFetchPolicy,
            variables: {
              hotelId,
              date,
              roomsOccupied: +idata.roomsOccupied || 0,
              roomsAvailable: +idata.roomsAvailable || 0,
              guests: +idata.guests || 0,
            },
          })
          .pipe(
            first(),
            map(({ data }: any) => {
              this.log.debug(data);
              return {
                ...data.updateHotelDriverDailyForecast,
                occupancy:
                  data.updateHotelDriverDailyForecast.roomsAvailable > 0
                    ? (+data.updateHotelDriverDailyForecast.roomsOccupied / +data.updateHotelDriverDailyForecast.roomsAvailable) * 100
                    : 0,
              };
            })
          )
      )
    );
  }

  setFrontOfficeDriverForecastDay(date: string, hotelId: string, idata: { arrivals; departures }) {
    return this.linkService.GQLRegion().pipe(
      first(),
      mergeMap((link) =>
        link
          .mutate({
            mutation: gql`
                    mutation updateFrontOfficeDriverDailyForecast($date:CalendarDate!,$hotelId:ID!,$departures:Int!,$arrivals:Int!) {
                      updateFrontOfficeDriverDailyForecast(date:$date,hotelId:$hotelId,departures:$departures,arrivals:$arrivals) {
                        ...${fragments.hoteldriverdayforecast.name}
                      }
                    }
                    ${fragments.hoteldriverdayforecast.definition}
              `,
            fetchPolicy: mutationFetchPolicy,
            variables: {
              hotelId,
              date,
              arrivals: +idata.arrivals || 0,
              departures: +idata.departures || 0,
            },
          })
          .pipe(
            first(),
            map(({ data }: any) => {
              this.log.debug(data);
              return data.updateFrontOfficeDriverDailyForecast;
            })
          )
      )
    );
  }

  setClubLoungeDriverForecastDay(date: string, hotelId: string, idata: { arrivals; departures; occupied }) {
    const data = {
      arrivals: +idata.arrivals || 0,
      departures: +idata.departures || 0,
      occupied: +idata.occupied || 0,
    };
    return this.linkService.GQLRegion().pipe(
      first(),
      mergeMap((link) =>
        link
          .mutate({
            mutation: gql`
                    mutation updateClubLoungeDriverDailyForecast($date:CalendarDate!,$hotelId:ID!,$data:DriverDataClubLoungeInput!) {
                      updateClubLoungeDriverDailyForecast(date:$date,hotelId:$hotelId,data:$data) {
                        ...${fragments.clubloungedriverdayforecast.name}
                      }
                    }
                    ${fragments.clubloungedriverdayforecast.definition}
              `,
            fetchPolicy: mutationFetchPolicy,
            variables: {
              hotelId,
              date,
              data,
            },
          })
          .pipe(
            first(),
            map(({ data }: any) => {
              this.log.debug(data);
              return data.updateClubLoungeDriverDailyForecast;
            })
          )
      )
    );
  }

  setRoomServiceDriverForecastDay(date: string, hotelId: string, idata: any) {
    let data = {
      breakfast: +idata.breakfast || 0,
      lunch: +idata.lunch || 0,
      dinner: +idata.dinner || 0,
      lateNight: +idata.lateNight || 0,
    };
    this.log.debug(date, data, hotelId);
    return this.linkService.GQLRegion().pipe(
      first(),
      mergeMap((link) =>
        link
          .mutate({
            mutation: gql`
                    mutation updateRoomServiceDriverDailyForecast($date:CalendarDate!,$hotelId:ID!,$data:DriverDataRoomServiceInput!) {
                      updateRoomServiceDriverDailyForecast(date:$date,hotelId:$hotelId,data:$data) {
                        ...${fragments.roomservicedriverdayforecast.name}
                      }
                    }
                    ${fragments.roomservicedriverdayforecast.definition}
              `,
            fetchPolicy: mutationFetchPolicy,
            variables: {
              hotelId,
              date,
              data,
            },
          })
          .pipe(
            first(),
            map(({ data }: any) => {
              return data.updateRoomServiceDriverDailyForecast;
            })
          )
      )
    );
  }

  setCAndBDriverForecastDay(date: string, hotelId: string, idata: any) {
    let data = {
      breakfast: +idata.breakfast || 0,
      lunch: +idata.lunch || 0,
      dinner: +idata.dinner || 0,
      coffeeBreak: +idata.coffeeBreak || 0,
      setup: +idata.setup || 0,
      cleardown: +idata.cleardown || 0,
    };
    return this.linkService.GQLRegion().pipe(
      first(),
      mergeMap((link) =>
        link
          .mutate({
            mutation: gql`
                      mutation updateConferenceDriverDailyForecast($date:CalendarDate!,$hotelId:ID!,$data:DriverDataConferenceInput!) {
                        updateConferenceDriverDailyForecast(date:$date,hotelId:$hotelId,data:$data) {
                          ...${fragments.candbdriverdayforecast.name}
                        }
                      }
                      ${fragments.candbdriverdayforecast.definition}
                `,
            fetchPolicy: mutationFetchPolicy,
            variables: {
              hotelId,
              date,
              data,
            },
          })
          .pipe(
            first(),
            map(({ data }: any) => {
              return data.updateConferenceDriverDailyForecast;
            })
          )
      )
    );
  }

  setDriverActualDay(sectionType: SectionType, outletIndex: number, idate: Date, data: any, hotelIdIn?: string) {
    let date = makeISODateOnly(idate);
    return this.linkService.awaitCurrentHotel(hotelIdIn).pipe(
      mergeMap((hotelId) => {
        if (sectionType == 'ALLMAIN') return this.setAllMainDriverActualDay(date, hotelId, data);
        else if (sectionType == 'HOTEL') return this.setHotelDriverActualDay(date, hotelId, data);
        else if (sectionType == 'FRONTOFFICE') return this.setFrontOfficeDriverActualDay(date, hotelId, data);
        else if (sectionType == 'CLUBLOUNGE') return this.setClubLoungeDriverActualDay(date, hotelId, data);
        else if (sectionType == 'ROOMSERVICE') return this.setRoomServiceDriverActualDay(date, hotelId, data);
        else if (sectionType == 'CANDB') return this.setCAndBDriverActualDay(date, hotelId, data);
        else return this.setOutletDriverActualDay(sectionType, outletIndex, date, hotelId, data);
      })
    );
  }

  setAllMainDriverActualDay(date: string, hotelId: string, idata) {
    this.log.debug('setAllMainDriverActualDay', idata);
    return this.linkService.GQLRegion(false).pipe(
      first(),
      mergeMap((link) =>
        link
          .mutate({
            mutation: gql`
                      mutation updateDriverDailyActual($date:CalendarDate!,$hotelId:ID!,$roomsOccupied:Int!,$roomsAvailable:Int!,$guests:Int!,$departures:Int!,$arrivals:Int!,$conferences:DriverDataConferenceInput,$clubLounge:DriverDataClubLoungeInput,$roomService:DriverDataRoomServiceInput) {
                        updateDriverDailyActual(date:$date,hotelId:$hotelId,roomsAvailable:$roomsAvailable,roomsOccupied:$roomsOccupied,guests:$guests,departures:$departures,arrivals:$arrivals,conferences:$conferences,clubLounge:$clubLounge,roomService:$roomService) {
                          ...${fragments.allmaindriverdayactual.name}
                        }
                      }
                      ${fragments.allmaindriverdayactual.definition}
                `,
            fetchPolicy: mutationFetchPolicy,
            variables: {
              hotelId,
              date,
              roomsOccupied: +idata.HOTELroomsOccupied || 0,
              roomsAvailable: +idata.HOTELroomsAvailable || 0,
              guests: +idata.HOTELguests || 0,
              arrivals: +idata.FRONTOFFICEarrivals || 0,
              departures: +idata.FRONTOFFICEdepartures || 0,
              conferences: {
                breakfast: +idata.CANDBbreakfast || 0,
                lunch: +idata.CANDBlunch || 0,
                dinner: +idata.CANDBdinner || 0,
                coffeeBreak: +idata.CANDBcoffeeBreak || 0,
                setup: +idata.CANDBsetup || 0,
                cleardown: +idata.CANDBcleardown || 0,
              },
              clubLounge: {
                arrivals: +idata.CLUBLOUNGEEarrivals || 0,
                departures: +idata.CLUBLOUNGEdepartures || 0,
                occupied: +idata.CLUBLOUNGEoccupied || 0,
              },
              roomService: {
                breakfast: +idata.ROOMSERVICEbreakfast || 0,
                lunch: +idata.ROOMSERVICElunch || 0,
                dinner: +idata.ROOMSERVICEdinner || 0,
                lateNight: +idata.ROOMSERVICElateNight || 0,
              },
            },
          })
          .pipe(
            first(),
            map(({ data }: any) => {
              this.log.debug(data);
              return {
                ...data.updateDriverDailyActual,
              };
            })
          )
      )
    );
  }

  setHotelDriverActualDay(date: string, hotelId: string, idata: { roomsOccupied; roomsAvailable; guests }) {
    return this.linkService.GQLRegion().pipe(
      first(),
      mergeMap((link) =>
        link
          .mutate({
            mutation: gql`
                      mutation updateHotelDriverDailyActual($date:CalendarDate!,$hotelId:ID!,$roomsOccupied:Int!,$roomsAvailable:Int!,$guests:Int!) {
                        updateHotelDriverDailyActual(date:$date,hotelId:$hotelId,roomsAvailable:$roomsAvailable,roomsOccupied:$roomsOccupied,guests:$guests) {
                          ...${fragments.hoteldriverdayforecast.name}
                        }
                      }
                      ${fragments.hoteldriverdayforecast.definition}
                `,
            fetchPolicy: mutationFetchPolicy,
            variables: {
              hotelId,
              date,
              roomsOccupied: +idata.roomsOccupied || 0,
              roomsAvailable: +idata.roomsAvailable || 0,
              guests: +idata.guests || 0,
            },
          })
          .pipe(
            first(),
            map(({ data }: any) => {
              this.log.debug(data);
              return {
                ...data.updateHotelDriverDailyActual,
                occupancy: idata.roomsAvailable > 0 ? (+idata.roomsOccupied / +idata.roomsAvailable) * 100 : 0,
              };
            })
          )
      )
    );
  }

  setFrontOfficeDriverActualDay(date: string, hotelId: string, idata: { arrivals; departures }) {
    return this.linkService.GQLRegion().pipe(
      first(),
      mergeMap((link) =>
        link
          .mutate({
            mutation: gql`
                      mutation updateFrontOfficeDriverDailyActual($date:CalendarDate!,$hotelId:ID!,$arrivals:Int!,$departures:Int!) {
                        updateFrontOfficeDriverDailyActual(date:$date,hotelId:$hotelId,arrivals:$arrivals,departures:$departures) {
                          ...${fragments.hoteldriverdayforecast.name}
                        }
                      }
                      ${fragments.hoteldriverdayforecast.definition}
                `,
            fetchPolicy: mutationFetchPolicy,
            variables: {
              hotelId,
              date,
              arrivals: +idata.arrivals || 0,
              departures: +idata.departures || 0,
            },
          })
          .pipe(
            first(),
            map(({ data }: any) => {
              this.log.debug(data);
              return data.updateHotelDriverDailyActual;
            })
          )
      )
    );
  }

  setOutletDriverActualDay(outletType: OutletType, outletIndex: number, date: string, hotelId: string, idata: any) {
    let data = {
      foodBreakfast: +idata.foodBreakfast || 0,
      foodLunch: +idata.foodLunch || 0,
      foodDinner: +idata.foodDinner || 0,
      foodLateNight: +idata.foodLateNight || 0,
      beverageBreakfast: +idata.beverageBreakfast || 0,
      beverageLunch: +idata.beverageLunch || 0,
      beverageDinner: +idata.beverageDinner || 0,
      beverageLateNight: +idata.beverageLateNight || 0,
    };
    this.log.debug('write data', outletType, outletIndex, date, data);
    return this.linkService.GQLRegion().pipe(
      first(),
      mergeMap((link) =>
        link
          .mutate({
            mutation: gql`
                      mutation updateOutletDriverDailyActual($outletType: ServiceOutletType!,$outletIndex:Int!,$date:CalendarDate!,$hotelId:ID!,$data:OutletDriverDataInput!) {
                        updateOutletDriverDailyActual(outletType:$outletType,outletIndex:$outletIndex,date:$date,hotelId:$hotelId,data:$data) {
                          ...${fragments.outletdriverdayforecast.name}
                        }
                      }
                      ${fragments.outletdriverdayforecast.definition}
                `,
            fetchPolicy: mutationFetchPolicy,
            variables: {
              hotelId,
              outletType,
              outletIndex,
              date,
              data,
            },
          })
          .pipe(
            first(),
            map(({ data }: any) => {
              return data.updateOutletDriverDailyActual;
            })
          )
      )
    );
  }

  setClubLoungeDriverActualDay(date: string, hotelId: string, idata: { arrivals; departures; occupied }) {
    const data = {
      arrivals: +idata.arrivals || 0,
      departures: +idata.departures || 0,
      occupied: +idata.occupied || 0,
    };
    return this.linkService.GQLRegion().pipe(
      first(),
      mergeMap((link) =>
        link
          .mutate({
            mutation: gql`
                      mutation updateClubLoungeDriverDailyActual($date:CalendarDate!,$hotelId:ID!,$data:DriverDataClubLoungeInput!) {
                        updateClubLoungeDriverDailyActual(date:$date,hotelId:$hotelId,data:$data) {
                          ...${fragments.clubloungedriverdayforecast.name}
                        }
                      }
                      ${fragments.clubloungedriverdayforecast.definition}
                `,
            fetchPolicy: mutationFetchPolicy,
            variables: {
              hotelId,
              date,
              data,
            },
          })
          .pipe(
            first(),
            map(({ data }: any) => {
              this.log.debug(data);
              return data.updateClubLoungeDriverDailyActual;
            })
          )
      )
    );
  }

  setRoomServiceDriverActualDay(date: string, hotelId: string, idata: any) {
    let data = {
      breakfast: +idata.breakfast || 0,
      lunch: +idata.lunch || 0,
      dinner: +idata.dinner || 0,
      lateNight: +idata.lateNight || 0,
    };
    this.log.debug(date, data, hotelId);
    return this.linkService.GQLRegion().pipe(
      first(),
      mergeMap((link) =>
        link
          .mutate({
            mutation: gql`
                      mutation updateRoomServiceDriverDailyActual($date:CalendarDate!,$hotelId:ID!,$data:DriverDataRoomServiceInput!) {
                        updateRoomServiceDriverDailyActual(date:$date,hotelId:$hotelId,data:$data) {
                          ...${fragments.roomservicedriverdayforecast.name}
                        }
                      }
                      ${fragments.roomservicedriverdayforecast.definition}
                `,
            fetchPolicy: mutationFetchPolicy,
            variables: {
              hotelId,
              date,
              data,
            },
          })
          .pipe(
            first(),
            map(({ data }: any) => {
              return data.updateRoomServiceDriverDailyActual;
            })
          )
      )
    );
  }

  setCAndBDriverActualDay(date: string, hotelId: string, idata: any) {
    let data = {
      breakfast: +idata.breakfast || 0,
      lunch: +idata.lunch || 0,
      dinner: +idata.dinner || 0,
      coffeeBreak: +idata.coffeeBreak || 0,
      setup: +idata.setup || 0,
      cleardown: +idata.cleardown || 0,
    };
    return this.linkService.GQLRegion().pipe(
      first(),
      mergeMap((link) =>
        link
          .mutate({
            mutation: gql`
                        mutation updateConferenceDriverDailyActual($date:CalendarDate!,$hotelId:ID!,$data:DriverDataConferenceInput!) {
                          updateConferenceDriverDailyActual(date:$date,hotelId:$hotelId,data:$data) {
                            ...${fragments.candbdriverdayforecast.name}
                          }
                        }
                        ${fragments.candbdriverdayforecast.definition}
                  `,
            fetchPolicy: mutationFetchPolicy,
            variables: {
              hotelId,
              date,
              data,
            },
          })
          .pipe(
            first(),
            map(({ data }: any) => {
              return data.updateConferenceDriverDailyActual;
            })
          )
      )
    );
  }

  getHotelWOT(year: number, month: number, hotelId?: string) {
    return forkJoin([
      hotelId
        ? of(hotelId)
        : this.linkService.getCurrentHotelW().pipe(
            filter((h) => h && h !== 'none'),
            first()
          ),
      this.linkService.GQLRegion().pipe(first()),
    ]).pipe(
      mergeMap(([id, link]) => {
        return link
          .query({
            query: gql`
                        query getHotelWOT($hotelId:ID!,$year:Int,$month:Month!) {
                          allDriverData(hotelId:$hotelId,month:$month,year:$year) {
                            ...${fragments.hotelWOT.name}
                          }
                        }
                        ${fragments.hotelWOT.definition}
                  `,
            fetchPolicy: queryFetchPolicy,
            variables: {
              month: mapMonthEnum(month),
              year,
              hotelId: id,
            },
          })
          .pipe(
            first(),
            map(({ data }: any) => {
              return data.allDriverData[0];
            })
          );
      })
    );
  }

  getOutletWOT(year: number, month: number, hotelId?: string) {
    return forkJoin([
      hotelId
        ? of(hotelId)
        : this.linkService.getCurrentHotelW().pipe(
            filter((h) => h && h !== 'none'),
            first()
          ),
      this.linkService.GQLRegion().pipe(first()),
    ]).pipe(
      mergeMap(([id, link]) => {
        return link
          .query({
            query: gql`
                        query getOutletWOT($hotelId:ID!,$year:Int,$month:Month!) {
                          allOutletDriverData(hotelId:$hotelId,month:$month,year:$year) {
                            ...${fragments.outletWOT.name}
                          }
                        }
                        ${fragments.outletWOT.definition}
                  `,
            fetchPolicy: queryFetchPolicy,
            variables: {
              month: mapMonthEnum(month),
              year,
              hotelId: id,
            },
          })
          .pipe(
            first(),
            map(({ data }: any) => {
              return data.allOutletDriverData;
            })
          );
      })
    );
  }

  getDriverActualDays(sectionType: SectionType, outletIndex: number, isdate: Date, iedate: Date, hotelIdIn?: string) {
    let sdate = makeISODateOnly(isdate);
    let edate = makeISODateOnly(iedate);
    return this.linkService.awaitCurrentHotel(hotelIdIn).pipe(
      mergeMap((hotelId) => {
        if (sectionType == 'HOTEL' || sectionType === 'FRONTOFFICE' || sectionType === 'ALLMAIN')
          return this.getHotelDriverActualDays(sdate, edate, hotelId);
        else if (sectionType == 'CLUBLOUNGE') return this.getClubLoungeDriverActualDays(sdate, edate, hotelId);
        else if (sectionType == 'ROOMSERVICE') return this.getRoomServiceDriverActualDays(sdate, edate, hotelId);
        else if (sectionType == 'CANDB') return this.getCAndBDriverActualDays(sdate, edate, hotelId);
        else return this.getOutletDriverActualDays(sectionType, outletIndex, sdate, edate, hotelId);
      })
    );
  }

  getHotelDriverActualDays(sdate: string, edate: string, hotelId: string) {
    let between = { start: sdate, end: edate };
    return this.linkService.GQLRegion().pipe(
      mergeMap((link) =>
        link
          .query({
            query: gql`
                    query getHotelDriverDailyActuals($between:BetweenInput,$hotelId:ID!) {
                      getHotelDriverDailyActuals(between:$between,hotelId:$hotelId) {
                        ...${fragments.hoteldriverdayforecast.name}
                      }
                    }
                    ${fragments.hoteldriverdayforecast.definition}
              `,
            fetchPolicy: queryFetchPolicy,
            variables: {
              hotelId,
              between,
            },
          })
          .pipe(
            first(),
            map(({ data }: any) => {
              let ret = data.getHotelDriverDailyActuals.map((d) => {
                //map out the data with some calulations
                const { roomsOccupied, roomsAvailable, ...rest } = d;
                return {
                  ...rest,
                  roomsAvailable,
                  roomsOccupied,
                  occupancy: roomsAvailable > 0 ? ((roomsOccupied || 0) / roomsAvailable) * 100 : null,
                };
              });
              return ret;
            })
          )
      )
    );
  }

  getClubLoungeDriverActualDays(sdate: string, edate: string, hotelId: string) {
    let between = { start: sdate, end: edate };
    return this.linkService.GQLRegion().pipe(
      mergeMap((link) =>
        link
          .query({
            query: gql`
                    query getClubLoungeDriverDailyActuals($between:BetweenInput,$hotelId:ID!) {
                      getClubLoungeDriverDailyActuals(between:$between,hotelId:$hotelId) {
                        ...${fragments.clubloungedriverdayforecast.name}
                      }
                    }
                    ${fragments.clubloungedriverdayforecast.definition}
              `,
            fetchPolicy: queryFetchPolicy,
            variables: {
              hotelId,
              between,
            },
          })
          .pipe(
            first(),
            map(({ data }: any) => {
              return data.getClubLoungeDriverDailyActuals;
            })
          )
      )
    );
  }

  getRoomServiceDriverActualDays(sdate: string, edate: string, hotelId: string) {
    let between = { start: sdate, end: edate };

    return this.linkService.GQLRegion().pipe(
      mergeMap((link) =>
        link
          .query({
            query: gql`
                      query getRoomServiceDriverDailyActuals($between:BetweenInput,$hotelId:ID!) {
                        getRoomServiceDriverDailyActuals(between:$between,hotelId:$hotelId) {
                          ...${fragments.roomservicedriverdayforecast.name}
                        }
                      }
                      ${fragments.roomservicedriverdayforecast.definition}
                `,
            fetchPolicy: queryFetchPolicy,
            variables: {
              hotelId,
              between,
            },
          })
          .pipe(
            first(),
            map(({ data }: any) => {
              return data.getRoomServiceDriverDailyActuals;
            })
          )
      )
    );
  }

  getCAndBDriverActualDays(sdate: string, edate: string, hotelId: string) {
    let between = { start: sdate, end: edate };
    return this.linkService.GQLRegion().pipe(
      mergeMap((link) =>
        link
          .query({
            query: gql`
                        query getConferenceDriverDailyActuals($between:BetweenInput,$hotelId:ID!) {
                          getConferenceDriverDailyActuals(between:$between,hotelId:$hotelId) {
                            ...${fragments.candbdriverdayforecast.name}
                          }
                        }
                        ${fragments.candbdriverdayforecast.definition}
                  `,
            fetchPolicy: queryFetchPolicy,
            variables: {
              hotelId,
              between,
            },
          })
          .pipe(
            first(),
            map(({ data }: any) => {
              return data.getConferenceDriverDailyActuals;
            })
          )
      )
    );
  }

  getOutletDriverActualDays(outletType: OutletType, outletIndex: number, sdate: string, edate: string, hotelId: string) {
    let between = { start: sdate, end: edate };
    return this.linkService.GQLRegion().pipe(
      mergeMap((link) =>
        link
          .query({
            query: gql`
                    query getOutletDriverDailyActuals($outletType: ServiceOutletType!,$outletIndex:Int!,$between:BetweenInput,$hotelId:ID!) {
                      getOutletDriverDailyActuals(outletType:$outletType,outletIndex:$outletIndex,between:$between,hotelId:$hotelId) {
                        ...${fragments.outletdriverdayforecast.name}
                      }
                    }
                    ${fragments.outletdriverdayforecast.definition}
              `,
            fetchPolicy: queryFetchPolicy,
            variables: {
              hotelId,
              outletType,
              outletIndex,
              between,
            },
          })
          .pipe(
            first(),
            map(({ data }: any) => {
              return data.getOutletDriverDailyActuals;
            })
          )
      )
    );
  }

  getDriverForecastDays(sectionType: SectionType, outletIndex: number, isdate: Date, iedate: Date, hotelIdIn?: string) {
    let sdate = makeISODateOnly(isdate);
    let edate = makeISODateOnly(iedate);
    return this.linkService.awaitCurrentHotel(hotelIdIn).pipe(
      mergeMap((hotelId) => {
        if (sectionType == 'HOTEL' || sectionType === 'FRONTOFFICE' || sectionType === 'ALLMAIN')
          return this.getHotelDriverForecastDays(sdate, edate, hotelId);
        else if (sectionType == 'CLUBLOUNGE') return this.getClubLoungeDriverForecastDays(sdate, edate, hotelId);
        else if (sectionType == 'ROOMSERVICE') return this.getRoomServiceDriverForecastDays(sdate, edate, hotelId);
        else if (sectionType == 'CANDB') return this.getCAndBDriverForecastDays(sdate, edate, hotelId);
        else return this.getOutletDriverForecastDays(sectionType, outletIndex, sdate, edate, hotelId);
      })
    );
  }

  getHotelDriverForecastDays(sdate: string, edate: string, hotelId: string) {
    let between = { start: sdate, end: edate };
    return this.linkService.GQLRegion().pipe(
      mergeMap((link) =>
        link
          .query({
            query: gql`
                    query getHotelDriverDailyForecasts($between:BetweenInput,$hotelId:ID!) {
                      getHotelDriverDailyForecasts(between:$between,hotelId:$hotelId) {
                        ...${fragments.hoteldriverdayforecast.name}
                      }
                    }
                    ${fragments.hoteldriverdayforecast.definition}
              `,
            fetchPolicy: queryFetchPolicy,
            variables: {
              hotelId,
              between,
            },
          })
          .pipe(
            first(),
            map(({ data }: any) => {
              let ret = data.getHotelDriverDailyForecasts.map((d) => {
                //map out the data with some calulations
                const { roomsOccupied, roomsAvailable, ...rest } = d;
                return {
                  ...rest,
                  roomsAvailable,
                  roomsOccupied,
                  occupancy: roomsAvailable > 0 ? ((roomsOccupied || 0) / roomsAvailable) * 100 : null,
                };
              });
              return ret;
            })
          )
      )
    );
  }

  getClubLoungeDriverForecastDays(sdate: string, edate: string, hotelId: string) {
    let between = { start: sdate, end: edate };
    return this.linkService.GQLRegion().pipe(
      mergeMap((link) =>
        link
          .query({
            query: gql`
                    query getClubLoungeDriverDailyForecasts($between:BetweenInput,$hotelId:ID!) {
                      getClubLoungeDriverDailyForecasts(between:$between,hotelId:$hotelId) {
                        ...${fragments.clubloungedriverdayforecast.name}
                      }
                    }
                    ${fragments.clubloungedriverdayforecast.definition}
              `,
            fetchPolicy: queryFetchPolicy,
            variables: {
              hotelId,
              between,
            },
          })
          .pipe(
            first(),
            map(({ data }: any) => {
              return data.getClubLoungeDriverDailyForecasts;
            })
          )
      )
    );
  }

  getRoomServiceDriverForecastDays(sdate: string, edate: string, hotelId: string) {
    let between = { start: sdate, end: edate };

    return this.linkService.GQLRegion().pipe(
      mergeMap((link) =>
        link
          .query({
            query: gql`
                      query getRoomServiceDriverDailyForecasts($between:BetweenInput,$hotelId:ID!) {
                        getRoomServiceDriverDailyForecasts(between:$between,hotelId:$hotelId) {
                          ...${fragments.roomservicedriverdayforecast.name}
                        }
                      }
                      ${fragments.roomservicedriverdayforecast.definition}
                `,
            fetchPolicy: queryFetchPolicy,
            variables: {
              hotelId,
              between,
            },
          })
          .pipe(
            first(),
            map(({ data }: any) => {
              return data.getRoomServiceDriverDailyForecasts;
            })
          )
      )
    );
  }

  getCAndBDriverForecastDays(sdate: string, edate: string, hotelId: string) {
    let between = { start: sdate, end: edate };
    return this.linkService.GQLRegion().pipe(
      mergeMap((link) =>
        link
          .query({
            query: gql`
                        query getConferenceDriverDailyForecasts($between:BetweenInput,$hotelId:ID!) {
                          getConferenceDriverDailyForecasts(between:$between,hotelId:$hotelId) {
                            ...${fragments.candbdriverdayforecast.name}
                          }
                        }
                        ${fragments.candbdriverdayforecast.definition}
                  `,
            fetchPolicy: queryFetchPolicy,
            variables: {
              hotelId,
              between,
            },
          })
          .pipe(
            first(),
            map(({ data }: any) => {
              return data.getConferenceDriverDailyForecasts;
            })
          )
      )
    );
  }

  getOutletDriverForecastDays(outletType: OutletType, outletIndex: number, sdate: string, edate: string, hotelId: string) {
    let between = { start: sdate, end: edate };
    return this.linkService.GQLRegion().pipe(
      mergeMap((link) =>
        link
          .query({
            query: gql`
                    query getOutletDriverDailyForecasts($outletType: ServiceOutletType!,$outletIndex:Int!,$between:BetweenInput,$hotelId:ID!) {
                      getOutletDriverDailyForecasts(outletType:$outletType,outletIndex:$outletIndex,between:$between,hotelId:$hotelId) {
                        ...${fragments.outletdriverdayforecast.name}
                      }
                    }
                    ${fragments.outletdriverdayforecast.definition}
              `,
            fetchPolicy: queryFetchPolicy,
            variables: {
              hotelId,
              outletType,
              outletIndex,
              between,
            },
          })
          .pipe(
            first(),
            map(({ data }: any) => {
              return data.getOutletDriverDailyForecasts;
            })
          )
      )
    );
  }

  requestReportRefresh(sdate: Date, edate: Date, reportType: 'ACTUAL' | 'FORECAST' | 'ALL', hotelIdIn?: string) {
    const start = makeISODateOnly(sdate);
    const end = makeISODateOnly(edate);
    return this.linkService.awaitCurrentHotel(hotelIdIn).pipe(
      mergeMap((hotelId) => {
        return this.linkService.GQLRegion(false).pipe(
          first(),
          mergeMap((link) =>
            link
              .mutate({
                mutation: gql`
                  mutation requestDailyReports($start: CalendarDate!, $end: CalendarDate!, $hotelId: ID!, $reportType: DailyReportType!) {
                    requestBefDailyReports(date: { start: $start, end: $end }, hotelId: $hotelId, reportType: $reportType)
                  }
                `,
                variables: {
                  hotelId,
                  start,
                  end,
                  reportType,
                },
              })
              .pipe(
                first(),
                map(({ data }: any) => {
                  this.log.debug(data);
                  return data.requestDailyReports;
                })
              )
          )
        );
      })
    );
  }
}

const fragments = {
  outletdriverdayforecast: {
    name: 'OutletDriverDayForecastFragment',
    definition: gql`
      fragment OutletDriverDayForecastFragment on OutletDriverDailyData {
        id
        year
        month
        day
        outletIndex
        outletType
        foodBreakfast
        foodLunch
        foodDinner
        foodLateNight
        beverageBreakfast
        beverageLunch
        beverageDinner
        beverageLateNight
      }
    `,
  },
  allmaindriverdayforecast: {
    name: 'AllMainDriverDayForecastFragment',
    definition: gql`
      fragment AllMainDriverDayForecastFragment on DriverDataDaily {
        id
        year
        month
        day
      }
    `,
  },
  allmaindriverdayactual: {
    name: 'AllMainDriverDayActualFragment',
    definition: gql`
      fragment AllMainDriverDayActualFragment on DriverDataDaily {
        id
        year
        month
        day
      }
    `,
  },

  hoteldriverdayforecast: {
    name: 'HotelDriverDayForecastFragment',
    definition: gql`
      fragment HotelDriverDayForecastFragment on DriverDataHotelDaily {
        id
        year
        month
        day
        roomsOccupied
        roomsAvailable
        guests
        arrivals
        departures
      }
    `,
  },
  clubloungedriverdayforecast: {
    name: 'ClubLoungeDriverDayForecastFragment',
    definition: gql`
      fragment ClubLoungeDriverDayForecastFragment on DriverDataClubLoungeDaily {
        id
        year
        month
        day
        occupied: roomsOccupied
        arrivals
        departures
      }
    `,
  },
  roomservicedriverdayforecast: {
    name: 'RoomServiceDriverDayForecastFragment',
    definition: gql`
      fragment RoomServiceDriverDayForecastFragment on DriverDataRoomServiceDaily {
        id
        year
        month
        day
        breakfast
        lunch
        dinner
        lateNight
      }
    `,
  },
  candbdriverdayforecast: {
    name: 'CAndBDriverDayForecastFragment',
    definition: gql`
      fragment CAndBDriverDayForecastFragment on DriverDataConferenceDaily {
        id
        year
        month
        day
        breakfast
        lunch
        dinner
        coffeeBreak
        setup
        cleardown
      }
    `,
  },
  hotelWOT: {
    name: 'HotelWOTFragment',
    definition: gql`
      fragment HotelWOTFragment on DriverData {
        id
        roomsOccupied
        roomsAvailable
        arrivals
        departures
        clubLounge {
          arrivals
          departures
          roomsOccupied
        }
        roomService {
          breakfast
          lunch
          dinner
          lateNight
        }
        conferences {
          breakfast
          lunch
          dinner
          coffeeBreak
        }
      }
    `,
  },
  outletWOT: {
    name: 'OutletWOTFragment',
    definition: gql`
      fragment OutletWOTFragment on OutletDriverData {
        id
        outletType
        outletIndex
        foodBreakfast
        foodLunch
        foodDinner
        foodLateNight
        beverageBreakfast
        beverageLunch
        beverageDinner
        beverageLateNight
      }
    `,
  },
};
