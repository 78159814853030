import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { CentralDataService } from 'src/services/central-data.service';

import { UIService } from 'src/services/ui.service';

@Component({
  selector: 'app-releasenotes',
  styleUrls: ['./releasenotes.component.scss'],
  templateUrl: './releasenotes.component.html',
})
export class ReleaseNotesComponent implements OnInit {
  constructor(
    private centralDataService: CentralDataService,
    private router: Router,
    private ui: UIService,
    private sanitizer: DomSanitizer
  ) {}

  public showUrl = null;

  ngOnInit() {
    this.showDoc();
  }

  showDoc() {
    this.centralDataService
      .getHelpDocUrl('/sched_docs/release.pdf')
      .pipe(first())
      .subscribe(
        (url) => {
          this.showUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
        },
        (error) => {
          this.ui.error('Error loading document', 'The document you requested is not currently available.');
        }
      );
  }

  close() {
    this.router.navigate(['/main/home']);
    this.showUrl = null;
  }
}
