<p-panel header="Import System for Actual Hours Worked by Employees">
  <div class="p-d-flex p-jc-left p-ai-center" *ngIf="loadedDeps == null && !isWorking">
    <button
      icon="pi pi-download"
      class="p-mx-1 p-button-info"
      pButton
      type="button"
      label="Create a blank template file"
      (click)="exportActualsTemplate()"
    ></button>
    <p-button icon="pi pi-upload" class="p-mx-1" label="Upload a file to import now?" (click)="startImport()"></p-button>
  </div>
  <p-progressBar *ngIf="isWorking" mode="indeterminate"></p-progressBar>
  <div *ngIf="loadedDeps">
    <div *ngFor="let dep of loadedDeps">
      <div class="p-d-flex p-jc-left p-ai-center deprow">
        <div class="depname">
          {{ dep.deptFullName }}
        </div>
        <div>
          <div>{{ dep.matched }} shifts matched to schedule and can be created.</div>
          <div *ngIf="dep.unplanned" class="warn">
            {{ dep.unplanned }} shifts will be created as unplanned and require manual confirmation.
          </div>
          <div *ngIf="dep.badId" class="bad">{{ dep.badId }} shifts include invalid employees.</div>
          <div *ngIf="dep.badDepartment" class="bad">{{ dep.badDepartment }} shifts include invalid departments.</div>
          <div *ngIf="dep.badSchedule" class="bad">{{ dep.badSchedule }} shift(s) are on days that have already been submitted.</div>
          <div *ngIf="dep.badTimes" class="bad">
            {{ dep.badTimes }} shift(s) have invalid times (Bad format; missing times; ends before they start).
          </div>
          <div *ngIf="dep.problemRows.length" class="bad">
            The following rows in the import file had critical errors: {{ dep.problemRows.join(',') }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="p-d-flex p-jc-left p-ai-center" *ngIf="loadedDeps && !isWorking && !fileLoaded">
    <p-button
      icon="pi pi-upload"
      *ngIf="!totalBad"
      class="p-mx-1"
      label="Capture {{ totalUpdate }} shift(s) and {{ totalUUpdate }} unplanned shifts from this file?"
      (click)="doImport()"
    ></p-button>
    <button
      icon="pi pi-cross"
      type="button"
      pButton
      class="p-mx-1 p-button-warning"
      label="Do not import any records, load a new / fixed file."
      (click)="loadedDeps = null"
    ></button>
  </div>
  <div class="p-d-flex p-jc-left p-ai-center" *ngIf="loadedDeps && !isWorking && fileLoaded">
    <p-button icon="pi pi-cross" class="p-mx-1" label="Import another file" (click)="loadedDeps = null; fileLoaded = false"></p-button>
  </div>

  <input #uploadarea name="files" type="file" hidden (change)="uploader($event)" />
</p-panel>
<br />
<p-panel header="Current Shifts that Require Manual Confirmation" *ngIf="badShifts.length">
  <div *ngFor="let bs of badShifts" class="p-d-flex p-jc-between p-ai-center">
    <div>{{ bs.deptFullName }}</div>
    <div>in the week Of {{ bs.start }}</div>
    <div>{{ bs.count }} shift(s) require confirmation</div>
    <p-button icon="pi pi-view" label="Show in Actuals" (click)="showActuals(bs)"></p-button>
  </div>
</p-panel>
