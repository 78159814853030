<div class="top">
  <div class="container">
    <div class="innercontainer">
      <p-table
        [value]="rows"
        [loading]="isLoading || firstLoad"
        #dt
        styleClass="p-datatable-gridlines p-datatable-striped"
        [scrollable]="true"
        scrollHeight="flex"
      >
        <ng-template pTemplate="caption">
          <div class="p-d-flex p-jc-between p-ai-center">
            <label
              >Starting
              <p-button icon="pi pi-arrow-left" (click)="setCal(-7)" class="p-mx-1"></p-button>
              <p-calendar
                [(ngModel)]="weekStart"
                #calendar
                (ngModelChange)="dateChange()"
                dateFormat="yy-mm-dd"
                [monthNavigator]="true"
                [yearNavigator]="true"
                [yearRange]="yearRange"
                [showIcon]="true"
                appendTo="body"
                persistLocal="statusstartdate"
              >
                <ng-template pTemplate="footer">
                  <div class="calendar-footer">
                    <button
                      pButton
                      pRipple
                      type="button"
                      label="Now"
                      class="p-button-text"
                      (click)="setToday(); calendar.toggle()"
                    ></button>
                  </div>
                </ng-template>
              </p-calendar>
              <p-button icon="pi pi-arrow-right" (click)="setCal(+7)" class="p-mx-1"></p-button>
            </label>
            <label
              >Display
              <p-dropdown
                [options]="showTimes"
                [(ngModel)]="showTime"
                optionLabel="label"
                optionValue="code"
                appendTo="body"
                (ngModelChange)="dateChange()"
              >
              </p-dropdown>
            </label>
            <div class="p-d-flex p-ai-center">
              <label class="warning">
                Changes to schedule and actual numbers may be delayed up to 5 minutes; will refresh automatically
              </label>
              <p-button
                icon="pi pi-refresh"
                pTooltip="Force data refresh"
                [disabled]="isRefreshing || isLoading"
                (click)="refresh()"
                class="p-mx-1"
              ></p-button>
            </div>
            <help-overlay [helpfiles]="[{ file: 'dashboard_instruction', description: 'Instructions' }]"></help-overlay>
          </div>
          <div *ngIf="updated || usedCache" style="padding-left: 1rem; margin-top: -14.5px">
            <span style="font-size: x-small; font-weight: lighter"
              >Retrieved:<ng-container *ngIf="updated"> {{ updated | date: 'YYYY-MM-dd HH:mm (z)' }}</ng-container
              ><ng-container *ngIf="usedCache"> from cache</ng-container></span
            >
          </div>
        </ng-template>
        <ng-template pTemplate="colgroup">
          <colgroup>
            <col style="width: 15rem" />
            <col style="width: 4rem" />
            <col style="width: 4rem" />
            <col style="width: 4rem" />
            <col style="width: 4rem" class="breakcol" />
            <col style="width: 4rem" class="breakcol" />
            <col style="width: 4rem" />
            <col style="width: 4rem" />
            <col style="width: 4rem" />
          </colgroup>
        </ng-template>
        <ng-template pTemplate="header">
          <tr class="headrow">
            <th></th>
            <th colspan="5">Planning Schedules</th>
            <th colspan="3">Actualising Schedules</th>
          </tr>
          <tr class="headrow">
            <th>Department</th>
            <th>Schedule hours saved <br /><i>(# days / 7 days)</i></th>
            <th *ngIf="context.allowOptimals() | async">
              <span> Schedule working hours / forecast optimal hours </span>
            </th>
            <th *ngIf="!(context.allowOptimals() | async)">
              <span> Schedule working hours </span>
            </th>
            <th>GM Approval</th>
            <th>View</th>

            <th>
              <span> Changes Post-approval <br /><i>(# amended shifts)</i> </span>
            </th>
            <th>
              <span> Actual Hours saved <br /><i>(# days / 7 days)</i> </span>
            </th>
            <th>Actual Hours locked <br /><i>(# days / 7 days)</i></th>
            <th>View</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage" let-columns>
          <tr class="emptymessage">
            <td [attr.colspan]="11">
              <div class="emptymessage">
                No forecast optimal nor scheduled hours available at this time, will keep checking.&nbsp;<i
                  class="pi pi-spin pi-refresh"
                ></i>
              </div>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-row let-rowIndex="rowIndex">
          <tr *ngIf="row.newDivision" class="divisionheader">
            <td>
              {{ getDivisionName(row.classIndex) }}
            </td>
            <td class="ratiocell">{{ (row.newDivision.scheduleSaved * 100) / row.newDivision.allDays | number: '1.0-0' }}%</td>
            <td></td>
            <td class="ratiocell">
              {{ (row.newDivision.weeksApproved * 100) / row.newDivision.depCount | number: '1.0-0' }}%
              <i
                *ngIf="row.newDivision.weeksApproved + row.newDivision.weeksAutoApproved < row.newDivision.depCount"
                pTooltip="Approve Division"
                class="pi pi-check-square llink floatr"
                (click)="approveAllDivision(row, rowIndex)"
              ></i>
            </td>
            <td></td>
            <td></td>
            <td class="ratiocell">{{ (row.newDivision.actualSaved * 100) / row.newDivision.allDays | number: '1.0-0' }}%</td>
            <td class="ratiocell">{{ (row.newDivision.actualLocked * 100) / row.newDivision.allDays | number: '1.0-0' }}%</td>
            <td></td>
          </tr>
          <tr>
            <td>
              {{ row.department }}
            </td>
            <td class="ratiocell" [pTooltip]="row.submitCount + ' schedule(s) has been submitted for the week'">
              {{ row.submitCount }} / 7
            </td>
            <td
              *ngIf="context.allowOptimals() | async"
              class="ratiocell colcoded"
              [ngClass]="{ over: row.scheduledHours > row.forecastHours, under: row.scheduledHours < row.forecastHours }"
            >
              {{ row.scheduledHours | number: '1.0-1' }} / {{ row.forecastHours | number: '1.0-1' }}
            </td>
            <td *ngIf="!(context.allowOptimals() | async)" class="ratiocell">
              {{ row.scheduledHours | number: '1.0-1' }}
            </td>

            <td class="ratiocell">
              <i *ngIf="row.isAutoApproved" pTooltip="auto approved" class="pi pi-desktop red"></i>
              <i *ngIf="!row.isAutoApproved && row.isApproved" pTooltip="GM approved" class="pi pi-check-square green"></i>
              <i
                *ngIf="!row.isAutoApproved && !row.isApproved"
                pTooltip="needs approval"
                class="pi pi-check-square red"
                (click)="approveAll(row)"
              ></i>
            </td>
            <td class="ratiocell">
              <i class="pi pi-external-link llink" pTooltip="view scheduled hours" (click)="openDetail(row, false)"></i>
            </td>
            <td class="ratiocell">
              {{ row.postChanges }}
            </td>
            <td class="ratiocell" [pTooltip]="row.cSubmitCount + ' schedule(s) has been captured and submitted for the week'">
              {{ row.cSubmitCount }} / 7
            </td>
            <td class="ratiocell" [pTooltip]="row.cApprovedCount + ' captured schedule(s) has been locked for the week'">
              {{ row.cApprovedCount }} / 7
            </td>
            <td class="ratiocell">
              <i class="pi pi-external-link llink" pTooltip="view actual hours" (click)="openDetail(row, true)"></i>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="footer">
          <tr class="divisionheader">
            <th>Total</th>
            <th class="ratiocell">{{ (total.scheduleSaved * 100) / total.allDays | number: '1.0-0' }}%</th>
            <th></th>
            <th class="ratiocell">{{ (total.weeksApproved * 100) / total.depCount | number: '1.0-0' }}%</th>
            <th></th>
            <th></th>
            <th class="ratiocell">{{ (total.actualSaved * 100) / total.allDays | number: '1.0-0' }}%</th>
            <th class="ratiocell">{{ (total.actualLocked * 100) / total.allDays | number: '1.0-0' }}%</th>
            <th></th>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
  <app-comment-panel [dateReference]="commentDate"> </app-comment-panel>
</div>
