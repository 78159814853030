<app-shift-display
  (shiftClicked)="modifyShift($event)"
  (cellAddClicked)="addCellShift($event)"
  (colAddClicked)="colMenu($event)"
  (colSaveClicked)="colSaveMenu($event)"
  (colApproveClicked)="colApprove($event)"
  (colUnapproveClicked)="colUnapprove($event)"
  [loadCapturedShift]="true"
  [showColAddOnSelection]="true"
  [showColApprove]="canApprove"
  [extraCommand]="canSubmit ? 'Submit All' : null"
  extraCommandIcon="pi-pencil"
  (extraCommandClicked)="submitAll($event)"
  [extraCommand2]="canApprove ? 'Lock All' : null"
  extraCommandIcon2="pi-lock"
  (extraCommandClicked2)="lockAll($event)"
  (exportClicked)="export($event)"
  [helpfiles]="[
    { file: 'capture_instruction', description: 'Instructions' },
    { file: 'capture_scheduling', description: 'Scheduling Process' }
  ]"
></app-shift-display>
<input #uploadarea name="files" type="file" hidden (change)="uploader($event)" [disabled]="disableEditShift" />
<app-captured-shift [shift]="editShift" [disabled]="disableEditShift" (onChange)="saveEditShift()" (onCancel)="cancelEditShift()">
</app-captured-shift>
