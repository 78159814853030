import { Component, OnInit } from '@angular/core';

import { ContextService } from 'src/services/context.service';

@Component({
  selector: 'app-home',
  styleUrls: ['./home.component.scss'],
  templateUrl: './home.component.html',
})
export class HomeComponent implements OnInit {

  constructor(public contextService:ContextService) {}


  ngOnInit() {

  }


}
