<p-dropdown
  [options]="roles"
  [(ngModel)]="selectedRole"
  filter="true"
  optionLabel="label"
  [placeholder]="placeholder"
  (onChange)="select()"
  [style]="{ width: '20em' }"
  [disabled]="disabled"
  [showClear]="showClear"
  resetFilterOnHide="true"
  appendTo="body"
>
</p-dropdown>
<p-progressBar *ngIf="showLoading" mode="indeterminate" [style]="{ height: '6px' }"></p-progressBar>
