import { Component, OnInit, Output, Input, EventEmitter, forwardRef, OnDestroy } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { RegionDataService } from './../../services/region-data.service';

import { FullDepartment } from './../../commontypes/manning';
import { Subscription } from 'rxjs';
import { orderByOutlet } from './../../commontypes/util';
import { ContextService } from 'src/services/context.service';
import { filter, map } from 'rxjs/operators';
import { LoggingService } from 'src/services/logging.service';

@Component({
  selector: 'app-full-department-drop-down',
  templateUrl: './full-department-drop-down.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FullDepartmentDropDownComponent),
      multi: true,
    },
  ],
})
export class FullDepartmentDropDownComponent implements ControlValueAccessor, OnInit, OnDestroy {
  constructor(private data: RegionDataService, private log: LoggingService, private contextService: ContextService) {}

  @Output() onChange = new EventEmitter<FullDepartment | FullDepartment[]>();
  @Input() hotelId;
  @Input() useCluster = false;
  @Input() disabled = false;
  @Input() showClear = false;
  @Input() placeholder = 'Select a Department';
  @Input() multi = false;
  @Input() verifyDepartmentAccess = false;
  @Input()
  get excludeDepts() {
    return this.excluded;
  }
  set excludeDepts(value: (string | FullDepartment)[]) {
    this.excluded =
      value && value[0] ? (typeof value[0] === 'string' ? (value as string[]) : (value as FullDepartment[]).map((v) => v.label)) : [];
    this.filterExcludedDepartments();
  }

  private department$: Subscription;
  private hotel$: Subscription;
  private propagateChange = null;
  private excluded: string[] = [];
  private allDepartments: FullDepartment[] = [];
  departments: FullDepartment[] = [];
  selectedDepartment: FullDepartment | FullDepartment[] = null;
  pendingSelection: FullDepartment = null;
  showLoading = false;

  filterExcludedDepartments() {
    if (!this.allDepartments.length) return; //can't filter them yet
    if (this.excluded.length > 0) {
      this.departments = [...this.allDepartments.filter((dep) => !this.excluded.includes(dep.label))];
      if (this.selectedDepartment) {
        if (this.multi) {
          if (this.selectedDepartment) {
            this.selectedDepartment = (this.selectedDepartment as FullDepartment[]).filter(
              (dep) => dep && !this.excluded.includes(dep.label)
            );
            this.onChange.emit(this.selectedDepartment);
          }
        } else if (this.excluded.includes((this.selectedDepartment as FullDepartment).label)) {
          this.selectedDepartment = undefined;
          this.onChange.emit(this.selectedDepartment);
        }
      }
    } else this.departments = this.allDepartments;
    if (!this.multi) {
      //Horrific work around for timing issues on primeNG dropdown - need to select a matching item from the final list
      setTimeout(() => {
        this.selectedDepartment =
          this.pendingSelection && // work around for https://sudoorgza.sentry.io/issues/5026641284/?project=5764862
          this.departments.find((d: FullDepartment) => {
            if (d.name != this.pendingSelection.name) return false;
            if (this.pendingSelection.subDeptName && d.subDeptName != this.pendingSelection.subDeptName) return false;
            if (this.pendingSelection.outletType && d.outletType != this.pendingSelection.outletType) return false;
            if (this.pendingSelection.outletIndex && d.outletIndex != this.pendingSelection.outletIndex) return false;
            return true;
          });
      }, 10);
    } else {
      //reset the multisin the same way
      setTimeout(() => {
        this.selectedDepartment = (this.selectedDepartment as FullDepartment[]).map((sd) => {
          return this.departments.find((d: FullDepartment) => {
            if (!d || !sd) return false; // work around for https://sudoorgza.sentry.io/issues/5075975444/?project=5764862
            if (d.name != sd.name) return false;
            if (sd.subDeptName && d.subDeptName != sd.subDeptName) return false;
            if (sd.outletType && d.outletType != sd.outletType) return false;
            if (sd.outletIndex && d.outletIndex != sd.outletIndex) return false;
            return true;
          });
        });
      }, 10);
    }
  }

  writeValue(value: any) {
    this.selectedDepartment = value;
    if (!this.multi) this.pendingSelection = value;
  }

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched() {}

  ngOnInit() {
    if (!this.hotelId) {
      this.showLoading = true;
      this.hotel$ = this.contextService
        .getCurrentBasicHotel$()
        .pipe(filter((hotel) => hotel?.id))
        .subscribe((hotel) => this.loadData(hotel?.id));
    } else {
      this.loadData(this.hotelId);
    }
  }

  loadData(hotelId) {
    this.showLoading = true;
    this.department$ = (
      this.useCluster
        ? this.data
            .getFullDepartmentsForCluster(null, this.verifyDepartmentAccess)
            .pipe(map((depts) => depts.filter((dept) => dept.hotelId === hotelId)))
        : this.data.getFullDepartmentsForHotel(hotelId, this.verifyDepartmentAccess)
    ).subscribe((result: FullDepartment[]) => {
      this.allDepartments = result.sort((a, b) =>
        a.defaultOrder < b.defaultOrder ? -1 : a.defaultOrder > b.defaultOrder ? 1 : orderByOutlet(a, b)
      );
      this.filterExcludedDepartments();
      this.showLoading = false;
    });
  }

  ngOnDestroy() {
    if (this.department$) {
      this.department$.unsubscribe();
      this.department$ = undefined;
    }
    if (this.hotel$) {
      this.hotel$.unsubscribe();
      this.hotel$ = undefined;
    }
  }

  select() {
    this.propagateChange(this.selectedDepartment);
    this.onChange.emit(this.selectedDepartment);
  }
}
