import { Injectable } from '@angular/core';

import { Observable, BehaviorSubject, Subject, forkJoin, Subscription, iif } from 'rxjs';

import { MenuItem } from 'primeng/api';
import { AuthService } from './auth.service';
import { CentralDataService } from './central-data.service';
import { VersionCheckService } from './version-check.service';
import { LinkService } from './link.service';
import { RegionDataService } from './region-data.service';
import { SentryService } from './sentry.service';
import { LocalService } from './local.service';
import { distinctUntilChanged, filter, first, map, mergeMap, switchMap, distinctUntilKeyChanged } from 'rxjs/operators';

import { Right, Scope } from 'src/commontypes/util';
import { LoggingService } from './logging.service';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { of } from 'rxjs';
import { gql } from 'apollo-angular';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

dayjs.extend(timezone);
dayjs.extend(utc);

interface IMenuItem extends MenuItem {
  hideMain?: boolean;
  items?: IMenuItem[];
}
@Injectable({
  providedIn: 'root',
})
export class ContextService {
  public $currentRegion = new BehaviorSubject<string>('');
  public $currentRegionId = new BehaviorSubject<string>('');
  public $regionMenu = new Subject<MenuItem[]>();
  public $hotelMenu = new Subject<MenuItem[]>();
  private $leftMenu = new BehaviorSubject<IMenuItem[]>([
    {
      label: 'Main',
      expanded: true,
      items: [{ label: 'Home Page', icon: 'pi ihg153', routerLink: ['/home'], hideMain: true }],
    },
  ]);
  private $rightMenu = new BehaviorSubject<MenuItem[]>([{ label: 'Log Out', icon: 'pi pi-fw ihg178', routerLink: ['/login'] }]);

  private $currentHotel = new BehaviorSubject<any>({
    id: 0,
    name: 'none',
  });

  private currentTZ: string;
  private currentOffset: number;
  private systemConfig: any = {};

  public get $currentHotelName(): Observable<string> {
    return this.$currentHotel.pipe(map((hotel) => hotel?.holidexCode + ' - ' + hotel?.name));
  }
  private setRegion$: Subscription;
  private currentRegion$: Subscription;

  constructor(
    private router: Router,
    private authService: AuthService,
    private centralData: CentralDataService,
    private regionData: RegionDataService,
    private local: LocalService,
    private linkService: LinkService,
    private versionService: VersionCheckService,
    private log: LoggingService,
    private sentryService: SentryService
  ) {
    let currentUser$: Subscription;
    this.versionService.getCurrentVersion().subscribe(({ version, buildDate }) => {
      if (currentUser$) {
        currentUser$.unsubscribe();
        currentUser$ = undefined;
      }
      currentUser$ = this.authService.currentUser().subscribe((data) => {
        const email = data?.email;
        this.$rightMenu.next([
          {
            label: 'Log Out',
            icon: 'pi pi-fw ihg178',
            command: () => {
              this.authService
                .isAuthorized()
                .pipe(
                  first(),
                  mergeMap((isAuthorized) =>
                    iif(
                      () => !isAuthorized,
                      of(false),
                      this.authService
                        .hasAnyRoles([
                          [Scope.REGION_ADMIN, Right.READ],
                          [Scope.USER, Right.READ],
                        ])
                        .pipe(
                          first(),
                          mergeMap((isAdmin) =>
                            this.authService.signOut().pipe(
                              first(),
                              map(() => isAdmin)
                            )
                          )
                        )
                    )
                  )
                )
                .subscribe((isAdmin) => {
                  this.local
                    .isReady()
                    .pipe(
                      filter((ready) => !!ready),
                      first()
                    )
                    .subscribe(() => {
                      const logOutRedirect = this.local.get('LORedirect', true, true);
                      if (logOutRedirect) {
                        if (isAdmin) {
                          window.open(environment.adminUrl, '_parent');
                        } else {
                          window.open(environment.toolsUrl, '_parent');
                        }
                      } else {
                        this.router.navigate(['/login/manual']);
                      }
                    });
                });

              this.authService
                .hasAnyRoles([
                  [Scope.REGION_ADMIN, Right.READ],
                  [Scope.USER, Right.READ],
                ])
                .pipe(
                  mergeMap((isAdmin) => {
                    return this.authService.signOut().pipe(map(() => isAdmin));
                  })
                );
              this.authService
                .signOut()
                .pipe(first())
                .subscribe(() => {
                  window.open(environment.toolsUrl);
                });
            },
          },
          { separator: true },
          { label: `Account: ${email}`, visible: !!email, disabled: true },
          { label: `Version: ${version.slice(0, 10)}`, disabled: true },
          {
            label: `Built: ${buildDate.slice(0, 16).replace('T', ' ')} UTC`,
            disabled: false,
            command: () => {
              const newLogLevel = this.log.logLevel + 1;
              if (newLogLevel <= 2) {
                this.log.logLevel = newLogLevel;
              } else {
                this.log.logLevel = -1;
              }
            },
          },
          { separator: true },
          { label: 'Release Notes', routerLink: ['/releasenotes'] },
        ]);
      });
    });
    this.versionService.initVersionCheck();
    this.$currentHotel.subscribe((hotel) => {
      const localOffset = new Date().getTimezoneOffset();
      const hotelTimezone = -dayjs().tz(hotel.timezone).utcOffset();
      this.currentOffset = (localOffset - hotelTimezone) / 60;
      this.currentTZ = hotel.timezone;
      this.authService.setCurrentHolidex(hotel?.holidexCode);
    });
  }

  public start() {
    this.authService
      .currentUser()
      .pipe(distinctUntilChanged((a, b) => a?.email === b?.email))
      .subscribe((u) => {
        //update menu and context as we get login
        if (u)
          //got a new login
          this.userChanged();
      });
    this.$currentRegion.pipe(distinctUntilChanged()).subscribe((reg) => {
      this.log.debug(`currentRegion ${reg}`);
      if (this.currentRegion$) {
        this.currentRegion$.unsubscribe();
        this.currentRegion$ = undefined;
      }
      this.currentRegion$ = this.authService
        .isAuthorized()
        .pipe(
          filter((loggedIn) => loggedIn),
          first()
        )
        .subscribe(() => {
          this.log.debug('authService logged in');
          this.regionChanged(reg);
        });
    });
  }

  public getMainMenu(): Observable<MenuItem[]> {
    return this.$leftMenu.asObservable();
  }

  public getRightMenu(): Observable<MenuItem[]> {
    return this.$rightMenu.asObservable();
  }

  public setCurrentRegion(regCode?: string) {
    this.sentryService.setRegion(regCode);
    if (regCode) {
      this.setCurrentHotel(undefined);
      this.$currentRegion.next('');
      this.$currentRegionId.next('');
      if (this.setRegion$) {
        this.setRegion$.unsubscribe();
        this.setRegion$ = undefined;
      }
      this.setRegion$ = this.authService
        .isAuthorized()
        .pipe(
          filter((loggedIn) => loggedIn),
          first()
        )
        .subscribe(() => {
          this.linkService
            .GQLCentral()
            .query<{ allRegions: { id }[] }>({
              query: gql`
                query getRegionId($regCode: String!) {
                  allRegions(abbreviation: $regCode) {
                    id
                  }
                }
              `,
              variables: { regCode },
            })
            .pipe(first())
            .subscribe((res) => {
              this.$currentRegionId.next(res?.data?.allRegions[0]?.id);
              this.$currentRegion.next(regCode); //tell everyone else we're changing
            });
        });
    } else {
      this.setCurrentHotel(undefined);
      this.$currentRegionId.next('');
      this.$currentRegion.next('');
    }
  }

  public getCurrentRegion() {
    return this.$currentRegion.value;
  }

  public getCurrentHotelId() {
    return this.$currentHotel.value?.id;
  }

  public getCurrentHotelWithDeptConfig$() {
    return this.$currentHotel.pipe(switchMap((value) => (value?.id ? this.regionData.getHotelWithDeptConfig(value.id) : of(undefined))));
  }

  public getCurrentBasicHotel$() {
    return this.$currentHotel.asObservable();
  }

  public getCurrentBasicHotel() {
    return this.$currentHotel?.value;
  }

  public currentHotelWeekStart(from: Date) {
    switch (this.$currentHotel?.value.firstWeekday) {
      case 'MONDAY':
        return this.jsDateToUTC(from).subtract(1, 'day').startOf('week').add(1, 'day').startOf('day');
      case 'SUNDAY':
        return this.jsDateToUTC(from).startOf('week').startOf('day');
    }
    return null; //only works for Monday and Sunday
  }

  public getCurrentRegionId() {
    return this.$currentRegionId.value;
  }

  public allowOptimals() {
    return this.$currentHotel.pipe(map((h) => h && h.schedulingOptimalAvailable));
  }

  private menuChanged() {
    this.$leftMenu.next([
      {
        label: 'Main',
        expanded: true,
        items: [{ label: 'Home Page', icon: 'pi ihg153', routerLink: ['/home'], hideMain: true }],
      },
    ]);
    this.authService
      .isAuthorized()
      .pipe(
        filter((loggedIn) => loggedIn),
        mergeMap(() =>
          forkJoin({
            hasAdmin: this.authService
              .hasAnyRoles([
                [Scope.REGION_ADMIN, '*'],
                [Scope.SCHEDULE_ADMIN, '*'],
              ])
              .pipe(first()),
            hasManning: this.authService.hasAnyRoles([[Scope.SCHEDULE_MANNING, '*']]).pipe(first()),
            hasActual: this.authService.hasAnyRoles([[Scope.SCHEDULE_ACTUAL, '*']]).pipe(first()),
            hasForecast: this.authService.hasAnyRoles([[Scope.SCHEDULE_FORECAST, '*']]).pipe(first()),
            hasSchedule: this.authService.hasAnyRoles([[Scope.SCHEDULE, '*']]).pipe(first()),
            hasCapture: this.authService.hasAnyRoles([[Scope.SCHEDULE_CAPTURE, '*']]).pipe(first()),
            hasWOT: this.authService.hasWOTRoles().pipe(first()),
            disabledFeatures: this.centralData.getConfig().pipe(
              first(),
              map(({ disabledFeatures }) => disabledFeatures)
            ),
          })
        ),
        first()
      )
      .subscribe(({ hasActual, hasAdmin, hasCapture, hasForecast, hasManning, hasSchedule, hasWOT, disabledFeatures }) => {
        this.$currentHotel.subscribe((hotel) => {
          const menu: IMenuItem = {
            label: 'Main',
            expanded: true,
            items: [{ label: 'Home Page', icon: 'pi ihg153', routerLink: ['/home'], hideMain: true }],
          };
          if (hotel) {
            if ((hasAdmin || hasActual || hasForecast) && hotel.schedulingOptimalAvailable) {
              menu.items.push({ label: 'Daily Activity Drivers', icon: 'pi ihg29', routerLink: ['/main/driverdaily'] });
            }
            if (hasAdmin || hasSchedule) {
              menu.items.push({ label: 'Scheduled Hours', icon: 'pi ihg4', routerLink: ['/main/scheduleshifts'] });
            }
            if (hasAdmin || hasCapture) {
              menu.items.push({ label: 'Actual Hours', icon: 'pi ihg27', routerLink: ['/main/captureshifts'] });
            }
            if (hasAdmin || hasManning) {
              menu.items.push({ label: 'Hotel Employees', icon: 'pi ihg11', routerLink: ['/main/manning'] });
            }
            if (hasAdmin && environment.useDeviceCheckIn) {
              menu.items.push({ label: 'Check In Devices', icon: 'pi ihg25', routerLink: ['/main/devices'] });
            }
            menu.items.push({
              label: 'Dashboards',
              icon: 'pi ihg28',
              expanded: false,
              items: [
                { label: 'Status Dashboard', icon: 'pi ihg28', routerLink: ['/main/statusdashboard'] },
                { label: 'Performance Dashboard', icon: 'pi ihg28', routerLink: ['/main/performancedashboard'] },
              ],
            });
            if (disabledFeatures.includes('TP_TA_UPLOAD')) {
              // if T&A upload is disabled then we only provide reports as an option
              menu.items.push({
                label: 'Reports',
                icon: 'pi ihg37',
                routerLink: ['/main/reportexports'],
                expanded: false,
              });
            } else {
              menu.items.push({
                label: 'Reports and Data',
                icon: 'pi ihg37',
                expanded: false,
                items: [
                  { label: 'Reports', icon: 'pi ihg37', routerLink: ['/main/reportexports'] },
                  { label: 'Import Actual Hours', icon: 'pi ihg37', routerLink: ['/main/actualsimport'] },
                ],
              });
            }
            if (hasAdmin || hasWOT)
              //if user has access to team planner
              menu.items.push({
                label: `Open ${environment.names.befFull}`,
                icon: 'pi ihg11',
                command: () => {
                  window.open(environment.wotUrl + '/login/manual', '_blank');
                },
              });
            menu.items.push({ label: 'Help And Support', icon: 'pi ihg30', routerLink: ['/main/help'] });
          }
          this.$leftMenu.next([menu]);
        });
      });
  }

  private userChanged() {
    this.menuChanged();
    this.$regionMenu.next(null);
    this.centralData
      .getRegions()
      .pipe(first())
      .subscribe(
        (r) => {
          const allowedRegions = r.filter((r) => this.authService.allowRegion(r.abbreviation));
          const regionMenu = allowedRegions
            .map((r) => ({ label: r.name, command: () => this.setCurrentRegion(r.abbreviation) }))
            .sort((a, b) => (a.label < b.label ? -1 : 1));
          const lastRegion = this.local.get('lastRegion') || this.getCurrentRegion();
          const defaultRegion = this.authService.getDefaultRegion();
          if (this.authService.allowRegion(lastRegion)) {
            this.setCurrentRegion(lastRegion);
          } else if (r.some((reg) => reg.abbreviation === defaultRegion)) {
            this.setCurrentRegion(defaultRegion);
          } else {
            // set it to the first available region, if any
            this.setCurrentRegion(allowedRegions && allowedRegions[0] && allowedRegions[0].abbreviation);
          }
          this.$regionMenu.next(regionMenu);
        },
        (error) => {
          this.sentryService.sendError(error, {}, 'context-region');
        }
      );
  }

  private regionChanged(regCode: string) {
    if (!regCode) return;
    this.setCurrentHotel(undefined);
    this.linkService.setCurrentRegion(regCode); //reconfigure the current region on the link manager
    this.local.set('lastRegion', regCode);
    //Load the latest Config
    this.centralData
      .getConfig()
      .pipe(first())
      .subscribe((C) => (this.systemConfig = C));
    //reconsider the hotel list
    this.$hotelMenu.next(null);
    this.regionData
      .getHotels()
      .pipe(first())
      .subscribe((hotels) => {
        const hotelMenu = hotels
          .map((h) => ({ label: h.holidexCode + ' - ' + h.name, command: () => this.setCurrentHotel(h) }))
          .sort((a, b) => (a.label < b.label ? -1 : 1));
        this.$hotelMenu.next(hotelMenu);
        const recentHolidexes = this.local.getRecentlyUsedList('holidexlist');
        let matchedHotel;
        for (const holidex of recentHolidexes) {
          matchedHotel = hotels.find(({ holidexCode }) => holidexCode === holidex);
          if (matchedHotel) {
            break;
          }
        }
        if (matchedHotel) {
          this.setCurrentHotel(matchedHotel);
        } else {
          this.setCurrentHotel(hotels.find(({ holidexCode }) => holidexCode === this.authService.getDefaultHotel()) || hotels[0]);
        }
      });
  }

  setCurrentHotelById(hotelId) {
    this.regionData
      .getHotel(hotelId)
      .pipe(first())
      .subscribe((hotel) => {
        if (hotel) {
          this.setCurrentHotel(hotel);
        }
      });
  }

  private setCurrentHotel(hotel) {
    this.linkService.setCurrentHotel(hotel && hotel.id);
    this.sentryService.setHolidex(hotel?.holidexCode);
    if (hotel && hotel.holidexCode) {
      this.local.addRecentlyUsedList('holidexlist', hotel.holidexCode, (d) => d === hotel.holidexCode);
    }
    this.log.debug(`setCurrentHotel ${hotel?.id}`, hotel);
    this.menuChanged();
    this.$currentHotel.next(hotel || { id: 0, name: 'none' });
  }

  public describeCurrentTZ() {
    return this.currentOffset
      ? `${this.currentTZ} (You are ${Math.abs(this.currentOffset)} hours ${this.currentOffset > 0 ? 'behind' : 'ahead of'} hotel)`
      : this.currentTZ;
  }

  public isoToContextDate(isoDate: string) {
    return this.dateToContextDate(dayjs.utc(isoDate));
  }

  public dateToContextDate(d: dayjs.Dayjs) {
    let offset = d.tz(this.currentTZ).utcOffset();
    return dayjs.utc(d).add(offset, 'm');
  }

  public contextDateToISO(dt: dayjs.Dayjs) {
    let offset = dt.tz(this.currentTZ).utcOffset();
    return dt.subtract(offset, 'm').toISOString();
  }

  public contextDateToDate(dt: dayjs.Dayjs) {
    let offset = dt.tz(this.currentTZ).utcOffset();
    return dt.subtract(offset, 'm');
  }

  public jsDateToUTC(d: Date) {
    //removes any time zone info and forces the javascript date to UTC
    let md = new Date(d).setMinutes(d.getMinutes() - d.getTimezoneOffset());
    return dayjs.utc(md);
  }

  public getSystemConfig() {
    return this.systemConfig;
  }
}
