<div class="p-d-flex  p-jc-center main">
  <p-panel *ngIf="showUrl">
    <ng-template pTemplate="header">
      <div class="p-d-flex p-jc-between p-ai-center ctl">
        <label >Select Language
        <p-dropdown [options]="languages" [(ngModel)]="language" optionLabel="name" optionValue="code" persistLocal="helplanguage" (ngModelChange)="langChange($event)"></p-dropdown>
        </label>
        <p-button *ngIf="showUrl" icon="pi pi-times" class="p-mx-1 p-button-sm" label="Back" (click)="closeHelp()"></p-button>
      </div>
    </ng-template>
    <embed *ngIf="showUrl" style="height: 80vh; width: 90vw" [src]="showUrl" />

  </p-panel>
  <app-menu-screen [menu]="helpMenu" *ngIf="!showUrl">
  </app-menu-screen>
</div>
