<div class="checkinline" [ngClass]="checkIn.shiftMatch" *ngIf="checkIn.length"
  [ngStyle]="{  'left.%': startPos,'width.%': lineLength}">
  &nbsp;
</div>
<div class="checkinfade" [ngClass]="checkIn.shiftMatch" *ngIf="showFade"
  [ngStyle]="{  'left.%': startPos,'width.%': 15}">
  &nbsp;
</div>
<div class="checkin" [ngStyle]="{ 'left.%': startPos}" [ngClass]="checkIn.checkInType+' '+checkIn.shiftMatch"
  [pTooltip]="toolTip()" tooltipPosition="top">
  &nbsp;
</div>