<div class="p-formgrid p-grid" style="width: 600px; margin-top: 1em">
  <div class="p-field p-col-10">
    <label
      >Email address
      <input
        id="email"
        type="email"
        size="50"
        pInputText
        [(ngModel)]="email"
        [disabled]="disabled"
        style="text-transform: lowercase"
        required
        email
        #emailCtrl="ngModel"
        (change)="emailChanged()"
      />
    </label>
    <p-message *ngIf="id && !accountDisabled" severity="info" text="Existing user"></p-message>
    <p-message
      *ngIf="isAdmin"
      severity="warn"
      [text]="'Not editable - access can only be changed on the ' + befName + ' Application'"
    ></p-message>
    <p-message *ngIf="accountDisabled" severity="warn" text="User disabled - save employee to enable login"></p-message>
    <p-message *ngIf="emailCtrl.errors?.required && (emailCtrl.dirty || emailCtrl.touched)" severity="error" text="Required"></p-message>
    <p-message
      *ngIf="emailCtrl.errors?.email && (emailCtrl.dirty || emailCtrl.touched)"
      severity="error"
      text="Invalid address"
    ></p-message>
  </div>
  <div class="p-field p-col-10">
    <label
      >Confirm email address
      <input
        (blur)="checkEmail()"
        id="confirmEmail"
        type="email"
        size="50"
        style="text-transform: lowercase"
        pInputText
        [(ngModel)]="confirmEmail"
        [disabled]="disabled"
        required
        email
        #confirmEmailCtrl="ngModel"
      />
    </label>
    <p-message
      *ngIf="confirmEmailCtrl.errors?.required && (confirmEmailCtrl.dirty || confirmEmailCtrl.touched)"
      severity="error"
      text="Required"
    ></p-message>
    <p-message *ngIf="emailUnmatched" severity="error" text="Invalid confirmation"></p-message>
  </div>
  <div class="p-field p-col-10" *ngFor="let holidexCode of holidexCodes">
    <ng-container *ngIf="departmentOptions && departmentOptions[holidexCode]">
      <label>
        {{ holidexCode }} <ng-container *ngIf="isAdmin">access to all departments</ng-container
        ><ng-container *ngIf="!isAdmin"
          >departments they can access
          <p-multiSelect
            [disabled]="loading || isAdmin || disableDepartments || disabled"
            id="departments"
            [options]="departmentOptions[holidexCode]"
            filter="true"
            optionLabel="label"
            [(ngModel)]="departments[holidexCode]"
            (onChange)="deptsChanged()"
            placeholder="Select departments"
            [style]="{ width: '15em' }"
            resetFilterOnHide="true"
            appendTo="body"
          >
          </p-multiSelect>
        </ng-container>
      </label>
    </ng-container>
  </div>
  <div class="p-field p-col-10" *ngIf="deptCount <= 0 && !isAdmin">
    <p-message severity="error" text="At least one department need to be selected"></p-message>
  </div>
  <div class="p-field p-col-10" *ngIf="deptCount > 99">
    <p-message severity="error" [text]="'Cannot select more than 99 departments, currently ' + deptCount + ' selected'"></p-message>
  </div>
  <div class="p-field p-col-8" *ngIf="!isAdmin">
    <label>
      Profile
      <p-dropdown
        [disabled]="loading || isAdmin || disabled"
        placeholder="Select a profile"
        showClear="true"
        [options]="profiles"
        [(ngModel)]="profile"
        optionLabel="label"
        appendTo="body"
        (ngModelChange)="profileChanged()"
      >
      </p-dropdown>
    </label>
  </div>
  <div class="p-field p-col-6">Scope</div>
  <div class="p-field p-col-2">Read</div>
  <div class="p-field p-col-2">Write</div>
  <div class="p-field p-col-2">Approve/Lock</div>
  <div class="p-field p-col-12">
    <hr />
  </div>
  <div class="p-field p-col-6">Employee Details</div>
  <div class="p-field p-col-2">
    <p-checkbox
      binary="true"
      [(ngModel)]="roles.manning.read"
      [disabled]="isAdmin || disabled || loading || roles.manning.write || !profile"
      (onChange)="rolesChanged()"
    ></p-checkbox>
  </div>
  <div class="p-field p-col-2">
    <p-checkbox
      [disabled]="isAdmin || disabled || loading || !profile"
      binary="true"
      [(ngModel)]="roles.manning.write"
      (onChange)="rolesChanged() && roles.manning.write && (roles.manning.read = true)"
    ></p-checkbox>
  </div>
  <div class="p-field p-col-6">Forecast Drivers</div>
  <div class="p-field p-col-2">
    <p-checkbox
      binary="true"
      [(ngModel)]="roles.forecast.read"
      [disabled]="isAdmin || disabled || loading || roles.forecast.write || !profile"
      (onChange)="rolesChanged()"
    ></p-checkbox>
  </div>
  <div class="p-field p-col-2">
    <p-checkbox
      [disabled]="isAdmin || disabled || loading || !profile"
      binary="true"
      [(ngModel)]="roles.forecast.write"
      (onChange)="rolesChanged() && roles.forecast.write && (roles.forecast.read = true)"
    ></p-checkbox>
  </div>
  <div class="p-field p-col-6">Actual Drivers</div>
  <div class="p-field p-col-2">
    <p-checkbox
      binary="true"
      [(ngModel)]="roles.actual.read"
      [disabled]="isAdmin || disabled || loading || roles.actual.write || !profile"
      (onChange)="rolesChanged()"
    ></p-checkbox>
  </div>
  <div class="p-field p-col-2">
    <p-checkbox
      [disabled]="isAdmin || disabled || loading || !profile"
      binary="true"
      [(ngModel)]="roles.actual.write"
      (onChange)="rolesChanged() && roles.actual.write && (roles.actual.read = true)"
    ></p-checkbox>
  </div>
  <div class="p-field p-col-6">Scheduled Hours</div>
  <div class="p-field p-col-2">
    <p-checkbox
      binary="true"
      [(ngModel)]="roles.shift.read"
      [disabled]="isAdmin || disabled || loading || roles.shift.write || !profile"
      (onChange)="rolesChanged()"
    ></p-checkbox>
  </div>
  <div class="p-field p-col-2">
    <p-checkbox
      [disabled]="isAdmin || disabled || loading || !profile"
      binary="true"
      [(ngModel)]="roles.shift.write"
      (onChange)="rolesChanged() && roles.shift.write && (roles.shift.read = true)"
    ></p-checkbox>
  </div>
  <div class="p-field p-col-2">
    <p-checkbox
      [disabled]="isAdmin || disabled || loading || !profile"
      binary="true"
      [(ngModel)]="roles.shift.approve"
      (onChange)="rolesChanged() && roles.shift.approve && (roles.shift.write = true && (roles.shift.read = true))"
    ></p-checkbox>
  </div>
  <div class="p-field p-col-6">Actual Hours</div>
  <div class="p-field p-col-2">
    <p-checkbox
      binary="true"
      [(ngModel)]="roles.captureShift.read"
      [disabled]="isAdmin || disabled || loading || roles.captureShift.write || roles.captureShift.approve || !profile"
      (onChange)="rolesChanged()"
    ></p-checkbox>
  </div>
  <div class="p-field p-col-2">
    <p-checkbox
      [disabled]="isAdmin || disabled || loading || roles.captureShift.approve || !profile"
      binary="true"
      [(ngModel)]="roles.captureShift.write"
      (onChange)="rolesChanged() && roles.captureShift.write && (roles.captureShift.read = true)"
    ></p-checkbox>
  </div>
  <div class="p-field p-col-2">
    <p-checkbox
      [disabled]="isAdmin || disabled || loading || !profile"
      binary="true"
      [(ngModel)]="roles.captureShift.approve"
      (onChange)="rolesChanged() && roles.captureShift.approve && (roles.captureShift.write = true && (roles.captureShift.read = true))"
    ></p-checkbox>
  </div>
  <div class="p-field p-col-12">
    <hr />
  </div>
  <div class="p-field p-col-6">User admin</div>
  <div class="p-field p-col-2">
    <p-checkbox
      binary="true"
      [(ngModel)]="roles.user.read"
      [disabled]="isAdmin || disabled || loading || roles.user.write || !profile"
      (onChange)="rolesChanged()"
    ></p-checkbox>
  </div>
  <div class="p-field p-col-2">
    <p-checkbox
      [disabled]="isAdmin || disabled || loading || !profile"
      binary="true"
      [(ngModel)]="roles.user.write"
      (onChange)="rolesChanged() && roles.user.write && (roles.user.read = true)"
    ></p-checkbox>
  </div>
</div>
