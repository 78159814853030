import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import dayjs from 'dayjs';
import { ContextService } from 'src/services/context.service';
import { LoggingService } from 'src/services/logging.service';
import { UIService } from 'src/services/ui.service';

@Component({
  selector: 'app-clone-shifts',
  styleUrls: ['./clone-shifts.component.scss'],
  templateUrl: './clone-shifts.component.html',
})
export class CloneShiftsComponent implements OnInit {
  @Input() cloneEv: any;
  @Output() onDone = new EventEmitter();
  @Output() onCancel = new EventEmitter();

  endDate: Date = null;

  useMon = true;
  useTue = true;
  useWed = true;
  useThurs = true;
  useFri = true;
  useSat = true;
  useSun = true;

  datesList = [];

  constructor(private log: LoggingService, private context: ContextService) {}

  ngOnInit() {}

  ngOnChanges() {
    if (!this.cloneEv) return; //no info
    this.log.debug('ngOnChanges', this.cloneEv);
    this.endDate = new Date(this.cloneEv.periodEnd);
    this.update();
  }

  clone() {
    this.onDone.emit(this.datesList.map((d) => d.toISOString()));
  }

  cancel() {
    this.onCancel.emit({});
  }

  update() {
    //make a list of dates
    const numDays = this.context.isoToContextDate(this.endDate.toISOString()).diff(this.cloneEv.startDate, 'days', false);

    this.log.debug(
      'update called here',
      numDays,
      this.cloneEv.startDate,
      dayjs(this.cloneEv.startDate),
      dayjs(this.cloneEv.startDate).toISOString()
    );

    if (numDays > 0) {
      this.datesList = new Array(numDays)
        .fill(0)
        .map((_, i) => dayjs(this.cloneEv.startDate).add(i + 1, 'days'))
        .filter((d) => {
          let dow = d.day();
          switch (dow) {
            case 0:
              return this.useSun;
            case 1:
              return this.useMon;
            case 2:
              return this.useTue;
            case 3:
              return this.useWed;
            case 4:
              return this.useThurs;
            case 5:
              return this.useFri;
            case 6:
              return this.useSat;
          }
          return true;
        });
    } else {
      this.datesList = [];
    }
  }
}
