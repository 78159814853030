import { Injectable } from '@angular/core';
import { configureScope, captureMessage, captureEvent, captureException, SeverityLevel, Event } from '@sentry/angular-ivy';
import { UIService } from './ui.service';
import { SentryErrorHandler } from './sentry-error-handler.service';
import clone from 'fast-clone';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SentryService {
  private email: string;
  private region: string;
  private holidex: string;
  private readonly baseTraceId = `${Date.now()}-${Math.floor(Math.random() * 1000)}`;
  private traceId = `${Date.now()}-${Math.floor(Math.random() * 1000)}`;
  private errorList = new Map<string, number>();

  constructor(private uiService: UIService) {
    if (environment.sentryDsn) {
      configureScope((scope) => {
        scope.setContext('trace-id', { baseTraceId: this.baseTraceId, traceId: this.traceId });
        scope.setTag('build.date', environment.buildDate);
        scope.setTag('build.hash', environment.hash);
        scope.setTag('build.version', environment.version);
        scope.setTag('build.release', environment.release);
      });
    }
  }

  getTraceId() {
    return this.traceId;
  }

  setUser(email) {
    this.email = email;
    this.traceId = `${Date.now()}-${Math.floor(Math.random() * 1000)}`;
    if (environment.sentryDsn) {
      configureScope((scope) => {
        scope.setUser({ email });
        scope.setContext('trace-id', { baseTraceId: this.baseTraceId, traceId: this.traceId });
      });
    }
  }

  setRegion(region) {
    this.region = region;
    if (environment.sentryDsn) {
      configureScope((scope) => {
        scope.setContext('context-data', { region: this.region, holidex: this.holidex });
      });
    }
  }

  setHolidex(holidex) {
    this.holidex = holidex;
    if (environment.sentryDsn) {
      configureScope((scope) => {
        scope.setContext('context-data', { region: this.region, holidex: this.holidex });
      });
    }
  }

  getUser() {
    return this.email;
  }

  sendMessage(message: string, severity: SeverityLevel, data?: any, eventType?: string) {
    if (environment.sentryDsn) {
      if (eventType) {
        this.addTag('event-type', eventType);
      }
      if (data) {
        this.addMetaData(data);
      }
      return captureMessage(message, severity);
    } else {
      console.log({ sentryMessage: { message, severity, email: this.email } });
      return 'NOT_LOGGED';
    }
  }

  sendEvent(event: Event, data?: any) {
    if (environment.sentryDsn) {
      if (data) {
        this.addMetaData(data);
      }
      return captureEvent(event);
    } else {
      console.log({ sentryEvent: event });
      return 'NOT_LOGGED';
    }
  }

  private addMetaData(data: any) {
    configureScope((scope) => scope.setContext('meta-data', clone(data)));
  }

  private addTag(tagName: string, val: string) {
    configureScope((scope) => scope.setTag(tagName, val));
  }

  sendError(error, data?: any, errorId?: string, force = false) {
    if (environment.sentryDsn) {
      if (!force) {
        const res = SentryErrorHandler.ignoreError(error);
        if (res) {
          return `IGNORED_${res}`;
        }
      }
      if (errorId && this.errorList.has(errorId)) {
        let occurrences = this.errorList.get(errorId) || 0;
        occurrences++;
        this.errorList.set(errorId, occurrences);
        if (occurrences > 100) {
          // ignore after 100
          return 'IGNORED_100';
        } else if (occurrences > 1) {
          // ignore 9 out of 10
          if (occurrences % 10 !== 0) {
            return 'IGNORED_0';
          }
        }
      }
      if (data) {
        this.addMetaData(data);
      }
      if (errorId) {
        this.addTag('errorId', errorId);
      }
      return captureException(error.originalError || error);
    } else {
      console.log({ sentryError: error });
      return 'NOT_LOGGED';
    }
  }

  showAndSendError(error, summary: string, description?: string, data?: any, errorId?: string) {
    const ignoreError = SentryErrorHandler.ignoreError(error);
    if (!ignoreError) {
      const eventId = this.sendError(
        error,
        {
          ...(data || {}),
          summary,
          description,
        },
        errorId
      );
      this.uiService.error(
        summary,
        `${description || ''} If this error persists please contact an administrator and provide them with the id "${eventId}".`
      );
    } else {
      this.uiService.error(
        summary,
        `${description || ''}. ERROR: ${
          error?.message || 'Unknown'
        }. If this error persists please contact an administrator. [${ignoreError}]`
      );
    }
  }
}
