import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { NgModel } from '@angular/forms';
import { first, map } from 'rxjs/operators';
import { deepClone, orderByOutlet } from 'src/commontypes/util';
import { ManningDataService } from 'src/services/manning-data.service';
import { IRoles, IAccount, IAccountDepartment } from 'src/services/auth.service';
import { forkJoin } from 'rxjs';
import { UIService } from 'src/services/ui.service';
import { environment } from 'src/environments/environment';

const defaultRoles = () => ({ actual: {}, captureShift: {}, forecast: {}, manning: {}, shift: {}, user: {} } as IRoles);

interface IProfile {
  label: string;
  roles: IRoles;
}
@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss'],
})
export class AccountComponent implements OnInit {
  befName = environment.names.bef;
  _hotel;
  deptCount = 0;
  accountDisabled = false;
  @Input() disabled = true;
  @Input() disableDepartments = true;
  @Input() set hotel(val) {
    this._hotel = val;
    this.holidexCodes = (this.cluster ? this.cluster.hotels : this._hotel ? [this._hotel] : []).map(({ holidexCode }) => holidexCode);
    this.selectDepts();
  }
  @Input() cluster?: { name; hotels: Array<{ id: string; label: string; holidexCode: string }> };
  holidexCodes = [];
  @Input() get account() {
    return {
      email: this.email?.trim().toLowerCase(),
      id: this.id,
      roles: this.roles,
      departments: Object.values(this.departments).reduce((prev, depts) => {
        prev.push(...depts);
        return prev;
      }, []),
      isAdminRead: this.isAdminRead,
      isAdminWrite: this.isAdminWrite,
      fullSchedulerHotels: [],
      policyName: `SCHEDULER${this.profile ? '.' + this.profile.label : ''}`,
    };
  }
  set account(value: IAccount) {
    if (value) {
      this.email = value.email?.trim().toLowerCase();
      this.id = value.id;
      this.confirmEmail = value.confirmEmail?.trim().toLowerCase();
      this.roles = deepClone(value.roles);
      const mappedDepts = value.departments.reduce((prev, dept) => {
        if (!prev[dept.holidexCode]) {
          prev[dept.holidexCode] = [];
        }
        prev[dept.holidexCode].push(dept);
        return prev;
      }, {});
      this.allDepartments = { ...mappedDepts };
      this.departments = { ...mappedDepts };
      this.isAdminRead = value.isAdminRead;
      this.isAdminWrite = value.isAdminWrite;
      this.checkEmail();
      if (value.policyName) {
        const [policy, profile] = value.policyName.split('.');
        if (profile && policy === 'SCHEDULER') {
          this.profile = this.profiles.find(({ label }) => label === profile);
          if (!this.profile && profile === 'Custom') {
            this.profiles.push({
              label: 'Custom',
              roles: {
                actual: {},
                captureShift: {},
                forecast: {},
                manning: {},
                shift: {},
                user: {},
              },
            });
            this.profile = this.profiles[this.profiles.length - 1];
          }
        } else if (policy === 'SCHEDULER') {
          this.profile = this.profiles.find((p) => p.label === 'Custom');
          if (!this.profile) {
            this.profiles.push({
              label: 'Custom',
              roles: {
                actual: {},
                captureShift: {},
                forecast: {},
                manning: {},
                shift: {},
                user: {},
              },
            });
            this.profile = this.profiles[this.profiles.length - 1];
          }
        }
      }
    } else {
      this.email = undefined;
      this.confirmEmail = undefined;
      this.id = undefined;
      this.roles = defaultRoles();
      this.allDepartments = {};
      this.departments = {};
      this.isAdminRead = false;
      this.isAdminWrite = false;
      this.emailUnmatched = false;
    }
    if (this.departmentOptions) {
      this.selectDepts();
    }
  }
  @Output() accountChange = new EventEmitter<IAccount>();
  @Output() isValid = new EventEmitter<boolean>();
  @ViewChild('emailCtrl') emailRef: NgModel;
  @ViewChild('confirmEmailCtrl') confirmEmailRef: NgModel;
  loading = false;
  roles: IRoles;
  email: string = '';
  confirmEmail: string = undefined;
  emailUnmatched = false;
  id: string;
  departments: { [holidex: string]: Omit<IAccountDepartment, 'defaultOrder'>[] } = {};
  /**
   * contains all the departments the user has access to, note that this may contain departments
   * from hotels which aren't in the current cluster/selected hotel and these values should not be overwritten
   */
  allDepartments: { [holidex: string]: Omit<IAccountDepartment, 'defaultOrder'>[] };
  get isAdminRead() {
    return this.adminRead;
  }
  set isAdminRead(value: boolean) {
    this.adminRead = value;
    this.isAdmin = this.adminRead || this.adminWrite;
    if (value) {
      Object.keys(this.roles).forEach((key) => {
        this.roles[key].read = true;
      });
      if (this.departmentOptions) {
        this.departments = this.departmentOptions;
      }
    }
  }
  get isAdminWrite() {
    return this.adminWrite;
  }
  set isAdminWrite(value: boolean) {
    this.adminWrite = value;
    this.isAdmin = this.adminRead || this.adminWrite;
    if (value) {
      Object.keys(this.roles).forEach((key) => {
        this.roles[key].write = true;
        this.roles[key].approve = true;
      });
      if (this.departmentOptions) {
        this.departments = this.departmentOptions;
      }
    }
  }
  adminRead = false;
  adminWrite = false;
  isAdmin = false;

  profile: IProfile;
  profiles: IProfile[] = [
    {
      label: 'HR',
      roles: {
        actual: { read: true },
        captureShift: { read: true, write: true, approve: true },
        forecast: { read: true },
        manning: { read: true, write: true },
        shift: { read: true, write: true },
        user: { read: true },
      },
    },
    {
      label: 'Finance',
      roles: {
        actual: { read: true, write: true },
        captureShift: { read: true, write: true },
        forecast: { read: true },
        manning: { read: true },
        shift: { read: true, write: true },
        user: {},
      },
    },
    {
      label: 'HoD',
      roles: {
        actual: { read: true },
        captureShift: { read: true, write: true },
        forecast: { read: true, write: true },
        manning: {},
        shift: { read: true, write: true },
        user: {},
      },
    },
    {
      label: 'Supervisor',
      roles: {
        actual: { read: true },
        captureShift: { read: true },
        forecast: { read: true },
        manning: {},
        shift: { read: true },
        user: {},
      },
    },
  ];
  constructor(private manningData: ManningDataService, private uiService: UIService) {}

  departmentOptions: { [holidex: string]: IAccountDepartment[] };

  selectDepts(holidexCode?) {
    const holidexCodes = holidexCode ? [holidexCode] : this.holidexCodes;
    if (this.departmentOptions) {
      const departments = holidexCodes.reduce((prev, holidex) => {
        prev[holidex] =
          (this.departmentOptions[holidex] || []).filter((depOpt) =>
            (this.departments[holidex] || []).some(
              (dep) =>
                dep.name === depOpt.name &&
                (depOpt.outletType ? dep.outletIndex === depOpt.outletIndex && dep.outletType === depOpt.outletType : !dep.outletType) &&
                (dep.subDeptName ? dep.subDeptName === depOpt.subDeptName : !depOpt.subDeptName)
            )
          ) || [];
        return prev;
      }, {});
      this.departments = departments;
      this.deptCount = Object.keys(this.departments).reduce((sum, key) => sum + (this.departments[key]?.length || 0), 0);
    }
  }

  ngOnInit() {
    this.holidexCodes = (this.cluster ? this.cluster.hotels : this._hotel ? [this._hotel] : []).map(({ holidexCode }) => holidexCode);
    this.loadDepartments();
  }

  loadDepartments() {
    const obs = this.cluster
      ? this.manningData.getRightsDepartmentsForCluster(this._hotel.id)
      : this.manningData.getRightsDepartmentsForHotel(this._hotel.id);
    obs.pipe(first()).subscribe((data) => {
      const deptOptions = data.reduce((prev, dept) => {
        const depts = prev[dept.holidexCode] || [];
        depts.push(dept);
        prev[dept.holidexCode] = depts;
        return prev;
      }, {});
      Object.keys(deptOptions).forEach((holidexCode) => {
        deptOptions[holidexCode].sort((a, b) =>
          a.defaultOrder < b.defaultOrder ? -1 : a.defaultOrder > b.defaultOrder ? 1 : orderByOutlet(a, b)
        );
      });
      this.departmentOptions = deptOptions;
      if (this.departments) {
        this.selectDepts();
      }
      this.checkValidity();
    });
  }

  checkEmail() {
    this.emailUnmatched =
      this.confirmEmail && this.email ? this.email.trim().toLowerCase() !== this.confirmEmail.trim().toLowerCase() : true;
    this.checkValidity();
  }

  rolesChanged() {
    const processChange = () => {
      if (!this.profiles.some(({ label }) => label === 'Custom')) {
        this.profiles.push({
          label: 'Custom',
          roles: {
            actual: {},
            captureShift: {},
            forecast: {},
            manning: {},
            shift: {},
            user: {},
          },
        });
      }
      this.profile = this.profiles[this.profiles.length - 1];
      return this.rightsChanged();
    };
    processChange.bind(this);
    if (this.roles?.user?.write) {
      this.uiService.confirmAction(
        `${this.email} will be allowed to create new profiles for users to login to the ${environment.names.schedulerFull} for this hotel. If you wish to allow a user to add employees rather give them the "Employee Details" right. Confirm that you wish to allow User Admin Write access.`,
        () => {
          return processChange();
        },
        () => {
          this.roles.user.write = false;
          return processChange();
        },
        'Confirm',
        'Cancel',
        'Allow User Admin'
      );
    } else {
      return processChange();
    }
  }

  deptsChanged() {
    this.deptCount = Object.keys(this.departments).reduce((sum, key) => sum + (this.departments[key]?.length || 0), 0);
    this.updateAccount();
    this.checkValidity();
    return true;
  }

  updateAccount() {
    const departments = Object.keys(this.departments).reduce((prev, holidex) => {
      if (this.holidexCodes.includes(holidex)) {
        prev.push(...(this.departments[holidex] || []));
      } else {
        prev.push(...(this.allDepartments[holidex] || []));
      }
      return prev;
    }, []);
    this.accountChange.emit({
      email: this.email?.trim().toLowerCase(),
      confirmEmail: this.confirmEmail?.trim().toLowerCase(),
      id: this.id,
      enabled: !this.accountDisabled,
      roles: this.roles,
      departments,
      isAdminRead: this.isAdminRead,
      isAdminWrite: this.isAdminWrite,
      policyName: `SCHEDULER${this.profile ? '.' + this.profile.label : ''}`,
      fullSchedulerHotels: [],
    });
  }

  rightsChanged() {
    this.deptCount = Object.keys(this.departments).reduce((sum, key) => sum + (this.departments[key]?.length || 0), 0);
    this.updateAccount();
    this.checkValidity();
    return true;
  }

  emailChanged() {
    if (this.emailRef.status === 'VALID') {
      this.loading = true;
      this.manningData
        .getAccountByEmail(this.email?.trim().toLowerCase())
        .pipe(first())
        .subscribe(
          (acc) => {
            this.confirmEmail = undefined;
            if (acc) {
              this.id = acc.id;
              this.roles = deepClone(acc.roles);
              this.accountDisabled = !acc.enabled;
              this.departments = deepClone(acc.departments).reduce((prev, dept) => {
                if (!prev[dept.holidexCode]) {
                  prev[dept.holidexCode] = [];
                }
                prev[dept.holidexCode].push(dept);
                return prev;
              }, {});
              this.isAdminRead = acc.isAdminRead;
              this.isAdminWrite = acc.isAdminWrite;
              this.isAdmin;
              this.selectDepts();
              this.rightsChanged();
            } else if (this.id) {
              this.id = undefined;
              this.accountDisabled = false;
              this.rightsChanged();
            }
            this.checkEmail();
            this.loading = false;
          },
          (err) => {
            this.loading = false;
            this.id = undefined;
            this.accountDisabled = false;
            this.rightsChanged();
            this.checkEmail();
          }
        );
    }
  }

  checkValidity() {
    this.isValid.emit(
      this.email?.trim().toLowerCase() === this.confirmEmail?.trim().toLowerCase() &&
        (this.isAdmin ||
          (!!this.emailRef &&
            this.emailRef.status !== 'INVALID' &&
            this.deptCount > 0 &&
            this.deptCount <= 99 &&
            Object.keys(this.roles).some((key) => this.roles[key].read || this.roles[key].write)))
    );
  }

  profileChanged() {
    if (!this.profile) {
      this.roles = defaultRoles();
      this.account.policyName = 'SCHEDULER';
    } else {
      this.account.policyName = `SCHEDULER.${this.profile.label}`;
      if (this.profile.label !== 'Custom') {
        this.roles = deepClone(this.profile.roles);
      }
    }
    this.rightsChanged();
  }
}
