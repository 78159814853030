<div class="container">
  <div class="innercontainer">
    <p-table
      [value]="rows"
      [loading]="isLoading"
      #dt
      styleClass="p-datatable-gridlines p-datatable-striped"
      [scrollable]="true"
      scrollHeight="flex"
    >
      <ng-template pTemplate="caption">
        <div class="p-d-flex p-jc-between p-ai-center">
          <label
            >Starting
            <p-button icon="pi pi-arrow-left" (click)="setCal(-7)" class="p-mx-1"></p-button>
            <p-calendar
              [(ngModel)]="weekStart"
              #calendar
              (ngModelChange)="dateChange()"
              dateFormat="yy-mm-dd"
              [monthNavigator]="true"
              [yearNavigator]="true"
              [yearRange]="yearRange"
              [showIcon]="true"
              appendTo="body"
              persistLocal="statusstartdate"
            >
              <ng-template pTemplate="footer">
                <div class="calendar-footer">
                  <button pButton pRipple type="button" label="Now" class="p-button-text" (click)="setToday(); calendar.toggle()"></button>
                </div>
              </ng-template>
            </p-calendar>
            <p-button icon="pi pi-arrow-right" (click)="setCal(+7)" class="p-mx-1"></p-button>
          </label>
          <label
            >Display
            <p-dropdown
              [options]="showTimes"
              [(ngModel)]="showTime"
              optionLabel="label"
              optionValue="code"
              appendTo="body"
              (ngModelChange)="dateChange()"
            >
            </p-dropdown>
          </label>
          <div class="p-d-flex p-ai-center">
            <label class="warning">
              Changes to schedule and optimal hours may be delayed up to 5 minutes; will refresh automatically
            </label>
            <p-button
              icon="pi pi-refresh"
              pTooltip="Force data refresh"
              [disabled]="isRefreshing || isLoading"
              (click)="refresh()"
              class="p-mx-1"
            ></p-button>
          </div>
          <help-overlay [helpfiles]="[{ file: 'pdashboard_instruction', description: 'Instructions' }]"></help-overlay>
        </div>
        <div *ngIf="updated || usedCache" style="padding-left: 1rem; margin-top: -14.5px">
          <span style="font-size: x-small; font-weight: lighter"
            >Retrieved:<ng-container *ngIf="updated"> {{ updated | date: 'YYYY-MM-dd HH:mm (z)' }}</ng-container
            ><ng-container *ngIf="usedCache"> from cache</ng-container></span
          >
        </div>
      </ng-template>
      <ng-template pTemplate="colgroup">
        <colgroup>
          <col style="width: 15rem" />
          <col style="width: 4rem" />
          <col style="width: 4rem" />
          <col style="width: 4rem" />
          <col style="width: 4rem" />
          <col style="width: 4rem" />
          <col style="width: 4rem" class="breakcol" />
          <col style="width: 4rem" />
          <col style="width: 4rem" />
        </colgroup>
      </ng-template>
      <ng-template pTemplate="header">
        <tr class="headrow">
          <th>Department</th>
          <th>Forecast Optimal Hours</th>
          <th>Scheduled Hours</th>
          <th>Variance</th>
          <th>Actual Optimal Hours</th>
          <th>Worked Hours</th>
          <th>Variance</th>
          <th>Forecast vs Actual Optimal</th>
          <th>Scheduled vs Worked Hours</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-row let-rowIndex="rowIndex">
        <tr *ngIf="row.newDivision" class="divisionheader">
          <td td class="first">
            {{ getDivisionName(row.classIndex) }}
          </td>
          <td>{{ row.newDivision.forecastHours | number: '1.0-0' }}</td>
          <td>{{ row.newDivision.scheduledHours | number: '1.0-0' }}</td>
          <td
            [ngClass]="{
              red: row.newDivision.scheduledHours - row.newDivision.forecastHours > 0,
              green: row.newDivision.scheduledHours - row.newDivision.forecastHours < 0
            }"
          >
            {{ row.newDivision.scheduledHours - row.newDivision.forecastHours | number: '1.0-0' }}
          </td>
          <td>{{ row.newDivision.actualHours | number: '1.0-0' }}</td>
          <td>{{ row.newDivision.capturedHours | number: '1.0-0' }}</td>
          <td
            [ngClass]="{
              red: row.newDivision.capturedHours - row.newDivision.actualHours > 0,
              green: row.newDivision.capturedHours - row.newDivision.actualHours < 0
            }"
          >
            {{ row.newDivision.capturedHours - row.newDivision.actualHours | number: '1.0-0' }}
          </td>
          <td>{{ row.newDivision.actualHours - row.newDivision.forecastHours | number: '1.0-0' }}</td>
          <td>{{ row.newDivision.capturedHours - row.newDivision.scheduledHours | number: '1.0-0' }}</td>
        </tr>
        <tr>
          <td class="first">
            {{ row.department }}
          </td>
          <td>
            <a class="llink" pTooltip="view scheduled hours" (click)="openDetail(row.fulldepartment, false)">{{
              row.forecastHours | number: '1.0-0'
            }}</a>
          </td>
          <td>
            <a class="llink" pTooltip="view scheduled hours" (click)="openDetail(row.fulldepartment, false)">{{
              row.scheduledHours | number: '1.0-0'
            }}</a>
          </td>
          <td [ngClass]="{ red: row.scheduledHours - row.forecastHours > 0, green: row.scheduledHours - row.forecastHours < 0 }">
            <a class="llink" pTooltip="view scheduled hours" (click)="openDetail(row.fulldepartment, false)">{{
              row.scheduledHours - row.forecastHours | number: '1.0-0'
            }}</a>
          </td>
          <td>
            <a class="llink" pTooltip="view actual hours" (click)="openDetail(row.fulldepartment, true)">{{
              row.actualHours | number: '1.0-0'
            }}</a>
          </td>
          <td>
            <a class="llink" pTooltip="view actual hours" (click)="openDetail(row.fulldepartment, true)">{{
              row.capturedHours | number: '1.0-0'
            }}</a>
          </td>
          <td [ngClass]="{ red: row.capturedHours - row.actualHours > 0, green: row.capturedHours - row.actualHours < 0 }">
            <a class="llink" pTooltip="view actual hours" (click)="openDetail(row.fulldepartment, true)"
              >{{ row.capturedHours - row.actualHours | number: '1.0-0' }}
            </a>
          </td>
          <td>{{ row.actualHours - row.forecastHours | number: '1.0-0' }}</td>
          <td>{{ row.capturedHours - row.scheduledHours | number: '1.0-0' }}</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="footer">
        <tr class="divisionheader">
          <th>Total</th>
          <th>{{ total.forecastHours | number: '1.0-0' }}</th>
          <th>{{ total.scheduledHours | number: '1.0-0' }}</th>
          <th>{{ total.scheduledHours - total.forecastHours | number: '1.0-0' }}</th>
          <th>{{ total.actualHours | number: '1.0-0' }}</th>
          <th>{{ total.capturedHours | number: '1.0-0' }}</th>
          <th>{{ total.capturedHours - total.actualHours | number: '1.0-0' }}</th>
          <th>{{ total.actualHours - total.forecastHours | number: '1.0-0' }}</th>
          <th>{{ total.capturedHours - total.scheduledHours | number: '1.0-0' }}</th>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
