<p-panel header="Select Report Details">
  <div class="p-d-flex p-jc-left p-ai-center">
    <label
      >From
      <p-calendar
        [(ngModel)]="dateStart"
        dateFormat="yy-mm-dd"
        [monthNavigator]="true"
        [yearNavigator]="true"
        yearRange="2019:2025"
        [showIcon]="true"
        appendTo="body"
      >
      </p-calendar>
    </label>
    <label
      >Time Period
      <p-dropdown
        [options]="numWeekList"
        [(ngModel)]="numWeeks"
        optionLabel="label"
        optionValue="code"
        appendTo="body"
        scrollHeight="400px"
      >
      </p-dropdown>
    </label>

    <p-button
      *ngIf="hasEmployeeAccess"
      icon="pi pi-download"
      class="p-mx-1"
      label="Export By Employee"
      (click)="exportEmployeeTotal()"
      [disabled]="isWorking"
    ></p-button>
    <p-button
      *ngIf="hasEmployeeAccess"
      icon="pi pi-download"
      class="p-mx-1"
      label="Export By Employee by Day"
      (click)="exportEmployee()"
      [disabled]="isWorking"
    ></p-button>
    <p-button
      *ngIf="hasEmployeeAccess"
      icon="pi pi-download"
      class="p-mx-1"
      label="Export Employee Roster"
      (click)="exportEmployeeRoster()"
      [disabled]="isWorking"
    ></p-button>
    <p-button
      *ngIf="hasDeptAccess"
      icon="pi pi-download"
      class="p-mx-1"
      label="Export By Department by Day"
      (click)="exportDepartment()"
      [disabled]="isWorking"
    ></p-button>
  </div>
  <p-progressBar *ngIf="isWorking" mode="indeterminate"></p-progressBar>
</p-panel>
