import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { ContextService } from 'src/services/context.service';
dayjs.extend(timezone);
dayjs.extend(utc);

/*
This component shows a large nice version of a menu component
Top level items will show as boxes, within them lie buttons
deeper levels will be exposed as pop ups
*/

@Component({
  selector: 'app-time-range',
  styleUrls: ['./time-range.component.scss'],
  templateUrl: './time-range.component.html',
})
export class TimeRangeComponent implements OnInit {
  _startDateString: string;
  _endDateString: string;

  @Input()
  get startDateString() {
    return this._startDateString;
  }
  set startDateString(date: string) {
    this._startDateString = date;
  }
  @Input()
  get endDateString() {
    return this._endDateString;
  }
  set endDateString(date: string) {
    this._endDateString = date;
  }
  @Output() startDateStringChange = new EventEmitter<string>();
  @Output() endDateStringChange = new EventEmitter<string>();

  @Input() usageContext = '';
  @Input() disabled = false;

  @Output() change = new EventEmitter<void>();

  constructor(private context:ContextService) {}

  public start: dayjs.Dayjs;
  public end: dayjs.Dayjs;

  public startH = 9;
  public startM = 0;
  public endH = 12;
  public endM = 0;
  public lenH = 4;
  public lenM = 0;
  public newDay = false;

  ngOnInit() {
    this.setupStart();
    this.setupEnd();
  }

  setupStart() {
    this.start = this.context.isoToContextDate(this._startDateString);
    this.startH = this.start.hour();
    this.startM = this.start.minute();
  }

  setupEnd() {
    this.end = this.context.isoToContextDate(this._endDateString);
    this.endH = this.end.hour();
    this.endM = this.end.minute();
    this.calcLength();
  }


  calcLength() {
    let l = this.endH * 60 + this.endM - (this.startH * 60 + this.startM);
    this.newDay = !(l > 0);
    if (l < 0) l += 24 * 60; //other way around the calendar
    this.lenH = Math.floor(l / 60);
    this.lenM = l % 60;
  }

  startChange() {
    //move up end time
    let e = this.startH * 60 + this.startM + this.lenH * 60 + this.lenM;
    this.endH = Math.floor(e / 60) % 24;
    this.endM = e % 60;
    this.calcLength();
    this.calcOutputs();

  }

  endChange() {
    this.calcLength();
    this.calcOutputs();
  }

  lenChange() {
    let e = this.startH * 60 + this.startM + this.lenH * 60 + this.lenM;
    this.endH = Math.floor(e / 60) % 24;
    this.endM = e % 60;
    this.calcLength();
    this.calcOutputs();
  }

  calcOutputs() {
    this.start = this.start.hour(this.startH).minute(this.startM);
    this.end = this.start.add(this.lenH, 'hour').add(this.lenM, 'minute');
    this.startDateStringChange.emit(this.context.contextDateToISO(this.start));
    this.endDateStringChange.emit(this.context.contextDateToISO(this.end));
  }
}
