<div class="container">
  <p-table
    [value]="data"
    [loading]="isLoading"
    #dt
    styleClass="p-datatable-gridlines p-datatable-striped"
    [scrollable]="true"
    scrollHeight="flex"
  >
    <ng-template pTemplate="caption">
      <div class="p-d-flex p-jc-between p-ai-center">
        <label
          >Starting
          <p-button icon="pi pi-arrow-left" (click)="setCal(-7)" class="p-mx-1"></p-button>
          <p-calendar
            [(ngModel)]="weekStart"
            #calendar
            (ngModelChange)="dateChange()"
            dateFormat="yy-mm-dd"
            [monthNavigator]="true"
            [yearNavigator]="true"
            [yearRange]="yearRange"
            [showIcon]="true"
            appendTo="body"
          >
            <ng-template pTemplate="footer">
              <div class="calendar-footer">
                <button pButton pRipple type="button" label="Now" class="p-button-text" (click)="setToday(); calendar.toggle()"></button>
              </div>
            </ng-template>
          </p-calendar>
          <p-button icon="pi pi-arrow-right" (click)="setCal(+7)" class="p-mx-1"></p-button>
        </label>
        <label
          >Display
          <p-dropdown
            [options]="showTimes"
            [(ngModel)]="showTime"
            optionLabel="label"
            optionValue="code"
            appendTo="body"
            (ngModelChange)="dateChange()"
            persistLocal="scheduleshowtime"
          >
          </p-dropdown>
        </label>
        <label>
          <span *ngIf="showColCodes.length > 1">Show</span>
          <span *ngIf="showColCodes.length === 1">{{ showColCodes[0].label }}</span>
          <p-dropdown
            *ngIf="showColCodes.length > 1"
            [options]="showColCodes"
            [(ngModel)]="showCols"
            optionLabel="label"
            optionValue="code"
            appendTo="body"
          >
          </p-dropdown>
        </label>
        <p-fileUpload
          #uploadarea
          mode="basic"
          auto="true"
          name="files"
          chooseLabel="Import from XLS"
          chooseIcon="pi pi-upload"
          [showUploadButton]="false"
          customUpload="true"
          (uploadHandler)="uploader($event)"
        ></p-fileUpload>
        <p-button icon="pi pi-download" (click)="export(false)" label="Export to XLS (week)"></p-button>
        <p-button icon="pi pi-download" (click)="export(true)" label="Export to XLS (month)"></p-button>
        <p-selectButton
          *ngIf="hasSuperAdmin"
          [options]="[
            { label: 'Lock', value: false },
            { label: 'Unlock', value: true }
          ]"
          [(ngModel)]="allowSuperWrite"
          pTooltip="Unlock to edit all values (Region Admin users only)"
        ></p-selectButton>
        <button
          *ngIf="hasSuperAdmin"
          type="button"
          pButton
          icon="pi pi-refresh"
          (click)="regenReports()"
          label=""
          [disabled]="isLoading"
          class="p-button-danger"
          pTooltip="Request optimals refresh"
        ></button>
        <i class="pi pi-spin pi-clock" *ngIf="isUpdating()"></i>
        <help-overlay
          [helpfiles]="[
            { file: 'dadriver_instruction', description: 'Instructions' },
            { file: 'dadriver_scheduling', description: 'Scheduling Process' }
          ]"
        ></help-overlay>
      </div>
    </ng-template>
    <ng-template pTemplate="colgroup" let-columns>
      <colgroup>
        <!-- <col [ngStyle]="{ width: '250px' }" class="department" /> -->
        <col style="width: 250px" class="driver" />
        <ng-container *ngFor="let i of displayRange">
          <col class="forecast" [ngStyle]="{ width: '5em' }" *ngIf="showCols != 1" />
          <col class="actual" [ngStyle]="{ width: '5em' }" *ngIf="showCols != 2" />
        </ng-container>
        <col class="week-break" [ngStyle]="{ width: '1em' }" />
        <col class="forecast week-total" [ngStyle]="{ width: '5em' }" *ngIf="showCols != 1" />
        <col class="actual week-total" [ngStyle]="{ width: '5em' }" *ngIf="showCols != 2" />
        <col class="total-break" [ngStyle]="{ width: '1em' }" />
        <col class="forecast total" style="width: 6em" *ngIf="showCols != 1" />
        <col class="actual total" style="width: 6em" *ngIf="showCols != 2" />
        <col class="wot total" style="width: 6em" />
      </colgroup>
    </ng-template>

    <ng-template pTemplate="header">
      <tr>
        <th rowspan="2" style="width: 250px" class="driver">Driver</th>
        <th *ngFor="let i of displayRange" [attr.colspan]="showCols == 3 ? 2 : 1" class="p-text-center" scope="col">
          {{ dateRange[i] | date: 'EEE dd MMM' }}
        </th>
        <th rowspan="2" class="week-break"></th>
        <th [attr.colspan]="showCols == 3 ? 2 : 1" class="p-text-center" scope="col">Total Week</th>
        <th rowspan="2" class="total-break"></th>
        <th [attr.colspan]="showCols == 3 ? 3 : 2" class="p-text-center" scope="col">
          Totals {{ activeMonthStart.toDate() | date: 'MMM YYYY' }}
        </th>
      </tr>
      <tr>
        <ng-container *ngFor="let i of displayRange">
          <th *ngIf="showCols != 2" class="p-text-center forecast" scope="col">Fcst</th>
          <th *ngIf="showCols != 1" class="p-text-center actual" scope="col">Act</th>
        </ng-container>
        <th *ngIf="showCols != 2" class="p-text-center forecast week-total" scope="col">Fcst</th>
        <th *ngIf="showCols != 1" class="p-text-center actual week-total" scope="col">Act</th>
        <th style="width: 6em" *ngIf="showCols != 2" class="p-text-center forecast total" scope="col">Fcst</th>
        <th style="width: 6em" *ngIf="showCols != 1" class="p-text-center actual total" scope="col">Act</th>
        <th style="width: 6em" class="p-text-center wot total" scope="col">{{ befName }}</th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-groupData>
      <tr>
        <th *ngIf="showCols === 3" class="group department" colspan="14">{{ groupData.sectionLabel }}</th>
        <th *ngIf="showCols !== 3" class="group department" colspan="7">{{ groupData.sectionLabel }}</th>
        <th class="week-break group department"></th>
        <th *ngIf="showCols === 3" colspan="3" class="group department"></th>
        <th *ngIf="showCols !== 3" colspan="2" class="group department"></th>
        <th class="total-break group department"></th>
        <th *ngIf="showCols === 3" class="group department" colspan="3"></th>
        <th *ngIf="showCols !== 3" class="group department" colspan="2"></th>
      </tr>
      <ng-container *ngFor="let rowData of groupData.lines">
        <tr [ngClass]="rowData.sectionClass || ''">
          <td scope="row" class="driver" style="width: 250px">{{ rowData.dataLabel }} {{ rowData.metricLabel }}</td>
          <ng-container *ngFor="let i of displayRange">
            <!-- <ng-container *ngIf="rowData.days[i]"> -->
            <td *ngIf="showCols === 1" class="p-text-center forecast" [ngClass]="{ readonly: !rowData.allowForecastWrite }">
              <ng-container *ngIf="allowSuperWrite || (rowData.allowForecastWrite && canEditDayFC[i])">
                <ng-container *ngIf="!rowData.days[i].closed; else closed">
                  <p-inputNumber
                    *ngIf="rowData.days[i]?.fcLoaded"
                    [size]="rowData.size || 7"
                    [max]="rowData.max || null"
                    [min]="rowData.min || null"
                    [disabled]="rowData.days[i].fcDirty"
                    [(ngModel)]="rowData.days[i].forecast"
                    (onBlur)="dayDirty(rowData, i, true, false)"
                    [inputStyle]="{ 'text-align': 'center', 'text-overflow': 'ellipsis' }"
                    placeHolder="0"
                    [pTooltip]="rowData.allowForecastWrite && canEditDayFC[i] ? undefined : 'Locked'"
                  ></p-inputNumber>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="!(allowSuperWrite || (rowData.allowForecastWrite && canEditDayFC[i])) && rowData.days[i]?.fcLoaded">
                <span *ngIf="!rowData.days[i].closed; else closed" class="readonly">{{
                  rowData.days[i].forecast === null ? 'N/A' : (rowData.days[i].forecast | number: '1.0-0')
                }}</span>
              </ng-container>
              <i class="pi pi-spin pi-spinner" *ngIf="!rowData.days[i]?.fcLoaded"></i>
            </td>
            <td *ngIf="showCols === 3" class="p-text-center forecast">
              <span>{{ rowData.days[i].forecast | number: '1.0-0' }}</span>
            </td>
            <td *ngIf="showCols != 1" [ngClass]="{ readonly: !rowData.allowActualWrite }" class="p-text-center actual">
              <ng-container *ngIf="allowSuperWrite || (rowData.allowActualWrite && canEditDayAct[i])">
                <ng-container *ngIf="!rowData.days[i].closed; else closed">
                  <p-inputNumber
                    *ngIf="rowData.days[i]?.aLoaded"
                    [size]="rowData.size || 7"
                    [max]="rowData.max || null"
                    [min]="rowData.min || null"
                    [disabled]="rowData.days[i].aDirty"
                    [(ngModel)]="rowData.days[i].actual"
                    (onBlur)="dayDirty(rowData, i, false, true)"
                    [inputStyle]="{ 'text-align': 'center', 'text-overflow': 'ellipsis' }"
                    [pTooltip]="rowData.allowForecastWrite && canEditDayAct[i] ? undefined : 'Locked'"
                  ></p-inputNumber>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="!(allowSuperWrite || (rowData.allowActualWrite && canEditDayAct[i])) && rowData.days[i]?.aLoaded">
                <span class="readonly">
                  <ng-container *ngIf="!rowData.days[i].closed; else closed">{{
                    rowData.days[i].actual === null ? 'N/A' : (rowData.days[i].actual | number: '1.0-0')
                  }}</ng-container>
                </span>
              </ng-container>
              <i class="pi pi-spin pi-spinner" *ngIf="!rowData.days[i]?.aLoaded"></i>
            </td>
          </ng-container>
          <!-- </ng-container> -->
          <td class="week-break"></td>
          <td
            *ngIf="showCols != 2"
            class="p-text-center forecast week-total"
            [ngClass]="{ readonly: !rowData.allowForecastWrite, incomplete: rowData.fWeekTotal === null }"
          >
            <span [pTooltip]="rowData.fWeekTotal === null ? 'Week data incomplete' : null">
              {{ rowData.fWeekTotal === null ? 'N/A' : (rowData.fWeekTotal | number: '1.0-0') }}
            </span>
          </td>
          <td
            *ngIf="showCols != 1"
            class="p-text-center actual week-total"
            [ngClass]="{ readonly: !rowData.allowActualWrite, incomplete: rowData.aWeekTotal === null }"
          >
            <span [pTooltip]="rowData.aWeekTotal === null ? 'Week data incomplete' : null">
              {{ rowData.aWeekTotal === null ? 'N/A' : (rowData.aWeekTotal | number: '1.0-0') }}
            </span>
          </td>
          <td class="total-break"></td>
          <td
            style="width: 6em"
            *ngIf="showCols != 2"
            class="p-text-center forecast total"
            [ngClass]="{ incomplete: rowData.fTotalPartial }"
          >
            <span [pTooltip]="rowData.fTotalPartial ? 'Month data incomplete' : null">
              {{ !rowData.fTotal ? 0 : (rowData.fTotal | number: '1.0-0') }}
            </span>
          </td>
          <td style="width: 6em" *ngIf="showCols != 1" class="p-text-center actual total" [ngClass]="{ incomplete: rowData.aTotalPartial }">
            <span [pTooltip]="rowData.aTotalPartial ? 'Month data incomplete' : null">
              {{ rowData.aTotal | number: '1.0-0' }}
            </span>
          </td>
          <td style="width: 6em" class="p-text-center wot total">
            {{ rowData.WOTTotalString || (rowData.WOTTotal | number: '1.0-0') }}
          </td>
        </tr>
      </ng-container>
    </ng-template>
  </p-table>
</div>
<ng-template #closed><span class="closed">closed</span></ng-template>
