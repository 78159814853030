import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { mergeMap, map, first, throttleTime } from 'rxjs/operators';
import { Subscription, BehaviorSubject, timer, of, forkJoin, from } from 'rxjs';
import { IRouteData } from '../app/app-routing.module';
import { MessageService } from 'primeng/api';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';
import { LoggingService } from './logging.service';

@Injectable({
  providedIn: 'root',
})
export class RouteGuardService {
  readonly CHECK_TOKEN_TIME_MS = 5 * 60 * 1000;
  timer$: Subscription;
  logoutTimer$: Subscription;
  $willLogout = new BehaviorSubject<boolean>(false);
  constructor(
    private router: Router,
    private messageService: MessageService,
    private authService: AuthService,
    private logService: LoggingService
  ) {
    if (environment.logout) {
      const checkInterval = environment.logout ? environment.logout.checkIntervalMs : 15000;
      this.timer$ = timer(0, checkInterval)
        .pipe(throttleTime(5000))
        .subscribe(async () => await this.checkActive());
    }
  }

  private async checkActive() {
    this.authService
      .isAuthorized()
      .pipe(first())
      .subscribe((loggedIn) => {
        if (loggedIn) {
          if (environment.logout && environment.logout.activeTimeoutMs > 0) {
            const now = Date.now();
            const lastActive = this.authService.getLastActive();
            if (this.authService.getPersistentLogin()) {
              if (now - lastActive > environment.logout.savedLogoutTimeoutMs) {
                this.logService.debug(
                  `persistent logout last active ${lastActive}, now ${now} [TIMEOUT=${environment.logout.savedLogoutTimeoutMs} vs ${
                    now - lastActive
                  }]`
                );
                this.router.navigate(['/login'], { queryParams: { autoLogout: true, returnUrl: this.router.routerState.snapshot.url } });
              }
            } else if (now - lastActive > environment.logout.activeTimeoutMs) {
              this.$willLogout.next(true);
              const ms = environment.logout.gracePeriodMs;
              this.logoutTimer$ = timer(ms)
                .pipe(first())
                .subscribe(() => {
                  this.logService.info(
                    `[${Date.now}] logging out user last active at ${this.authService.getLastActive()} ` +
                      `[TIMEOUT=${environment.logout.activeTimeoutMs} GRACE=${environment.logout.gracePeriodMs}]`
                  );
                  // on the timer expire reload the app and logout by going to the login route
                  window.location.href = `${environment.webUrl}/login?returnUrl=${encodeURIComponent(
                    this.router.routerState.snapshot.url
                  )}&autoLogout=true`;
                });
            } else {
              this.$willLogout.next(false);
            }
          } else {
            this.logService.debug(`run check active`);
          }
        }
      });
  }

  willLogout() {
    return this.$willLogout.asObservable();
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return forkJoin([this.authService.isAuthenticated().pipe(first()), this.authService.isAuthorized().pipe(first())]).pipe(
      mergeMap(([authenticated, authorized]) => {
        this.authService.markActive();
        if (!authenticated) {
          const queryParams: Record<string, any> = { returnUrl: state.url };
          this.router.navigate(['/login'], { queryParams });
          return of(false);
        }
        const data = next.data as IRouteData;
        if (!data.roles && !data.anyRoles) {
          return of(true);
        }
        if (!authorized) {
          return of(false);
        }
        return forkJoin([
          this.authService.hasAllRoles(data.roles).pipe(first()),
          this.authService.hasAnyRoles(data.anyRoles).pipe(first()),
        ]).pipe(
          first(),
          map(([allRights, anyRights]) => {
            if (allRights && anyRights) {
              return true;
            }
            this.messageService.add({
              key: 'top-center',
              severity: 'error',
              summary: 'No Access',
              detail: 'You do not have security rights to access ' + data.title,
            });
            this.router.navigate(['/home']);
            return false;
          })
        );
      })
    );
  }
}
