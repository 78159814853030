<div class=scroller>
  <label>Comments</label>
  <div *ngFor="let comment of comments" class="card">
    <p-button *ngIf="comment.editing" [disabled]="!comment.text" icon="pi pi-save" class="cbutton" (click)="saveComment(comment)"></p-button>
    <p-button *ngIf="(!comment.editing)&&comment.canEdit" icon="pi pi-pencil" class="cbutton" (click)="editComment(comment)"></p-button>
    <label>{{comment.createdBy}}</label>
    <label>at {{comment.createdAt|date:'h:mma dd MMM YYYY'}}</label>

    <div *ngIf="!comment.editing">{{comment.text}}</div>
    <textarea *ngIf="comment.editing" [rows]="5" maxlength="1024" pInputTextarea [(ngModel)]="comment.text"></textarea>
  </div>
</div>
