<ng-container *ngIf="cloneEv">
  <p-dialog [header]="'Clone Shifts for '+cloneEv.persons.length+' Employees'" [visible]="cloneEv" showEffect="fade" modal="true" [closable]="false">
    <div class="content">
        <h4>Copy all Shifts on occurring on {{cloneEv.startDate|date:'yyyy/MM/dd'}} for {{cloneEv.persons.length}} employee(s) onto
          <span *ngFor="let d of datesList">{{d|dayjs}},</span>
        </h4>
        <div class="p-field">
          <label class="p-mr-2">Clone until </label>
          <p-calendar [(ngModel)]="endDate" #calendar dateFormat="yy-mm-dd" [monthNavigator]="true" [showIcon]="true" appendTo="body"  (ngModelChange)="update()">
          </p-calendar>
        </div>
        <label class="p-m-2">For these days of the week:</label>
        <div class="p-d-flex p-flex-wrap p-jc-around">
          <p-checkbox class="p-mr-2" [(ngModel)]="useMon" [binary]="true" label="Monday" (ngModelChange)="update()"></p-checkbox>
          <p-checkbox class="p-mr-2" [(ngModel)]="useTue" [binary]="true" label="Tuesday" (ngModelChange)="update()"></p-checkbox>
          <p-checkbox class="p-mr-2" [(ngModel)]="useWed" [binary]="true" label="Wednesday" (ngModelChange)="update()"></p-checkbox>
          <p-checkbox class="p-mr-2" [(ngModel)]="useThurs" [binary]="true" label="Thursday" (ngModelChange)="update()"></p-checkbox>
          <p-checkbox class="p-mr-2" [(ngModel)]="useFri" [binary]="true" label="Friday" (ngModelChange)="update()"></p-checkbox>
          <p-checkbox class="p-mr-2" [(ngModel)]="useSat" [binary]="true" label="Saturday" (ngModelChange)="update()"></p-checkbox>
          <p-checkbox class="p-mr-2" [(ngModel)]="useSun" [binary]="true" label="Sunday" (ngModelChange)="update()"></p-checkbox>
        </div>
    </div>
    <p-footer>
      <button type="button" pButton icon="pi pi-times" (click)="cancel()" label="Cancel" class="p-button-secondary"></button>
      <button type="button" pButton icon="pi pi-check" (click)="clone()" label="Clone Shifts"></button>
    </p-footer>
  </p-dialog>
</ng-container>
