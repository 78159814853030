import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApolloError } from '@apollo/client/errors';
import { Subject, Subscription, timer, of } from 'rxjs';
import { catchError, distinctUntilChanged, filter, first, map, mergeMap, throttleTime } from 'rxjs/operators';
import gql from 'graphql-tag';

import { environment } from 'src/environments/environment';

import { ILinkContext, LinkService } from './link.service';
import { LoggingService } from './logging.service';
import { AuthService } from './auth.service';
import { UIService } from './ui.service';

@Injectable({
  providedIn: 'root',
})
export class CentralDataService {
  isProdOnlyDept: (departmentName: string) => boolean = () => false;

  constructor(
    private linkService: LinkService,
    private logService: LoggingService,
    private authService: AuthService,
    private uiService: UIService,
    private router: Router
  ) {
    this.logService.info('Start data service');
    const updateAuthorizationToken = () => {
      this.getAuthorizationToken()
        .pipe(first())
        .subscribe(
          (claimsToken) => {
            this.authService.setAuthorizationToken(claimsToken);
          },
          (error) => {
            this.authService
              .getAuthenticationToken()
              .pipe(first())
              .subscribe((authnToken) => {
                let email: string;
                try {
                  const payload = authnToken?.token?.split('.')[1];
                  const token = payload && JSON.parse(atob(payload));
                  email = token?.email;
                } catch (err) {
                  email = undefined;
                }
                this.authService.setAuthorizationToken(undefined);
                this.logService.info(error);
                if (error.name === 'ApolloError' && error.message === 'Invalid user') {
                  this.uiService.acknowledgeError(
                    `${
                      email ? email + ' does' : 'You do'
                    } not have any access to the WOT system. Please contact the WOT team for further information.`,
                    () => {
                      this.authService
                        .signOut()
                        .pipe(first())
                        .subscribe(() => {
                          this.router.navigate(['/login/manual'], { queryParams: { returnUrl: this.router.routerState.snapshot.url } });
                        });
                    },
                    'Invalid user'
                  );
                } else {
                  this.uiService.acknowledgeError(
                    `Unable to log ${email ? email : 'you'} in to the WOT system. Please contact the WOT team for further information`,
                    () => {
                      this.authService
                        .signOut()
                        .pipe(first())
                        .subscribe(() => {
                          const returnUrl = this.router.routerState.snapshot.url?.match(/(login|password)/i)
                            ? ''
                            : `?returnUrl=${this.router.routerState.snapshot.url}`;
                          window.location.href = environment.webUrl + `/login/manual${returnUrl}`;
                        });
                    },
                    'Unable to authorize'
                  );
                }
              });
          }
        );
    };
    this.authService
      .isAuthenticated()
      .pipe(distinctUntilChanged())
      .subscribe((authenticated) => {
        if (authenticated) {
          updateAuthorizationToken();
        } else {
          this.authService.setAuthorizationToken(undefined);
        }
      });
    this.authService
      .isAuthenticated()
      .pipe(
        filter((authed) => authed),
        mergeMap(() => this.authService.isAuthorizationExpiringSoon()),
        filter((expiry) => expiry > 0),
        throttleTime(1000 * 30)
      )
      .subscribe(() => {
        updateAuthorizationToken();
      });
    this.getDepartments().subscribe((res) => {
      const map = res.reduce((m, d) => {
        m.set(d.name, d.isProdOnly);
        return m;
      }, new Map<string, boolean>());
      this.isProdOnlyDept = (departmentName) => map.get(departmentName);
    });
  }

  private getAuthorizationToken() {
    const context: ILinkContext = { retryingOperation: false };
    return this.linkService
      .GQLCentral(false, true)
      .query<{ getToken: string }>({
        context,
        query: gql`
          query getAuthorizationToken {
            getToken
          }
        `,
        fetchPolicy: 'network-only',
      })
      .pipe(map((res) => res?.data?.getToken));
  }

  getHelpDocUrl(docPath: string) {
    return this.linkService
      .GQLCentral()
      .query<{ getHelpDocUrl: string }>({
        query: gql`
          query getHelpDocUrl($path: String!) {
            getHelpDocUrl(path: $path)
          }
        `,
        variables: {
          path: docPath,
        },
      })
      .pipe(map((res) => res.data.getHelpDocUrl));
  }

  getConfig() {
    return this.linkService
      .GQLCentral()
      .query<{ getConfig: { disabledFeatures?: string[]; SHIFT_APPRENTICE_PRODUCTIVITY: number } }>({
        query: gql`
          query getConfig {
            getConfig {
              disabledFeatures
              SHIFT_APPRENTICE_PRODUCTIVITY
            }
          }
        `,
      })
      .pipe(
        first(),
        map((res) => {
          return {
            disabledFeatures: res?.data?.getConfig.disabledFeatures || [],
            SHIFT_APPRENTICE_PRODUCTIVITY: res?.data?.getConfig.SHIFT_APPRENTICE_PRODUCTIVITY,
          };
        })
      );
  }

  getRegions() {
    return this.linkService
      .GQLCentral()
      .query<{ allRegions: any[] }>({
        query: gql`
          query getRegions {
            allRegions(schedulingAvailable: true) {
              ...${fragments.region.name}
            }
          }
          ${fragments.region.definition}
        `,
      })
      .pipe(
        first(),
        map((res) => {
          return (
            (res &&
              res.data &&
              res.data.allRegions &&
              (
                res.data.allRegions as {
                  id;
                  abbreviation;
                  name;
                  schedulingAvailable;
                }[]
              )
                .slice()
                .sort((a, b) => (a.abbreviation <= b.abbreviation ? -1 : 1))) ||
            []
          );
        })
      );
  }

  watchGetHealth(pollInterval = 5 * 60 * 1000) {
    let watchSub$: Subscription;
    let initialErrorPollDelay = 5000;
    const $data = new Subject<{ available: boolean; schedulerUnavailable?: boolean; schedulerMessage?: string; error?: boolean }>();
    const timedCheckForSubs$ = timer(50, 1000).subscribe(() => {
      if ($data.observers.length === 0 && watchSub$) {
        watchSub$.unsubscribe();
        if (!$data.closed) {
          $data.complete();
        }
        timedCheckForSubs$.unsubscribe();
      }
    });

    const watch = () => {
      if (watchSub$ && !watchSub$.closed) {
        watchSub$.unsubscribe();
      }
      watchSub$ = this.linkService
        .GQLCentral()
        .watchQuery<{ getHealth: { available: boolean; schedulerUnavailable?: boolean; schedulerMessage?: string } }>({
          query: gql`
            query getHealth {
              getHealth {
                available
                schedulerUnavailable
                schedulerMessage
              }
            }
          `,
          fetchPolicy: 'network-only',
          errorPolicy: 'ignore',
          pollInterval,
        })
        .valueChanges.pipe(
          filter((res) => !res.loading),
          map((res) => {
            if (res.error || res.errors?.length > 0) {
              return { available: false, err: true };
            }
            const data = res?.data?.getHealth || { available: false };
            return { ...data, err: undefined };
          }),
          catchError((err) => {
            if (err instanceof ApolloError) {
              if (err.networkError && err.networkError['status'] === 400) {
                return of({ available: true, err: undefined });
              }
            }
            return of({ available: false, err });
          })
        )
        .subscribe(
          ({ err, ...res }) => {
            if (err) {
              timer(initialErrorPollDelay, pollInterval / 2)
                .pipe(first())
                .subscribe(() => watch());
              initialErrorPollDelay *= 2;
              if (initialErrorPollDelay > pollInterval / 4) {
                initialErrorPollDelay = pollInterval / 4;
              }
            } else {
              initialErrorPollDelay = 5000;
            }
            $data.next({ ...res, error: !!err });
          },
          () => {
            $data.next({ available: false, error: true });
            timer(initialErrorPollDelay, pollInterval / 2)
              .pipe(first())
              .subscribe(() => watch());
            initialErrorPollDelay *= 2;
            if (initialErrorPollDelay > pollInterval / 4) {
              initialErrorPollDelay = pollInterval / 4;
            }
          }
        );
    };
    watch();

    return $data.asObservable();
  }

  watchIsAlive(pollInterval = 5 * 60 * 1000) {
    let initialErrorPollDelay = 15000;
    let watchSub$: Subscription;
    const $data = new Subject<boolean>();

    const timedCheckForSubs$ = timer(50, 1000).subscribe(() => {
      if ($data.observers.length === 0 && watchSub$) {
        watchSub$.unsubscribe();
        if (!$data.closed) {
          $data.complete();
        }
        timedCheckForSubs$.unsubscribe();
      }
    });

    const watch = () => {
      if (watchSub$ && !watchSub$.closed) {
        watchSub$.unsubscribe();
      }
      watchSub$ = this.linkService
        .GQLUnauth()
        .watchQuery<{ isAlive: boolean }>({
          query: gql`
            query isAliveTP {
              isAlive
            }
          `,
          errorPolicy: 'ignore',
          fetchPolicy: 'network-only',
          pollInterval,
        })
        .valueChanges.pipe(
          map((res) => {
            return res?.data?.isAlive || false;
          })
        )
        .subscribe(
          (res) => {
            initialErrorPollDelay = 5000;
            $data.next(res);
          },
          (err) => {
            $data.next(false);
            if (err?.networkError?.status === 429) {
              initialErrorPollDelay = 60 * 1000;
            }
            timer(initialErrorPollDelay, pollInterval / 2)
              .pipe(first())
              .subscribe(() => watch());
            initialErrorPollDelay *= 2;
            if (initialErrorPollDelay > pollInterval / 4) {
              initialErrorPollDelay = pollInterval / 4;
            }
          }
        );
    };
    watch();
    return $data.asObservable();
  }

  getDepartments() {
    this.logService.debug('loading departments');
    return this.linkService
      .GQLCentral()
      .query<{ getDepartments: Array<{ class; name; label; defaultOrder; isProdOnly }> }>({
        query: gql`
          query departments {
            getDepartments {
              name
              label
              class
              defaultOrder
              isProdOnly
            }
          }
        `,
      })
      .pipe(map(({ data }) => data.getDepartments));
  }
}

const fragments = {
  region: {
    name: 'RegionFragment',
    definition: gql`
      fragment RegionFragment on Region {
        id
        abbreviation
        name
        schedulingAvailable
      }
    `,
  },
};
