import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { getAbsenceTypeLookUp, getShiftTypeLookUp } from 'src/commontypes/shifts';
import { ContextService } from 'src/services/context.service';

import dayjs from 'dayjs';
import { IScheduleStatus } from '../shift-display/shift-display.component';
import { LoggingService } from 'src/services/logging.service';

@Component({
  selector: 'app-checkin-marker',
  styleUrls: ['./checkin-marker.component.scss'],
  templateUrl: './checkin-marker.component.html',
})
export class CheckInMarkerComponent implements OnInit {
  @Input() checkIn: any;
  @Input() timeStart = 0;
  @Input() cellStart = 0;
  @Input() timeEnd = 0;
  @Input() timeWidth = 1;
  @Input() blockMode = false;
  @Input() displayIndex;

  public startPos = 10;
  public lineLength = 0;
  public width = 100;
  public start: dayjs.Dayjs;
  public showFade = false;


  constructor(private context: ContextService, private log: LoggingService) { }

  ngOnInit() {

  }


  ngOnChanges(changes: SimpleChanges) {
    this.start = this.context.isoToContextDate(this.checkIn.time);

    let astart = +this.start;

    if (this.blockMode) {
      this.startPos = this.displayIndex * 10;
      this.lineLength = this.checkIn.length ? 10 : 0;
    } else {
      this.startPos = ((+astart - this.cellStart) * 100) / this.timeWidth;
      this.lineLength = this.checkIn.length * 24 * 60 * 60 * 1000 * 100 / this.timeWidth;
    }

    if ((!this.lineLength) && (this.checkIn.checkInType == 'IN')) {
      this.showFade = true;
    }

  }

  toolTip() {
    let dt = this.start.format("HH:mm:ss");
    let tt = (this.checkIn.checkInType == "IN") ? `Checked in at ${dt}.` : `Checked out at ${dt}.`;
    if (this.checkIn.length) tt += `Length ${(this.checkIn.length * 24).toFixed(1)} hours.`;
    if (this.showFade) tt += "Show fade";
    return tt;
  }

}
