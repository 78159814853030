import { Component, OnInit, Output, Input, EventEmitter, forwardRef, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';

/*
This component shows a large nice version of a menu component
Top level items will show as boxes, within them lie buttons
deeper levels will be exposed as pop ups
*/

@Component({
  selector: 'app-menu-screen',
  styleUrls: ['./menu-screen.component.scss'],
  templateUrl: './menu-screen.component.html',
})
export class MenuScreenComponent implements OnInit {

  _menu: MenuItem[];

  @Input()
  get menu() {
    return this._menu;
  }
  set menu(newMenu: MenuItem[]) {
       this._menu=newMenu;
  }

  constructor(private router:Router) {}


  ngOnInit() {
  }




}
