import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  Auth,
  isSignInWithEmailLink,
  sendPasswordResetEmail,
  sendSignInLinkToEmail,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  signInWithEmailLink,
  user,
} from '@angular/fire/auth';
import { MessageService } from 'primeng/api';
import {
  generateRandomCodeVerifier,
  calculatePKCECodeChallenge,
  validateAuthResponse,
  isOAuth2Error,
  authorizationCodeGrantRequest,
  expectNoState,
  processDiscoveryResponse,
  discoveryRequest,
  WWWAuthenticateChallenge,
  parseWwwAuthenticateChallenges,
  processAuthorizationCodeOpenIDResponse,
  processUserInfoResponse,
  generateRandomState,
  refreshTokenGrantRequest,
  processRefreshTokenResponse,
  userInfoRequest,
  AuthorizationServer,
  Client as OauthClient,
  skipSubjectCheck,
  UserInfoResponse,
} from 'oauth4webapi';
import { browserLocalPersistence, IdTokenResult, inMemoryPersistence, setPersistence } from 'firebase/auth';
import { BehaviorSubject, from, of, Subscription, timer, Observable } from 'rxjs';
import { catchError, distinctUntilChanged, filter, first, map, mergeMap, tap, throttleTime } from 'rxjs/operators';
import dayjs from 'dayjs';

import { DepartmentName, Right, Scope } from '../commontypes/util';
import { LocalService } from './local.service';
import { UIService } from './ui.service';
import { SentryService } from './sentry.service';
import { LoggingService } from './logging.service';
import { AuthError, AuthErrorType } from './auth-error';
import { environment } from './../environments/environment';

export { AuthError, AuthErrorType };
export type Role = [Scope, Right | '*'];

export interface IRights {
  read?: boolean;
  write?: boolean;
  approve?: boolean;
}

export interface IRoles {
  manning: IRights;
  forecast: IRights;
  actual: IRights;
  user: IRights;
  shift: IRights;
  captureShift: IRights;
}

export interface IAccountDepartment {
  name: string;
  label?: string;
  hotelId: string;
  holidexCode: string;
  outletIndex?: number;
  outletType?: 'BAR' | 'RESTAURANT' | 'RETAIL';
  defaultOrder: number;
  subDeptName?: 'KITCHEN' | 'STEWARDING';
}

export interface IAccount {
  email?: string;
  confirmEmail?: string;
  enabled?: boolean;
  id?: string;
  roles: IRoles;
  isAdminRead: boolean;
  isAdminWrite: boolean;
  departments: Omit<IAccountDepartment, 'defaultOrder'>[];
  fullSchedulerHotels: string[];
  policyName?: string;
}

const parseRoles = (roles: string[]) => ({
  actual: {
    read: roles.includes(`${Scope.SCHEDULE_ACTUAL}.${Right.READ}`),
    write: roles.includes(`${Scope.SCHEDULE_ACTUAL}.${Right.WRITE}`),
    approve: roles.includes(`${Scope.SCHEDULE_ACTUAL}.${Right.APPROVE}`),
  },
  captureShift: {
    read: roles.includes(`${Scope.SCHEDULE_CAPTURE}.${Right.READ}`),
    write: roles.includes(`${Scope.SCHEDULE_CAPTURE}.${Right.WRITE}`),
    approve: roles.includes(`${Scope.SCHEDULE_CAPTURE}.${Right.APPROVE}`),
  },
  forecast: {
    read: roles.includes(`${Scope.SCHEDULE_FORECAST}.${Right.READ}`),
    write: roles.includes(`${Scope.SCHEDULE_FORECAST}.${Right.WRITE}`),
    approve: roles.includes(`${Scope.SCHEDULE_FORECAST}.${Right.APPROVE}`),
  },
  manning: {
    read: roles.includes(`${Scope.SCHEDULE_MANNING}.${Right.READ}`),
    write: roles.includes(`${Scope.SCHEDULE_MANNING}.${Right.WRITE}`),
    approve: roles.includes(`${Scope.SCHEDULE_MANNING}.${Right.APPROVE}`),
  },
  shift: {
    read: roles.includes(`${Scope.SCHEDULE}.${Right.READ}`),
    write: roles.includes(`${Scope.SCHEDULE}.${Right.WRITE}`),
    approve: roles.includes(`${Scope.SCHEDULE}.${Right.APPROVE}`),
  },
  user: {
    read: roles.includes(`${Scope.SCHEDULE_USER}.${Right.READ}`),
    write: roles.includes(`${Scope.SCHEDULE_USER}.${Right.WRITE}`),
    approve: roles.includes(`${Scope.SCHEDULE_USER}.${Right.APPROVE}`),
  },
});

export const parseAccountData = ({
  email,
  roles,
  schedulerDepartments,
  schedulerHotelAccess,
  id,
  ...rest
}: {
  id;
  email;
  roles: string[];
  schedulerDepartments: Array<{ hotelId; holidexCode; departmentName; outletIndex; outletType; subDeptName }>;
  schedulerHotelAccess: Array<{
    allDepartments?: boolean;
    holidexCode;
    hotelId;
    regionAbbreviation;
    departments?: Array<{
      departmentName;
      outletIndex;
      outletType;
      subDeptName;
    }>;
  }>;
}): IAccount => {
  const result: IAccount = {
    ...rest,
    id,
    email,
    roles: parseRoles(roles),
    isAdminRead: roles.includes(`${Scope.REGION_ADMIN}.${Right.READ}`) || roles.includes(`${Scope.SCHEDULE_ADMIN}.${Right.READ}`),
    isAdminWrite: roles.includes(`${Scope.REGION_ADMIN}.${Right.WRITE}`) || roles.includes(`${Scope.SCHEDULE_ADMIN}.${Right.WRITE}`),
    departments: schedulerDepartments
      ? schedulerDepartments
          .filter(({ holidexCode }) => !schedulerHotelAccess.find((h) => h.allDepartments && h.holidexCode === holidexCode))
          .map(({ departmentName: name, ...rest }) => ({ ...rest, name }))
      : [],
    fullSchedulerHotels: schedulerHotelAccess.filter(({ allDepartments }) => allDepartments).map(({ holidexCode }) => holidexCode),
  };
  return result;
};

enum ShortDepartmentName {
  FRONT_OFFICE = 'FO',
  UNIFORMED_SERVICES = 'US',
  CLUB_LOUNGE = 'CL',
  HOUSEKEEPING = 'HK',
  LAUNDRY = 'LA',
  RESTAURANTS = 'OR',
  ROOM_SERVICE = 'RS',
  BARS = 'OB',
  MINI_BARS = 'MB',
  KITCHEN = 'KI',
  STEWARDING = 'ST',
  C_AND_E = 'CE',
  LEISURE_AND_SPA = 'LS',
  ENGINEERING = 'EN',
  SWITCHBOARD = 'SB',
  FINANCE = 'FI',
  IT = 'IT',
  EXECUTIVE_OFFICE = 'EO',
  PURCHASING = 'PU',
  RESERVATIONS = 'RE',
  SECURITY = 'SE',
  HUMAN_RESOURCES = 'HR',
  SALES_AND_MARKETING = 'SM',
  MICE_SALES = 'MS',
  F_AND_B = 'FB',
  EMPLOYEE_CAFETERIA = 'EC',
  EMPLOYEE_HOUSING = 'EH',
  OUTSIDE_CATERING = 'OC',
  RETAIL_OUTLETS = 'RO',
  MARINE = 'MA',
  OTHER = 'OT',
  UNKNOWN = 'ZZ',
}

const convertFromShortDepartmentName = (d: ShortDepartmentName) => {
  switch (d) {
    case ShortDepartmentName.BARS:
      return DepartmentName.BARS;
    case ShortDepartmentName.CLUB_LOUNGE:
      return DepartmentName.CLUB_LOUNGE;
    case ShortDepartmentName.C_AND_E:
      return DepartmentName.C_AND_E;
    case ShortDepartmentName.EMPLOYEE_CAFETERIA:
      return DepartmentName.EMPLOYEE_CAFETERIA;
    case ShortDepartmentName.EMPLOYEE_HOUSING:
      return DepartmentName.EMPLOYEE_HOUSING;
    case ShortDepartmentName.ENGINEERING:
      return DepartmentName.ENGINEERING;
    case ShortDepartmentName.EXECUTIVE_OFFICE:
      return DepartmentName.EXECUTIVE_OFFICE;
    case ShortDepartmentName.FINANCE:
      return DepartmentName.FINANCE;
    case ShortDepartmentName.FRONT_OFFICE:
      return DepartmentName.FRONT_OFFICE;
    case ShortDepartmentName.F_AND_B:
      return DepartmentName.F_AND_B;
    case ShortDepartmentName.HOUSEKEEPING:
      return DepartmentName.HOUSEKEEPING;
    case ShortDepartmentName.HUMAN_RESOURCES:
      return DepartmentName.HUMAN_RESOURCES;
    case ShortDepartmentName.IT:
      return DepartmentName.IT;
    case ShortDepartmentName.KITCHEN:
      return DepartmentName.KITCHEN;
    case ShortDepartmentName.LAUNDRY:
      return DepartmentName.LAUNDRY;
    case ShortDepartmentName.LEISURE_AND_SPA:
      return DepartmentName.LEISURE_AND_SPA;
    case ShortDepartmentName.MICE_SALES:
      return DepartmentName.MICE_SALES;
    case ShortDepartmentName.MINI_BARS:
      return DepartmentName.MINI_BARS;
    case ShortDepartmentName.OUTSIDE_CATERING:
      return DepartmentName.OUTSIDE_CATERING;
    case ShortDepartmentName.PURCHASING:
      return DepartmentName.PURCHASING;
    case ShortDepartmentName.RESERVATIONS:
      return DepartmentName.RESERVATIONS;
    case ShortDepartmentName.RESTAURANTS:
      return DepartmentName.RESTAURANTS;
    case ShortDepartmentName.RETAIL_OUTLETS:
      return DepartmentName.RETAIL_OUTLETS;
    case ShortDepartmentName.ROOM_SERVICE:
      return DepartmentName.ROOM_SERVICE;
    case ShortDepartmentName.SALES_AND_MARKETING:
      return DepartmentName.SALES_AND_MARKETING;
    case ShortDepartmentName.SECURITY:
      return DepartmentName.SECURITY;
    case ShortDepartmentName.STEWARDING:
      return DepartmentName.STEWARDING;
    case ShortDepartmentName.SWITCHBOARD:
      return DepartmentName.SWITCHBOARD;
    case ShortDepartmentName.UNIFORMED_SERVICES:
      return DepartmentName.UNIFORMED_SERVICES;
    case ShortDepartmentName.MARINE:
      return DepartmentName.MARINE;
    case ShortDepartmentName.OTHER:
      return DepartmentName.OTHER;
    case ShortDepartmentName.UNKNOWN:
      return DepartmentName.UNKNOWN;
    default:
      throw new Error('Unknown ShortDepartmentName value');
  }
};

interface ISchedulerClaims {
  d?: ShortDepartmentName[];
  b?: number[]; // bars
  r?: number[]; // restaurants
  t?: number[]; // retail outlets
  k?: ShortDepartmentName[];
  s?: ShortDepartmentName[];
  bK?: number[]; // bar kitchens
  rK?: number[]; // restaurant kitchens
  bS?: number[]; // bar stewarding
  rS?: number[]; // restaurant stewarding
}

/**
 * copy of the definition from the backend
 */
interface IUserClaims {
  regions: string[];
  roles: string[];
  defaultRegion?: string;
  holidex?: string;
  subRegion?: number;
  hotels?: { [region: string]: string[] };
  /** password expiry */
  expires?: number;
  sched?: { [holidex: string]: ISchedulerClaims | true };
  v?: string;
}

interface IDepartment {
  name: DepartmentName;
  subDeptName?: DepartmentName;
  outletIndex?: number;
  outletType?: 'BAR' | 'RESTAURANT';
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private currentHolidex?: string;
  private persistLogin;
  private expiryTimer$: Subscription;
  private $authenticating = new BehaviorSubject<boolean>(true);
  private $authorized = new BehaviorSubject<boolean>(false);
  private $userData = new BehaviorSubject<{ email?: string; emailVerified?: boolean }>(undefined);
  private $authenticationToken = new BehaviorSubject<{ token; source: 'ihg' | 'firebase' }>(undefined);
  private $authenticationExpiry = new BehaviorSubject<number>(undefined);
  private $refreshToken = new BehaviorSubject<{ token; source: 'ihg' | 'firebase' }>(undefined);
  private $authorizationToken = new BehaviorSubject<string>(undefined);
  private $authRawRoles = new BehaviorSubject<string[]>([]);
  private $authRegions = new BehaviorSubject<string[]>([]);
  private $authDefHolidex = new BehaviorSubject<string>(undefined);
  private $authDefRegion = new BehaviorSubject<string>(undefined);
  private $authSubRegion = new BehaviorSubject<number>(undefined);
  private $authHotels = new BehaviorSubject<{ [region: string]: string[] }>({});
  private $authDepartments = new BehaviorSubject<{
    [holidexCode: string]:
      | true
      | {
          depts: Set<string>;
          bars: { main: number[]; kitchens: number[]; stewarding: number[] };
          restaurants: { main: number[]; kitchens: number[]; stewarding: number[] };
          retailOutlets: { main: number[]; kitchens: number[]; stewarding: number[] };
        };
  }>({});
  private $authorizationExpired = new BehaviorSubject<'expired' | 'no-expiry' | 'sign-out' | false>(false);
  private $authorizationExpiry = new BehaviorSubject<number>(-1);
  private $authorizationExpiringSoon = new BehaviorSubject<number>(undefined);
  private $willLogout = new BehaviorSubject<boolean>(false);
  private logoutTimer$: Subscription;
  private authorizationServer: AuthorizationServer;
  private oauthClient: OauthClient;
  private redirectUri = environment.ssoConfig.redirect_uri;

  constructor(
    private afAuth: Auth,
    private router: Router,
    private messageService: MessageService,
    private local: LocalService,
    private uiService: UIService,
    private logService: LoggingService,
    private sentry: SentryService
  ) {
    this.local
      .isReady()
      .pipe(first())
      .subscribe(() => {
        this.persistLogin = this.getPersistentLogin();
        if (this.useSSO) {
          if (this.persistLogin) {
            setPersistence(this.afAuth, browserLocalPersistence).then();
          } else {
            setPersistence(this.afAuth, inMemoryPersistence).then();
          }
        }
        if (this.useSSO) {
          this.$authenticating.next(false);
        } else {
          user(this.afAuth)
            .pipe(
              mergeMap((user) => {
                if (user) {
                  return from(user.getIdTokenResult());
                } else {
                  this.$userData.next(undefined);
                  return of(undefined as IdTokenResult);
                }
              })
            )
            .subscribe((idResult) => {
              if (idResult) {
                this.$authenticationExpiry.next(Number(idResult.expirationTime) * 1000);
                this.$authenticationToken.next({ token: idResult.token, source: 'firebase' });
              } else {
                this.$authenticationToken.next(undefined);
                this.$authenticationExpiry.next(undefined);
              }
              this.$authenticating.next(false);
            });
        }
      });
    this.$userData.pipe(distinctUntilChanged((x, y) => x?.email === y?.email)).subscribe((res) => {
      this.sentry.setUser(res?.email || '');
      this.local.setUser(res?.email);
    });
    if (this.useSSO) {
      this.checkAuthServer().then(() => {
        this.logService.info('authorization server setup');
      });
      this.oauthClient = {
        client_id: environment.ssoConfig.client_id,
        client_secret: environment.ssoConfig.client_secret,
        token_endpoint_auth_method: 'client_secret_basic',
      };
    }

    this.$authorizationToken.subscribe((token) => {
      const [header, payload] = token?.split('.') || [];
      const claims = payload ? JSON.parse(atob(payload)) : undefined;
      this.publishClaims(claims);
    });

    if (environment.logout) {
      timer(0, environment.logout.checkIntervalMs || 15000).subscribe(() => this.checkActive());
    }

    this.logService.debug('authTiming setup', environment.authTiming);

    let expiryObs$: Subscription;
    const startExpiryCheck = () => {
      if (expiryObs$) {
        expiryObs$.unsubscribe();
        expiryObs$ = undefined;
      }
      expiryObs$ = timer(environment.authTiming.checkIntervalMs, environment.authTiming.checkIntervalMs)
        .pipe(
          mergeMap(() => this.$authenticationToken.asObservable()),
          filter((authenticated) => !!authenticated),
          mergeMap(() => this.$authorizationToken.asObservable()),
          filter((authorized) => !!authorized),
          mergeMap(() => this.$authorizationExpiry.asObservable()),
          throttleTime(2000),
          map((expiry) => expiry && expiry * 1000),
          tap((expiry) =>
            this.logService.debug(
              `expiryObs: expiry=${expiry || ''} delta=${expiry ? Math.round((expiry - Date.now()) / 1000) : ''} expiryWarning=${Math.round(
                environment.authTiming.expiryWarningMs / 1000
              )} refreshTimeout=${Math.round(environment.authTiming.refreshTimeoutMs / 1000)}`
            )
          ),
          tap((expiry) => {
            const now = Date.now();
            const soon = expiry > 0 ? expiry - now < environment.authTiming.expiryWarningMs : false;
            if (soon) {
              this.logService.info(`authorization expiring soon - ${Math.round((now - expiry) / 1000)}s`);
              this.$authorizationExpiringSoon.next(expiry);
            } else {
              this.$authorizationExpiringSoon.next(undefined);
            }
          }),
          filter((expiry) => {
            if (expiry > 0) {
              return expiry - Date.now() < environment.authTiming.refreshTimeoutMs;
            }
            return false;
          }),
          throttleTime(environment.authTiming.refreshThrottleMs),
          tap((expiry) => this.logService.debug(`expiryObs: refreshing [${expiry}]`))
        )
        .subscribe(
          (expiry) => {
            this.refreshLogin()
              .pipe(first())
              .subscribe((refreshed) => {
                this.logService.info(`${refreshed ? 'refreshed' : 'tried to refresh'} login`, { expiry, refreshed });
                if (!refreshed && expiry > Date.now()) {
                  this.messageService.add({
                    key: 'error-toast',
                    severity: 'warn',
                    summary: 'Logged out',
                    detail: 'Your login ended, please try to log in again.',
                    life: 10000,
                  });
                  from(this.signOut())
                    .pipe(first())
                    .subscribe(
                      () => {
                        this.logService.debug('logout no refresh');
                        const returnUrl = this.router.routerState.snapshot.url;
                        this.router.navigate(
                          ['/login/manual'],
                          !returnUrl?.match(/(login|password)/i) ? { queryParams: { returnUrl } } : undefined
                        );
                        startExpiryCheck();
                      },
                      () => {
                        startExpiryCheck();
                      }
                    );
                } else if (refreshed) {
                  startExpiryCheck();
                }
              });
          },
          () => {
            startExpiryCheck();
          }
        );
    };
    startExpiryCheck.bind(this);
    startExpiryCheck();

    this.$authorizationExpiry.pipe(distinctUntilChanged()).subscribe((expiry) => {
      if (this.expiryTimer$) {
        this.expiryTimer$.unsubscribe();
        this.expiryTimer$ = undefined;
      }
      if (expiry !== -1) {
        const now = dayjs().unix();
        if (!expiry) {
          this.$authorizationExpired.next('no-expiry');
        } else if (now >= expiry) {
          this.$authorizationExpired.next('expired');
        } else {
          this.$authorizationExpired.next(false);
          const dueMs = Math.min((expiry - now) * 1000, 1000 * 60 * 60);
          this.logService.info(`Password expires at ${dayjs(expiry * 1000)}`);
          this.expiryTimer$ = timer(dueMs, 1000 * 60 * 5)
            .pipe(throttleTime(2000))
            .subscribe(() => {
              this.$authorizationExpired.next(dayjs().unix() > expiry ? 'expired' : false);
            });
        }
      }
    });
  }

  private async checkAuthServer() {
    if (this.authorizationServer) {
      return Promise.resolve();
    }
    const issuer = new URL(environment.ssoConfig.issuer_uri);
    const oidcResponse = await discoveryRequest(issuer, { algorithm: 'oidc' });
    this.authorizationServer = await processDiscoveryResponse(issuer, oidcResponse);
    this.logService.info('oidc', this.authorizationServer);
  }

  private get useSSO() {
    return this.local.get('US', environment.useSSO, true);
  }

  useExternalAuth() {
    return this.useSSO;
  }

  setAuthorizationToken(token: string | undefined) {
    this.$authorizationToken.next(token);
  }

  getAuthorizationToken() {
    return this.$authorizationToken.asObservable();
  }

  getAuthenticationToken() {
    return this.$authenticationToken.asObservable();
  }

  public hasAuthorizationExpired() {
    return this.isAuthorized(false).pipe(
      filter((loggedIn) => loggedIn),
      mergeMap(() => this.$authorizationExpired.asObservable())
    );
  }

  public isAuthorizationExpiringSoon() {
    return this.$authorizationExpiringSoon.asObservable();
  }

  private refreshLogin() {
    const obs: Observable<{ token; refreshToken?; source: 'ihg' | 'firebase' }> = this.useSSO
      ? this.ssoRefreshToken(this.$refreshToken.value?.token)
      : this.firebaseRefreshToken();
    return obs.pipe(
      map((result) => {
        if (result) {
          this.$authenticationToken.next({ token: result.token, source: result.source });
          this.$refreshToken.next(result.refreshToken && { token: result.refreshToken, source: result.source });
          return true;
        }
        return false;
      }),
      catchError((error) => {
        this.sentry.sendMessage('error refreshing login', 'warning', { error, stack: error.stack });
        return of(false);
      })
    );
  }

  private firebaseRefreshToken(): Observable<{ token; refreshToken?; source: 'firebase' }> {
    return user(this.afAuth).pipe(
      mergeMap((user) => {
        if (user) {
          return from(
            user
              .getIdTokenResult(true)
              .then((token) => {
                this.$authenticationExpiry.next(Number(token.expirationTime) * 1000);
                return { token, source: 'firebase' };
              })
              .catch((error) => undefined as { token; refreshToken?; source: 'firebase' })
          );
        }
        return of(undefined);
      })
    );
  }

  private checkActive() {
    if (this.$authorized.value) {
      if (environment.logout && environment.logout.activeTimeoutMs > 0) {
        const lastActive = this.local.get('last-active') ?? Date.now();
        const now = Date.now();
        if (this.getPersistentLogin()) {
          if (now - lastActive > environment.logout.savedLogoutTimeoutMs) {
            this.logService.debug(
              `persistent logout ${lastActive}, now ${now} [TIMEOUT=${environment.logout.savedLogoutTimeoutMs} vs ${now - lastActive}]`
            );
            this.router.navigate(['/login'], { queryParams: { autoLogout: true, returnUrl: this.router.routerState.snapshot.url } });
          }
        } else if (now - lastActive > environment.logout.activeTimeoutMs) {
          this.$willLogout.next(true);
          this.uiService.showConfirmation({
            title: 'Confirm login',
            message: 'You have been inactive for a while. Please confirm that you want to stay logged in.',
            icon: 'pi-exclamation-triangle',
            options: [
              {
                text: 'Stay logged in',
                icon: 'pi-check',
                class: 'p-button-danger',
                command: () => {
                  this.markActive();
                  this.$willLogout.next(false);
                },
              },
              {
                text: 'Logout',
                icon: 'pi-times',
                class: 'p-button-secondary',
                command: () => {
                  this.logService.debug('inactive prompt logout');
                  this.signOut()
                    .pipe(first())
                    .subscribe(() => {
                      this.router.navigate(['/login'], {
                        queryParams: { autoLogout: true, returnUrl: this.router.routerState.snapshot.url },
                      });
                    });
                  this.uiService.hideConfirmation();
                },
              },
            ],
          });
          const ms = environment.logout.gracePeriodMs;
          this.logoutTimer$ = timer(ms).subscribe(() => {
            this.logService.info(
              `[${Date.now}] logging out user last active at ${lastActive} ` +
                `[TIMEOUT=${environment.logout.activeTimeoutMs} GRACE=${environment.logout.gracePeriodMs}]`
            );
            this.uiService.hideConfirmation();
            this.uiService.info('Logged out', 'You have been logged out due to inactivity');
            this.router.navigate(['/login'], { queryParams: { autoLogout: true, returnUrl: this.router.routerState.snapshot.url } });
          });
        } else {
          this.$willLogout.next(false);
        }
      } else {
        this.logService.debug(`run check active`);
      }
    }
  }

  getPersistentLogin() {
    return !!this.local.get('saveSignIn', false, true);
  }

  setPersistentLogin(save?: boolean) {
    this.persistLogin = save;
    this.local.set('saveSignIn', !!save, true);
  }

  willLogout() {
    return this.$willLogout.asObservable();
  }

  getLastActive() {
    return this.local.get('last-active', Date.now());
  }

  markActive() {
    if (this.logoutTimer$) {
      this.logoutTimer$.unsubscribe();
      this.logoutTimer$ = undefined;
    }
    this.local.set('last-active', Date.now());
  }

  requestPasswordlessLogin(email, route?) {
    if (this.useSSO) {
      throw new Error('only firebase supported');
    }
    return from(
      sendSignInLinkToEmail(this.afAuth, email, {
        url: `${environment.webUrl}${route ? route : ''}`,
        handleCodeInApp: true,
      })
    );
  }

  loginPasswordless(email) {
    if (this.useSSO) {
      throw new Error('only firebase supported');
    }
    isSignInWithEmailLink(this.afAuth, window.location.href);
    return from(
      signInWithEmailLink(this.afAuth, email)
        .then((cred) => {
          if (cred.user) {
            return cred.user.getIdTokenResult();
          }
          return Promise.resolve(undefined);
        })
        .then((result) => {
          return !!result;
        })
    );
  }

  async login(usernameOrURL: string | URL, password, persistLogin: boolean) {
    this.setPersistentLogin(persistLogin);
    try {
      if (this.useSSO) {
        if (typeof usernameOrURL !== 'string') {
          await this.ssoLogin(usernameOrURL, persistLogin);
        } else {
          throw new Error('Expected params');
        }
      } else {
        if (typeof usernameOrURL === 'string') {
          await this.firebaseLogin(usernameOrURL, password, persistLogin);
        } else {
          throw new Error('Expected username');
        }
      }
      return 'LoggedIn';
    } catch (err) {
      this.$authenticationExpiry.next(undefined);
      this.$authenticationToken.next(undefined);
      this.$refreshToken.next(undefined);
      this.$userData.next(undefined);
      throw err;
    }
  }

  private async ssoLogin(url: URL, persistLogin: boolean): Promise<boolean> {
    await this.checkAuthServer();
    const data = this.local.get('codeVerifier', undefined, true);
    if (!data) {
      throw new AuthError('No validation data available', AuthErrorType.MISSING_DATA);
    }
    this.logService.info('Validate response', { url, data });
    this.local.set('codeVerifier', undefined, true);
    const parameters = validateAuthResponse(this.authorizationServer, this.oauthClient, url, data.state || expectNoState);
    if (isOAuth2Error(parameters)) {
      this.logService.debug('Error in OAuth params', parameters);
      throw new AuthError('Error in parameters', AuthErrorType.INTERNAL_ERROR);
    }
    this.logService.info('Code grant request', parameters);
    const response = await authorizationCodeGrantRequest(
      this.authorizationServer,
      this.oauthClient,
      parameters,
      environment.ssoConfig.redirect_uri,
      data.codeVerifier
    );

    this.logService.info('Parse challenges', { ok: response.ok, status: response.status, statusText: response.statusText });
    let challenges: WWWAuthenticateChallenge[] | undefined;
    if ((challenges = parseWwwAuthenticateChallenges(response))) {
      for (const challenge of challenges) {
        this.logService.info('www authenticate challenge', challenge);
      }
    }

    const issuedAt = Date.now();
    try {
      const authResult = await processAuthorizationCodeOpenIDResponse(this.authorizationServer, this.oauthClient, response);
      if (!authResult || isOAuth2Error(authResult)) {
        this.logService.error('error', authResult);
        throw new AuthError('Error processing open ID response', AuthErrorType.INTERNAL_ERROR);
      }
      const expiresAt = issuedAt + authResult.expires_in * 1000;
      this.logService.info('authResult', { ...authResult, expiresAt });
      this.$authenticationExpiry.next(expiresAt);
      if (persistLogin) {
        this.local.set('openIdResponse', { ...authResult, expiresAt }, true);
      }
      await this.ssoProcessAuthenticationResult(authResult);
      this.local.set('autoLoginData', undefined, true);
      return true;
    } catch (error) {
      this.local.set('openIdResponse', undefined, true);
      if (error instanceof AuthError) {
        throw error;
      }
      if ((error?.message as string)?.startsWith('unexpected JWT "exp"')) {
        throw new AuthError('Error with JWT exp', AuthErrorType.INVALID_EXP, error);
      }
      this.logService.error('Error retrieving user info', { error, stack: error.stack });
      throw new AuthError('Error retrieving user info', AuthErrorType.INTERNAL_ERROR);
    }
  }

  private async ssoProcessAuthenticationResult(authResult: { access_token: string; refresh_token?: string }) {
    const userResult = await userInfoRequest(this.authorizationServer, this.oauthClient, authResult.access_token);
    const userInfo = await processUserInfoResponse(this.authorizationServer, this.oauthClient, skipSubjectCheck, userResult);

    this.logService.info('user info retrieved', userInfo);
    this.$authenticationToken.next({ token: authResult.access_token, source: 'ihg' });
    this.$refreshToken.next({ token: authResult.refresh_token, source: 'ihg' });
    this.$userData.next(userInfo && { email: userInfo.email, emailVerified: userInfo.email_verified });
  }

  oldSavedSignInExists() {
    if (this.getPersistentLogin()) {
      if (this.local.get('codeVerifier', undefined, true)) {
        return false;
      }
      const idResponse = this.local.get('openIdResponse', undefined, true);
      if (idResponse) {
        return idResponse.refresh_token?.length > 0 || Date.now() < (idResponse.expiresAt || 0);
      }
    }
    return false;
  }

  async ssoLoginRequest(persistLogin: boolean, firstRequest = false) {
    if (firstRequest) {
      this.local.set('autoLoginData', { count: 0, lastSSOReq: 0 }, true);
    } else {
      const { count, lastSSOReq } = this.local.get('autoLoginData', { count: 0, lastSSOReq: undefined }, true);
      let loginCount = count;
      if (Date.now() - (lastSSOReq || 0) < environment.login.errorClearTimeoutMs) {
        if (loginCount >= environment.login.maxLoginErrorCount) {
          this.local.set('autoLoginData', { count: 0, lastSSOReq: 0 }, true);
          throw new Error('Too many login retries');
        }
        loginCount++;
      } else {
        loginCount = 1;
      }
      this.local.set('autoLoginData', { count: loginCount, lastSSOReq: Date.now() }, true);
    }
    await this.checkAuthServer();
    if (persistLogin) {
      try {
        const idResponse = this.local.get('openIdResponse', undefined, true);
        if (idResponse) {
          if (idResponse?.refresh_token) {
            const { authResult } = await this.ssoRefreshToken(idResponse.refresh_token).toPromise();
            await this.ssoProcessAuthenticationResult(authResult);
            return;
          } else if (Date.now() < idResponse.expiresAt) {
            await this.ssoProcessAuthenticationResult(idResponse);
            return;
          }
        }
      } catch (error) {
        this.logService.debug('error using sso id response', error);
        this.sentry.sendMessage('error using sso id response', 'warning', error);
      }
    }
    const codeVerifier = generateRandomCodeVerifier();
    const codeChallenge = await calculatePKCECodeChallenge(codeVerifier);
    const state = generateRandomState();
    const codeChallengeMethod = 'S256';
    this.logService.info(`code codeChallenge=${codeChallenge} codeVerifier=${codeVerifier} state=${state}`);
    this.local.set('codeVerifier', { codeChallenge, codeChallengeMethod, codeVerifier, state }, true);
    const authorizationUrl = new URL(this.authorizationServer.authorization_endpoint!);
    authorizationUrl.searchParams.set('client_id', this.oauthClient.client_id);
    authorizationUrl.searchParams.set('code_challenge', codeChallenge);
    authorizationUrl.searchParams.set('code_challenge_method', codeChallengeMethod);
    authorizationUrl.searchParams.set('redirect_uri', this.redirectUri);
    authorizationUrl.searchParams.set('response_type', 'code');
    authorizationUrl.searchParams.set('state', state);
    authorizationUrl.searchParams.set('scope', 'openid');
    this.logService.info(`redirect ${authorizationUrl.href}`);
    window.location.href = authorizationUrl.href;
  }

  private ssoRefreshToken(refreshToken?: string): Observable<{ token; refreshToken; source: 'ihg'; authResult }> {
    if (!refreshToken) {
      this.logService.debug('no sso refresh token');
      return of(undefined);
    }
    const issuedAt = Date.now();
    return from(refreshTokenGrantRequest(this.authorizationServer, this.oauthClient, refreshToken)).pipe(
      mergeMap((response) => {
        this.logService.info('Parse challenges', response);
        let challenges: WWWAuthenticateChallenge[] | undefined;
        if ((challenges = parseWwwAuthenticateChallenges(response))) {
          for (const challenge of challenges) {
            this.logService.debug('challenge', challenge);
          }
        }
        return from(processRefreshTokenResponse(this.authorizationServer, this.oauthClient, response));
      }),
      map((authResult) => {
        if (isOAuth2Error(authResult)) {
          this.sentry.sendMessage('Error processing refresh token', 'warning', authResult);
          throw new AuthError('Unable to process refresh token', AuthErrorType.INTERNAL_ERROR);
        }
        const expiresAt = issuedAt + authResult.expires_in * 1000;
        this.$authenticationExpiry.next(expiresAt);
        if (this.persistLogin) {
          this.local.set('openIdResponse', { ...authResult, expiresAt }, true);
        }
        return { authResult, token: authResult.access_token, refreshToken: authResult.refresh_token, source: 'ihg' };
      })
    );
  }

  private async firebaseLogin(username, password, persistLogin): Promise<boolean> {
    if (persistLogin) {
      await setPersistence(this.afAuth, browserLocalPersistence);
    } else {
      await setPersistence(this.afAuth, inMemoryPersistence);
    }
    try {
      const cred = await signInWithEmailAndPassword(this.afAuth, username, password);
      return !!cred?.user;
    } catch (error) {
      if (error?.code === 'auth/wrong-password') {
        throw new AuthError('wrong password', AuthErrorType.WRONG_PASSWORD);
      }
      if (error?.code === 'auth/user-disabled') {
        throw new AuthError('user disabled', AuthErrorType.DISABLED);
      }
      if (error?.code === 'auth/too-many-requests') {
        throw new AuthError('too many logins', AuthErrorType.TOO_MANY_REQUESTS);
      }
      if (error?.code === 'auth/internal-error') {
        throw new AuthError('internal error', AuthErrorType.INTERNAL_ERROR);
      }
      throw new AuthError(error.message);
    }
  }

  loginWithToken(token) {
    if (this.useSSO) {
      throw new Error('login method not supported');
    }
    return from(
      signInWithCustomToken(this.afAuth, token).then(async (cred) => {
        if (cred.user) {
          this.$userData.next(cred.user);
        }
        try {
          const result = await cred.user.getIdTokenResult();
          return cred.user.email;
        } catch (error) {
          this.$userData.next(undefined);
          throw error;
        }
      })
    );
  }

  getUserData() {
    const { email, emailVerified } = this.$userData.value || {};
    return { email, emailVerified };
  }

  currentUser() {
    return this.$userData.pipe(map((val) => val && { email: val.email, emailVerified: val.emailVerified }));
  }

  private publishClaims(claims?: IUserClaims & { exp?: number; email: string; emailVerified?: boolean }) {
    this.logService.debug('publishClaims', claims);
    this.$authorized.next(!!claims);
    if (!claims) {
      this.$authorizationExpiringSoon.next(undefined);
      this.$userData.next(undefined);
    } else {
      this.$userData.next({ email: claims.email, emailVerified: claims.emailVerified });
    }
    this.$authSubRegion.next(claims?.subRegion ? Number(claims.subRegion) : undefined);
    this.$authHotels.next(claims?.hotels || {});
    this.$authDefHolidex.next(claims?.holidex);
    this.$authDefRegion.next(claims?.defaultRegion);
    this.$authRawRoles.next(claims?.roles || []);
    this.$authorizationExpiry.next(claims?.exp);
    this.$authRegions.next(claims?.regions || []);
    const authDepts: {
      [holdiexCode: string]:
        | true
        | {
            depts: Set<string>;
            bars: { main: number[]; kitchens: number[]; stewarding: number[] };
            restaurants: { main: number[]; kitchens: number[]; stewarding: number[] };
            retailOutlets: { main: number[]; kitchens: number[]; stewarding: number[] };
          };
    } = {};
    for (const holidex of Object.keys(claims?.sched || {})) {
      const hotelClaims = claims.sched[holidex];
      if (hotelClaims === true) {
        authDepts[holidex] = true;
      } else {
        const depts: Set<string> = new Set(hotelClaims.d?.map((s) => convertFromShortDepartmentName(s)) || []);
        const barsMain = new Set<number>();
        const restsMain = new Set<number>();
        const retailMain = new Set<number>();
        const barsKitchen = new Set<number>();
        const restsKitchen = new Set<number>();
        const barsStewarding = new Set<number>();
        const restsStewarding = new Set<number>();
        if (hotelClaims) {
          if (hotelClaims.b) {
            hotelClaims.b.forEach((outletIndex) => {
              depts.add(`${DepartmentName.BARS}-bar-${outletIndex}`);
              barsMain.add(outletIndex);
            });
          }
          if (hotelClaims.r) {
            hotelClaims.r.forEach((outletIndex) => {
              depts.add(`${DepartmentName.RESTAURANTS}-restaurant-${outletIndex}`);
              restsMain.add(outletIndex);
            });
          }
          if (hotelClaims.t) {
            hotelClaims.r.forEach((outletIndex) => {
              depts.add(`${DepartmentName.RETAIL_OUTLETS}-retail-${outletIndex}`);
              retailMain.add(outletIndex);
            });
          }
          if (hotelClaims.rK) {
            hotelClaims.rK.forEach((outletIndex) => {
              depts.add(`${DepartmentName.RESTAURANTS}-${DepartmentName.KITCHEN}-${outletIndex}`);
              restsKitchen.add(outletIndex);
            });
          }
          if (hotelClaims.rS) {
            hotelClaims.rS.forEach((outletIndex) => {
              depts.add(`${DepartmentName.RESTAURANTS}-${DepartmentName.STEWARDING}-${outletIndex}`);
              restsStewarding.add(outletIndex);
            });
          }
          if (hotelClaims.bK) {
            hotelClaims.bK.forEach((outletIndex) => {
              depts.add(`${DepartmentName.BARS}-${DepartmentName.KITCHEN}-${outletIndex}`);
              barsKitchen.add(outletIndex);
            });
          }
          if (hotelClaims.bS) {
            hotelClaims.bS.forEach((outletIndex) => {
              depts.add(`${DepartmentName.BARS}-${DepartmentName.STEWARDING}-${outletIndex}`);
              barsStewarding.add(outletIndex);
            });
          }
          if (hotelClaims.k) {
            hotelClaims.k.forEach((dept) => {
              depts.add(`${convertFromShortDepartmentName(dept)}-${DepartmentName.KITCHEN}`);
            });
          }
          if (hotelClaims.s) {
            hotelClaims.s.forEach((dept) => {
              depts.add(`${convertFromShortDepartmentName(dept)}-${DepartmentName.STEWARDING}`);
            });
          }
        }
        authDepts[holidex] = {
          depts,
          bars: { main: Array.from(barsMain), kitchens: Array.from(barsKitchen), stewarding: Array.from(barsStewarding) },
          restaurants: { main: Array.from(restsMain), kitchens: Array.from(restsKitchen), stewarding: Array.from(restsStewarding) },
          retailOutlets: { main: Array.from(retailMain), kitchens: [], stewarding: [] },
        };
      }
    }
    this.$authDepartments.next(authDepts);
  }

  signOut() {
    this.local.set('openIdResponse', undefined, true);
    this.local.set('autoLoginData', undefined, true);
    this.local.set('codeVerifier', undefined, true);

    this.$authorizationExpired.next('sign-out');
    const signOutPromise = this.useSSO ? Promise.resolve() : this.afAuth.signOut();
    return from(
      signOutPromise.then(() => {
        this.publishClaims(undefined);
        this.$authenticationToken.next(undefined);
        this.$authorizationToken.next(undefined);
        this.$userData.next(undefined);
      })
    );
  }

  passwordReset(email: string) {
    if (this.useSSO) {
      throw new Error('only supported for firebase');
    }
    return sendPasswordResetEmail(this.afAuth, email, environment.webUrl ? { url: `${environment.webUrl}/login` } : undefined);
  }

  isAuthenticating() {
    return this.$authenticating.asObservable();
  }

  isAuthenticated(): Observable<boolean> {
    return this.$authenticationToken.pipe(map((token) => !!token));
  }

  /**
   * Check if the user is logged in and whether their authorization is expired,
   * authorization expiry will only be checked if environment.internalAuthorizationExpiryCheck is true
   * @param checkAuthorizationExpired
   */
  isAuthorized(checkAuthorizationExpired = environment.internalAuthorizationExpiryCheck) {
    if (checkAuthorizationExpired) {
      return this.$authorized.pipe(
        mergeMap((loggedIn) => (loggedIn ? this.$authorizationExpired.pipe(map((expired) => !expired)) : of(false)))
      );
    }
    return this.$authorized.asObservable();
  }

  allowRegion(regCode: string) {
    return this.$authRegions.value?.includes(regCode);
  }

  allowHotel(region, holidex) {
    let hotels = this.$authHotels.value[region];
    if (!hotels) return false;
    return hotels.includes(holidex);
  }

  getDefaultRegion() {
    return this.$authDefRegion.value;
  }

  getDefaultHotel() {
    return this.$authDefHolidex.value;
  }

  hasAnyRoles(roles: Role[]) {
    if (!roles) return of(true);
    return this.$authRawRoles.pipe(
      map((userRoles) => {
        return roles.reduce((res, [scope, right]) => {
          return (
            res ||
            (right === '*'
              ? userRoles.includes(`${scope}.${Right.READ}`) || userRoles.includes(`${scope}.${Right.WRITE}`)
              : userRoles.includes(`${scope}.${right}`))
          );
        }, false);
      })
    );
  }

  hasWOTRoles() {
    return this.$authRawRoles.pipe(
      map((userRoles) => {
        for (var role of userRoles) {
          if (!role.includes('sched') && role != 'hotel.read') return true;
        }
        return false;
      })
    );
  }

  hasAllRoles(roles: Role[]) {
    this.logService.debug('in has All Roles');
    if (!roles) return of(true);
    return this.$authRawRoles.pipe(
      map((userRoles) => {
        return roles.reduce((res, [scope, right]) => {
          return (
            res &&
            (right !== '*'
              ? userRoles.includes(`${scope}.${right}`)
              : userRoles.includes(`${scope}.${Right.READ}`) ||
                userRoles.includes(`${scope}.${Right.WRITE}`) ||
                userRoles.includes(`${scope}.${Right.APPROVE}`))
          );
        }, true);
      })
    );
  }

  private getDeptKey({ name, outletIndex, outletType, subDeptName }: IDepartment) {
    return outletType ? `${name}-${outletType}-${outletIndex}` : subDeptName ? `${name}-${subDeptName}` : name;
  }

  hasDepartment(dept: IDepartment, holidexCode?: string) {
    if (this.$authRawRoles.value.some((role) => role.startsWith(Scope.REGION_ADMIN) || role.startsWith(Scope.SCHEDULE_ADMIN))) {
      return of(true);
    }
    const key = this.getDeptKey(dept);
    return this.$authDepartments.pipe(
      map((depts) => depts[holidexCode || this.currentHolidex] || { depts: new Set<string>() }),
      map((hotelDepts) => hotelDepts === true || hotelDepts.depts.has(key))
    );
  }

  hasAnyDepartment(departments: IDepartment[], holidexCode?: string) {
    if (this.$authRawRoles.value.some((role) => role.startsWith(Scope.REGION_ADMIN) || role.startsWith(Scope.SCHEDULE_ADMIN))) {
      return of(true);
    }
    const keys = departments.map((dept) => this.getDeptKey(dept));
    return this.$authDepartments.pipe(
      map((depts) => depts[holidexCode || this.currentHolidex] || { depts: new Set<string>() }),
      map((hotelDepts) => hotelDepts === true || keys.some((key) => hotelDepts.depts.has(key)))
    );
  }

  setCurrentHolidex(holidexCode?: string) {
    this.currentHolidex = holidexCode;
  }

  getOutlets(
    outletType?: 'BAR' | 'RESTAURANT' | 'RETAIL',
    holidexCode?: string
  ): Observable<{ all?: boolean; main?: number[]; kitchens?: number[]; stewarding?: number[] }> {
    if (this.$authRawRoles.value.some((role) => role.startsWith(Scope.REGION_ADMIN) || role.startsWith(Scope.SCHEDULE_ADMIN))) {
      return of({
        main: [] as number[],
        kitchens: [] as number[],
        stewarding: [] as number[],
        all: true,
      });
    }
    return this.$authDepartments.pipe(
      map(
        (depts) =>
          depts[holidexCode || this.currentHolidex] || {
            bars: { main: [], kitchens: [], stewarding: [] },
            restaurants: { main: [], kitchens: [], stewarding: [] },
            retailOutlets: { main: [], kitchens: [], stewarding: [] },
          }
      ),
      map((hotelDepts) =>
        outletType === 'BAR'
          ? hotelDepts === true
            ? { all: true }
            : { ...hotelDepts.bars, all: false }
          : outletType === 'RETAIL'
          ? hotelDepts === true
            ? { all: true }
            : { ...hotelDepts.retailOutlets, all: false }
          : hotelDepts === true
          ? { all: true }
          : { ...hotelDepts.restaurants, all: false }
      )
    );
  }
}
