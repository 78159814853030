export const shiftTypeCodes = [
  {
    code: 'AGENCY',
    label: 'Agency',
    short: 'A',
    canAssign: false,
    canBeAbsent: true,
    hasBreaks: true,
  },
  {
    code: 'WORK',
    label: 'Working',
    short: 'W',
    canAssign: true,
    canBeAbsent: true,
    hasBreaks: true,
  },
  {
    code: 'SUPERVISE',
    label: 'Supervising',
    short: 'S',
    canAssign: false,
    canBeAbsent: true,
    hasBreaks: true,
  },
  {
    code: 'MANAGE',
    label: 'Managing',
    short: 'M',
    canAssign: false,
    canBeAbsent: true,
    hasBreaks: true,
  },
  {
    code: 'ABSENCE',
    label: 'Absence',
    short: 'ABSENT',
    canAssign: true,
    canBeAbsent: false,
  },
  {
    code: 'APPRENTICE',
    label: 'Apprentice',
    short: 'APPR',
    canAssign: true,
    canBeAbsent: true,
    hasBreaks: true,
  },
  {
    code: 'TRAINING',
    label: 'Training',
    short: 'TRAIN',
    canAssign: true,
    canBeAbsent: true,
    hasBreaks: true,
  },
  {
    code: 'OFF',
    label: 'Off',
    short: 'OFF',
    canAssign: true,
    canBeAbsent: false,
  },
  {
    code: 'ADMIN',
    label: 'Admin',
    short: 'ADM',
    canAssign: true,
    hasBreaks: true,
    prodOnly: true,
  },
  {
    code: 'HOLIDAY',
    label: 'Annual Leave',
    short: 'AL',
    canAssign: false,
    canBeAbsent: false,
  },
  {
    code: 'SICK',
    label: 'Sick Leave',
    short: 'LS',
    canAssign: false,
    canBeAbsent: false,
  },
  {
    code: 'UNPAID',
    label: 'Unpaid Leave',
    short: 'LU',
    canAssign: false,
    canBeAbsent: false,
  },
];

export const absenceTypeCodes = [
  {
    code: 'NO_SHOW',
    label: 'No Show',
    short: 'NS',
    timeSheetOnly: true,
  },
  {
    code: 'ANNUAL_LEAVE',
    label: 'Annual Leave',
    short: 'AL',
  },
  {
    code: 'PUBLIC_HOLIDAY',
    label: 'Public Holiday',
    short: 'PH',
  },
  {
    code: 'LEAVE_WITHOUT_PAY',
    label: 'Leave without Pay',
    short: 'LWP',
  },
  {
    code: 'UNPAID_LEAVE',
    label: 'Unpaid Leave',
    short: 'UL',
  },
  {
    code: 'SICK_LEAVE',
    label: 'Sick Leave',
    short: 'SL',
  },
  {
    code: 'PARENTAL_LEAVE',
    label: 'Maternity/Paternity Leave',
    short: 'MP',
  },
  {
    code: 'TIME_IN_LIEU',
    label: 'Time in Lieu',
    short: 'TL',
  },

  {
    code: 'OTHER',
    label: 'Other (enter comment)',
    short: 'O',
  },
];

export function getShiftTypeLookUp() {
  return shiftTypeCodes.reduce((a, v) => {
    a[v.code] = v;
    return a;
  }, {});
}

export function getAbsenceTypeLookUp() {
  return absenceTypeCodes.reduce((a, v) => {
    a[v.code] = v;
    return a;
  }, {});
}
