<div class="centerpanel">
  <p-panel *ngIf="!isAlive" header="Service status">
    <div class="p-grid fieldbox" *ngIf="message">
      <div class="p-col-12">
        <p-messages severity="warn">
          <ng-template pTemplate>
            <div>
              <span style="font-size: large">{{ message }}</span>
            </div>
          </ng-template>
        </p-messages>
      </div>
    </div>
  </p-panel>
  <p-panel *ngIf="!usePasswordLogin" header="Sign In">
    <p-progressBar *ngIf="saving" mode="indeterminate" [style]="{ height: '6px' }"></p-progressBar>
    <div class="p-grid fieldbox" *ngIf="needsExternalAuthentication">
      <div class="p-col-12" style="font-size: large">There is currently no authenticated user, please login.</div>
    </div>
    <div class="p-grid fieldbox" *ngIf="defaultUrl">
      <div class="p-col-12">
        <p-messages severity="warn">
          <ng-template pTemplate>
            <div>
              You are accessing the tool from the wrong URL - some functionality may be disabled. <br />Please access the tool from
              <a [href]="defaultUrl">{{ defaultUrl }}</a
              >.
            </div>
          </ng-template>
        </p-messages>
      </div>
    </div>
    <p-footer>
      <div style="display: flex; justify-content: flex-start">
        <div style="margin-right: 8px">
          <button
            pButton
            [disabled]="authenticating || !needsExternalAuthentication || !isAlive"
            type="button"
            label="Login"
            (click)="requestSSOLogin()"
            icon="pi pi-cloud-upload"
            style="margin-right: 16px"
          ></button>
        </div>
        <div style="padding: 6px">
          <p-inputSwitch
            [(ngModel)]="saveSignIn"
            [disabled]="authenticating"
            (onChange)="saveSignInChanged($event)"
            pTooltip="Stay logged in on all tabs for up to a week"
          ></p-inputSwitch>
          <div style="padding-top: 7px; min-width: 10em">
            {{ saveSignIn ? 'Keep me logged in' : "Don't save my login" }}
          </div>
        </div>
      </div>
    </p-footer>
  </p-panel>
  <p-panel *ngIf="usePasswordLogin" [header]="updatePassword ? 'Update password' : resetPassword ? 'Reset password' : 'Sign In'">
    <p-progressBar *ngIf="saving" mode="indeterminate" [style]="{ height: '6px' }"></p-progressBar>
    <div class="p-grid fieldbox" *ngIf="defaultUrl">
      <div class="p-col-12">
        <p-messages severity="warn">
          <ng-template pTemplate>
            <div>
              You are accessing the tool from the wrong URL - some functionality may be disabled. <br />Please access the tool from
              <a [href]="defaultUrl">{{ defaultUrl }}</a
              >.
            </div>
          </ng-template>
        </p-messages>
      </div>
    </div>
    <div class="p-grid fieldbox">
      <label class="p-col-2" for="username">Email Address</label>
      <div class="p-col-6">
        <input
          type="email"
          id="username"
          style="text-transform: lowercase"
          autocomplete="username"
          [disabled]="authenticating"
          pInputText
          [(ngModel)]="user"
        />
      </div>
    </div>
    <div class="p-grid fieldbox" *ngIf="!resetPassword">
      <label class="p-col-2" for="password">{{ updatePassword ? 'Old ' : '' }}Password</label>
      <div class="p-col-6">
        <input
          *ngIf="!passwordUpdated"
          type="password"
          id="password"
          autocomplete="current-password"
          pInputText
          [disabled]="authenticating"
          [(ngModel)]="password"
          (keyup.enter)="!updatePassword && login()"
        />
        <input
          *ngIf="passwordUpdated"
          type="password"
          id="password"
          autocomplete="new-password"
          pInputText
          [disabled]="authenticating"
          [(ngModel)]="password"
          (keyup.enter)="!updatePassword && login()"
        />
      </div>
    </div>
    <ng-container *ngIf="updatePassword && !resetPassword">
      <div class="p-grid fieldbox">
        <label class="p-col-2" for="new-password">New Password</label>
        <div class="p-col-6">
          <input
            type="password"
            id="new-password"
            [(ngModel)]="newPassword"
            autocomplete="new-password"
            [disabled]="authenticating"
            pInputText
            pTooltip="Password must be at least 8 characters long and contain special characters, numbers, uppercase and lowercase letters"
            tooltipPosition="right"
            tooltipEvent="focus"
          />
        </div>
      </div>
      <div class="p-col-6" *ngIf="(newPassword && newPassword === password) || !isValidPassword()">
        <p-message
          *ngIf="newPassword && newPassword === password"
          severity="error"
          text="new password is the same as old password"
        ></p-message>
        <p-message *ngIf="!isValidPassword()" severity="error" text="new password not strong enough"></p-message>
      </div>
      <div class="p-grid fieldbox" *ngIf="!resetPassword">
        <label class="p-col-2" for="confirm-password">Confirm New Password</label>
        <div class="p-col-6">
          <input type="password" id="confirm-password" [disabled]="authenticating" pInputText [(ngModel)]="confirmPassword" />
        </div>
      </div>
      <div class="p-col-6" *ngIf="newPassword !== confirmPassword">
        <p-message severity="error" text="new passwords do not match"></p-message>
      </div>
    </ng-container>
    <p-footer>
      <div style="display: flex; justify-content: flex-start">
        <div style="margin-right: 8px">
          <button
            pButton
            [disabled]="authenticating || saving || passwordResetRequested || (resetPassword ? user : !(password && user))"
            type="button"
            [label]="resetPassword ? 'Reset password' : updatePassword ? 'Update password' : 'Login'"
            (click)="resetPassword ? passwordReset() : updatePassword ? changePassword() : login()"
            icon="pi pi-cloud-upload"
            style="margin-right: 16px"
          ></button>
        </div>
        <div style="margin-right: 8px" *ngIf="updatePassword || resetPassword">
          <button
            pButton
            type="button"
            [disabled]="authenticating"
            [label]="passwordResetRequested ? 'Return to login' : 'Cancel'"
            (click)="updatePassword = false; resetPassword = false; passwordResetRequested = false"
            icon="pi pi-times"
            style="margin-right: 16px"
          ></button>
        </div>
        <ng-container *ngIf="!updatePassword && !resetPassword">
          <div style="padding: 6px">
            <p-inputSwitch
              [(ngModel)]="saveSignIn"
              [disabled]="authenticating"
              pTooltip="Stay logged in on all tabs for up to a week"
              (onChange)="saveSignInChanged($event)"
            ></p-inputSwitch>
          </div>
          <div style="padding-top: 7px">
            {{ saveSignIn ? 'Keep me logged in' : "Don't save my login" }}
          </div>
        </ng-container>
        <div style="margin-left: auto">
          <button
            style="float: right"
            [disabled]="authenticating || saving"
            (click)="forgotPassword()"
            pButton
            *ngIf="!resetPassword"
            class="p-button-text"
            label="I forgot my password and need to reset it"
            tooltipPosition="bottom"
          ></button>
        </div>
      </div>
    </p-footer>
  </p-panel>
</div>
