import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { first } from 'rxjs/operators';

import { CentralDataService } from 'src/services/central-data.service';
import { UIService } from 'src/services/ui.service';

/**
 * This component shows a large nice version of a menu component
 * Top level items will show as boxes, within them lie buttons
 * deeper levels will be exposed as pop ups
 */
@Component({
  selector: 'help-overlay',
  styleUrls: ['./help-overlay.component.scss'],
  templateUrl: './help-overlay.component.html',
})
export class HelpOverlayComponent implements OnInit {
  @Input() helpfiles: any = [];

  public selFile = null;
  public showUrl = null;

  constructor(private centralDataService: CentralDataService, private ui: UIService, private sanitizer: DomSanitizer) {}

  ngOnInit() {
    this.selFile = this.helpfiles[0];
    //this.showDoc();
  }

  showDoc() {
    const docPath = '/sched_docs/' + this.selFile.file + '.pdf';
    this.showUrl = null;
    this.centralDataService
      .getHelpDocUrl(docPath)
      .pipe(first())
      .subscribe(
        (url) => {
          this.showUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
        },
        (error) => {
          this.ui.error('Error loading document', 'The document you requested is not currently available.');
        }
      );
  }
}
