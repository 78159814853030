import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import dayjs from 'dayjs';
import { shiftTypeCodes, absenceTypeCodes } from 'src/commontypes/shifts';
import { CentralDataService } from 'src/services/central-data.service';
// import { allowsAdminShift } from 'src/commontypes/util';
import { SentryService } from 'src/services/sentry.service';

@Component({
  selector: 'app-scheduled-shift',
  styleUrls: ['./scheduled-shift.component.scss'],
  templateUrl: './scheduled-shift.component.html',
})
export class ScheduledShiftComponent implements OnInit, OnChanges {
  @Input() shift: any;
  @Output() onChange = new EventEmitter();
  @Output() onCancel = new EventEmitter();
  @Output() onRemove = new EventEmitter();
  private oldBreaks?: number;

  typeCodes = shiftTypeCodes.filter((s) => s.canAssign);
  absenceCodes = absenceTypeCodes.filter((s) => !s.timeSheetOnly);
  departmentList: Array<{ code; data: { name; outletType?; outletIndex; subDeptName? }; label }> = null;
  selectedDepartment = 0;

  constructor(private central: CentralDataService, private sentryService: SentryService) {}

  showOpen = false;

  ngOnInit() {}

  ngOnChanges() {
    if (this.shift?.departments) {
      //get row departments
      const genericAgencyShift = this.shift.rows[0].isAgency && !this.shift.rows[0].id;
      let rDeps = genericAgencyShift
        ? undefined
        : [this.shift.rows[0].currentContract.department, ...(this.shift.rows[0].currentContract.otherDepartments || [])];
      //build department list
      this.departmentList = this.shift.departments
        .map((d, i) => {
          return {
            code: i,
            data: {
              name: d.name,
              outletType: d.outletType,
              outletIndex: d.outletIndex,
            },
            label: d.label,
          };
        })
        .filter((d) => {
          if (genericAgencyShift) {
            return true;
          }
          //remove all the ones we don't have on our employee
          let res = !!rDeps.find((rd) => {
            return (
              d.data.name == rd.departmentName &&
              (d.data.outletType ? d.data.outletType == rd.outletType && d.data.outletIndex == rd.outletIndex : !rd.outletType) &&
              (d.data.subDeptName ? d.data.subDeptName === rd.subDeptName : !rd.subDeptName)
            );
          });
          return res;
        });
      //select the correct department
      this.selectedDepartment = this.departmentList.findIndex((d) => {
        return (
          d.data.name == this.shift.departmentName &&
          (d.data.outletType
            ? d.data.outletType == this.shift.outletType && d.data.outletIndex == this.shift.outletIndex
            : !this.shift.outletType) &&
          (d.data.subDeptName ? d.data.subDeptName === this.shift.subDeptName : !this.shift.subDeptName)
        );
      });
      if (this.selectedDepartment < 0) this.selectedDepartment = 0;
      const department = this.departmentList[this.selectedDepartment];
      if (!this.central.isProdOnlyDept(department?.data?.name)) {
        this.typeCodes = shiftTypeCodes.filter((s) => s.canAssign);
      } else {
        this.typeCodes = shiftTypeCodes.filter((s) => s.canAssign && !s.prodOnly);
      }
    } else if (this.shift?.departmentName) {
      if (!this.central.isProdOnlyDept(this.shift.departmentName)) {
        this.typeCodes = shiftTypeCodes.filter((s) => s.canAssign);
      } else {
        this.typeCodes = shiftTypeCodes.filter((s) => s.canAssign && !s.prodOnly);
      }
    }
  }

  hasBreaks() {
    const typeCode = shiftTypeCodes.find(({ code }) => code === this.shift?.detail?.type);
    return typeCode?.hasBreaks;
  }

  saveEdit() {
    if (this.shift.departments) {
      //copy the department data
      const department = this.departmentList?.find((d) => d.code === this.selectedDepartment);
      if (!department) {
        this.sentryService.showAndSendError(
          new Error('Unable to find department'),
          'Error saving shift',
          'Please contact support',
          {
            departmentList: this.departmentList,
            selectedDepartment: this.selectedDepartment,
          },
          'SAVE_SHIFT_ERROR'
        );
        return;
      }
      this.shift.departmentName = department.data.name;
      this.shift.departmentLabel = department.label;
      this.shift.subDeptName = department.data.subDeptName;
      this.shift.outletType = department.data.outletType;
      this.shift.outletIndex = department.data.outletIndex;
      this.shift.detail.departmentName = department.data.name;
      this.shift.detail.subDeptName = department.data.subDeptName;
      this.shift.detail.outletType = department.data.outletType;
      this.shift.detail.outletIndex = department.data.outletIndex;
    } else {
      this.shift.detail.departmentName = this.shift.departmentName;
      this.shift.detail.subDeptName = this.shift.subDeptName;
      this.shift.detail.outletType = this.shift.outletType;
      this.shift.detail.outletIndex = this.shift.outletIndex;
    }
    this.onChange.emit(this.shift);
  }

  typeCodeChanged(event) {
    // remove breaks from the shift start and end if it is not a work shift
    // otherwise add the breaks back in to the length
    if (event.value !== 'WORK' && this.shift?.detail?.breaks > 0) {
      this.oldBreaks = this.shift.detail.breaks;
      this.shift.detail.end = dayjs(this.shift.detail.end).subtract(this.oldBreaks, 'minute').toISOString();
      this.shift.detail.breaks = 0;
    } else if (event.value === 'WORK' && this.oldBreaks > 0 && !this.shift?.detail?.breaks) {
      this.shift.detail.end = dayjs(this.shift.detail.end).add(this.oldBreaks, 'minute').toISOString();
      this.shift.detail.breaks = this.oldBreaks;
      this.oldBreaks = 0;
    }
  }

  cancelEdit() {
    this.onCancel.emit();
  }

  removeEdit() {
    this.onRemove.emit();
  }

  isValidTimeRange() {
    return dayjs(this.shift.detail.start).isBefore(this.shift.detail.end);
  }

  isValid() {
    if (this.shift.detail.type === 'AGENCY' && !this.shift.detail.quantity) {
      return false;
    }
    return (this.shift.detail.type !== 'ABSENCE' || !!this.shift.detail.absenceType) && this.isValidTimeRange();
  }

  departmentChanged(e: any) {}
}
