import { ErrorHandler, Injectable } from '@angular/core';
import { ApolloError, ApolloLink } from '@apollo/client/core';
import { captureException, showReportDialog, EventHint, captureEvent, addBreadcrumb } from '@sentry/angular-ivy';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  static PREV_ERROR_KEY = 'S_PEK';
  static PREV_ERROR_DATE_KEY = 'S_PED';

  errors: string[];
  static warnings = new Set();
  clearTimestamp: number;

  constructor() {
    try {
      this.errors = JSON.parse(localStorage.getItem(SentryErrorHandler.PREV_ERROR_KEY)) || [];
      const rawTimestamp = localStorage.getItem(SentryErrorHandler.PREV_ERROR_DATE_KEY);
      const clearTimestamp = rawTimestamp ? (Number(rawTimestamp) as number) : 0;
      if (Date.now() - clearTimestamp > 1000 * 60 * 24 * 7) {
        localStorage.removeItem(SentryErrorHandler.PREV_ERROR_KEY);
        this.errors = [];
        localStorage.setItem(SentryErrorHandler.PREV_ERROR_DATE_KEY, Date.now().toString());
      }
    } catch (err) {
      console.error(err);
    }
  }

  public static ignoreError(error) {
    const isError =
      error instanceof ApolloError
        ? (message: string) =>
            error.message?.toLowerCase().includes(message.toLowerCase()) ||
            error.networkError?.message?.toLowerCase().includes(message.toLowerCase()) ||
            error.stack?.toLowerCase().includes(message.toLowerCase()) ||
            error.graphQLErrors?.some((err) => err.message?.toLowerCase().includes(message.toLowerCase()))
        : (message: string) =>
            error &&
            ((error.toString && error.toString().toLowerCase().includes(message.toLowerCase())) ||
              (error.stack && String(error.stack).toLowerCase().includes(message.toLowerCase())) ||
              (error.message && String(error.message).toLowerCase().includes(message.toLowerCase())) ||
              (error.originalStack && String(error.originalStack).toLowerCase().includes(message.toLowerCase())));

    const updateWarnings = (message: string, logChance = 1, consoleLogExcessErrors = true) => {
      if (!SentryErrorHandler.warnings.has(message.toLowerCase())) {
        SentryErrorHandler.warnings.add(message.toLowerCase());
        if (logChance === 1 || Math.random() < logChance) {
          captureEvent({
            message: `${message} error`,
            //severity: 'warning',
            extra: { name: error.name, stack: error.stack, originalStack: error.originalStack, message: error.message },
          });
        }
      } else if (consoleLogExcessErrors) {
        console.error(error.name);
      }
    };

    if (error instanceof ApolloError) {
      if (error.networkError) {
        addBreadcrumb({
          type: 'network',
          category: 'api',
          message: `[Network Error] ${error.networkError.stack || error.networkError.message}`,
          data: error.networkError,
        });
        return 'NE';
      }
    }

    if (String(error?.code).toLowerCase().startsWith('auth/')) {
      return 'AU';
    } else if (isError('Password.onKeyup')) {
      updateWarnings('Password.onKeyup');
      return 'PK';
    } else if (isError('Observable cancelled prematurely')) {
      updateWarnings('Observable cancelled prematurely', 0.05, false);
      return 'OCP';
    } else if (isError('The custom token format is incorrect')) {
      updateWarnings('The custom token format is incorrect', 0.05, false);
      return 'CTF';
    } else if (isError('Invariant violation')) {
      updateWarnings('Invariant violation');
      return 'IV';
    } else if (isError('The email address is badly formatted')) {
      return 'EBF';
    } else if (isError('password is invalid')) {
      return 'PI';
    } else if (isError('Extra/unparsed characters found in date')) {
      return 'EUC';
    } else if (isError('Http failure response for https://api')) {
      return 'HFR';
    } else if (isError('User does not have the appropriate rights')) {
      addBreadcrumb({
        type: 'rights',
        category: 'auth',
        message: 'User does not have the appropriate rights',
        data: { error: error.message, stack: error.stack },
      });
      return 'UAR';
    } else if (
      error &&
      error.message &&
      (String(error.message).toLowerCase().startsWith('network error') ||
        String(error.message).toLowerCase().startsWith('http failure response for'))
    ) {
      updateWarnings('Network error');
      return 'NE';
    }
    return undefined;
  }

  private static $operations = new BehaviorSubject<{ operationName; variables? }>(undefined);

  static errorFilter(hint: EventHint) {
    const error = typeof hint.originalException !== 'string' ? hint.originalException : undefined;
    const operation = SentryErrorHandler.$operations.value;
    const lastOperation = operation && {
      operationName: operation.operationName || 'unknown',
      variables: environment.production ? 'unavailable' : operation.variables,
    };
    if (!error) {
      return { accept: true, lastOperation };
    }
    const ignoreError = SentryErrorHandler.ignoreError(error);
    if (ignoreError) {
      return { accept: false, lastOperation, ignoreError };
    }

    return { accept: true, lastOperation };
  }

  static apiLink() {
    return new ApolloLink((operation, forward) => {
      SentryErrorHandler.$operations.next({
        variables: environment.production ? undefined : operation?.variables,
        operationName: operation?.operationName || 'unknown',
      });
      return forward(operation);
    });
  }

  handleError(error) {
    try {
      if (!environment.sentryDsn) {
        console.error(error);
      }
      if (!error) {
        return;
      }
      if (error.originalError && SentryErrorHandler.ignoreError(error.originalError)) {
        return;
      } else if (SentryErrorHandler.ignoreError(error)) {
        return;
      } else {
        const eventId = captureException(error ? error.originalError || error : 'Unknown error');
        if (error?.name !== 'TypeError' && error?.message) {
          // we check against the 10 most recent errors and ignore if it exists
          if (!this.errors.includes(String(error.message).toLowerCase())) {
            showReportDialog({
              eventId,
              title: 'Please report this error',
              subtitle:
                'Complete the details below to report this error to the administrators. If you continue to have issues please email wot@ihg.com.',
              subtitle2: '',
              labelComments: 'Please note down what you were trying to do when this happened.',
              labelClose: 'Ignore',
            });
            this.errors.push(String(error.message).toLowerCase());
            if (this.errors.length > 10) {
              this.errors = this.errors.slice(this.errors.length - 10);
            }
            localStorage.setItem(SentryErrorHandler.PREV_ERROR_KEY, JSON.stringify(this.errors));
            localStorage.setItem(SentryErrorHandler.PREV_ERROR_DATE_KEY, Date.now().toString());
          }
        }
      }
    } catch (err) {
      console.error(err);
    }
  }
}
