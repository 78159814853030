import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { first, map, throttleTime } from 'rxjs/operators';
import { BehaviorSubject, forkJoin, timer } from 'rxjs';
import { UIService } from './ui.service';
import { LocalService } from './local.service';

@Injectable({
  providedIn: 'root',
})
export class VersionCheckService {
  constructor(private http: HttpClient, private uiService: UIService, private local: LocalService) {}

  private $currentVersion = new BehaviorSubject<{ version: string; hash: string; buildDate: string }>({
    version: environment.version,
    hash: environment.hash,
    buildDate: environment.buildDate,
  });

  public getCurrentVersion = () => this.$currentVersion.asObservable();

  public initVersionCheck() {
    if (environment.versionDelay) {
      timer(15000, environment.versionDelay)
        .pipe(throttleTime(5000))
        .subscribe(() => this.checkVersion());
    }
  }

  private checkVersion() {
    forkJoin({
      versionResponse: this.http.get(environment.webUrl + '/version.json?t=' + new Date().getTime()),
      hashResponse: this.http.get(environment.webUrl + '/hash.json?t=' + new Date().getTime()),
    })
      .pipe(
        first(),
        map(({ versionResponse, hashResponse }) => ({
          version: versionResponse && versionResponse['version'],
          hash: hashResponse && hashResponse['hash'],
        }))
      )
      .subscribe(
        ({ version: newVersion, hash: newHashes }) => {
          const { version: currentVersion, hash: currentHash } = this.$currentVersion.value;
          const versionChanged = this.checkVersionChanged(currentVersion, newVersion);
          const hashChanged = this.checkHashChanged(currentHash, newHashes);
          if (versionChanged || hashChanged) {
            if (versionChanged) {
              this.uiService.confirmActionDanger(
                `There is a later version of the app available. You are currently on ${currentVersion} while the latest version is ${newVersion}.`,
                'Reload app',
                () => this.reload()
              );
            } else {
              this.uiService.confirmActionDanger(`The app on the server has changed, please reload.`, 'Reload app', () => this.reload());
            }
            this.$currentVersion.next({ version: newVersion, buildDate: environment.buildDate, hash: newHashes });
          }
        },
        (error) => {
          console.log('unable to check version');
        }
      );
  }

  private reload() {
    this.local.resetStore();
    window.location.reload();
  }

  private checkVersionChanged(currentVersion, newVersion) {
    if (!newVersion || newVersion === environment.version) {
      return false;
    }
    return currentVersion !== newVersion;
  }

  private checkHashChanged(currentHash, newHashes: string[]) {
    if (!newHashes || newHashes.includes(environment.hash)) {
      return false;
    }
    return !newHashes.includes(currentHash);
  }
}
