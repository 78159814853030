import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { LoggingService } from 'src/services/logging.service';

/*
This component shows a large nice version of a menu component
Top level items will show as boxes, within them lie buttons
deeper levels will be exposed as pop ups
*/

@Component({
  selector: 'app-text-filter',
  styleUrls: ['./text-filter.component.scss'],
  templateUrl: './text-filter.component.html',
})
export class TextFilterComponent implements OnInit {
  constructor(private log: LoggingService) {}

  @Input() defaultFilter = '';

  @Output() filterChange = new EventEmitter<any>();
  filterValue = '';

  ngOnInit() {
    if (this.defaultFilter != '') {
      this.filterValue = this.defaultFilter;
      this.filterChange.next(this.filterValue);
    }
  }

  clearFilter() {
    this.filterValue = '';
    this.filterChange.next(this.filterValue);
  }

  changeFilter() {
    this.log.debug('change value ' + this.filterValue);
    this.filterChange.next(this.filterValue);
  }
}
