import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import * as dayjs from 'dayjs';
import { deepClone } from 'src/commontypes/util';
import { AuthService } from 'src/services/auth.service';
import { LoggingService } from 'src/services/logging.service';
import { RegionDataService } from 'src/services/region-data.service';

/*
This component shows a large nice version of a menu component
Top level items will show as boxes, within them lie buttons
deeper levels will be exposed as pop ups
*/

@Component({
  selector: 'app-comment-panel',
  styleUrls: ['./comment-panel.component.scss'],
  templateUrl: './comment-panel.component.html',
})
export class CommentPanelComponent implements OnInit {
  private _dateReference: Date = null;

  @Input()
  get dateReference() {
    return this._dateReference
  }
  set dateReference(date: Date) {
    this._dateReference = date;
    this.loadComments();
  }

  comments = [
  ];



  constructor(private regionData:RegionDataService, private authService:AuthService, private log: LoggingService) { }

  ngOnInit() {

  }

  loadComments()
  {
    this.regionData.getComments(this._dateReference).subscribe((c=>{
      this.comments=deepClone(c);
      this.comments.sort((a,b)=> a.createdAt.localeCompare(b.createdAt));
      this.comments.forEach(c=>{
        c.canEdit=(c.createdBy==this.authService.getUserData().email);
        c.createdAt=Date.parse(c.createdAt);
        this.log.debug('loadComments', c);
      })
      this.addNewComment();
    }))
  }

  addNewComment() {
    this.comments.push({
      createdBy: this.authService.getUserData().email,
      createdAt: Date.now(),
      id: 0,
      text: '',
      editing: true,
      canEdit: true,
    })
  }


  editComment(comment) {
    comment.editing=true;
  }

  saveComment(comment) {
    comment.editing=false;
    comment.createdAt=Date.now();
    if (comment.id==0)//if this was the last (new) one
      this.addNewComment();
    //do the mutation and update the outcomes.
    this.regionData.saveComment(this._dateReference,comment).subscribe((c:any)=>{
      comment.id=c.id;
      comment.createdAt=c.createdAt;
      comment.updatedAt=c.updatedAt;
    });
  }


}
