import { Component, OnDestroy, OnInit } from '@angular/core';
import { ScheduleDataService } from 'src/services/schedule-data.service';

import { UIService } from 'src/services/ui.service';
import { LoggingService } from 'src/services/logging.service';
import { RegionDataService } from 'src/services/region-data.service';
import dayjs from 'dayjs';
import { Router } from '@angular/router';
import { ContextService } from 'src/services/context.service';
import { FullDepartment } from 'src/commontypes/manning';
import { Subject, Subscription, timer } from 'rxjs';
import { first, mergeMap, tap } from 'rxjs/operators';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-performancedashboard',
  styleUrls: ['./performancedashboard.component.scss'],
  templateUrl: './performancedashboard.component.html',
})
export class PerformanceDashboardComponent implements OnInit, OnDestroy {
  isRefreshing = false;
  isLoading = true;
  firstLoad = true;
  weekStart = new Date();
  weekEnd = new Date();
  showTime = 0;
  showTimes = [
    { code: 0, label: 'Week (Mon - Sun)' },
    { code: 1, label: 'Week (Sun - Sat)' },
    { code: 2, label: 'Month' },
  ];
  dateChangeTimer = 0;
  dateChangeTimerID = null;
  yearRange = `${dayjs().subtract(1, 'years').year()}:${dayjs().add(3, 'years').year()}`;
  updated = undefined;
  usedCache = false;

  public departments: FullDepartment[] = [];
  public rows = [];
  public total: any = {};
  private hotel$: Subscription;
  private timer$: Subscription;
  private $refreshData = new Subject();

  constructor(
    private scheduleData: ScheduleDataService,
    private regionData: RegionDataService,
    private log: LoggingService,
    private router: Router,
    public context: ContextService,
    private messageService: MessageService
  ) {}

  ngOnInit() {
    this.hotel$ = this.context.getCurrentBasicHotel$().subscribe((v) => {
      if (!v.id)
        //no hotel yet
        return;
      switch (v.firstWeekday) {
        case 'MONDAY':
          this.showTime = 0;
          break;
        default:
          this.showTime = 1;
          break;
      }
      this.regionData.getFullDepartmentsForHotel().subscribe((data) => {
        this.departments = data;
        this.dateChange();
      });
    });
  }

  refresh() {
    this.isRefreshing = true;
    this.loadData(true);
  }

  refreshData() {
    this.isRefreshing = true;
    this.messageService.clear('bottom-right');
    this.messageService.add({
      id: 'update_schedules',
      key: 'bottom-right',
      severity: 'info',
      summary: 'Checking for new statistics...',
      icon: 'pi-spin pi-spinner',
      sticky: true,
    });
    return this.scheduleData.getCapturedScheduleStatusWithStats(this.weekStart, this.weekEnd).pipe(
      first(),
      tap((newData: any) => {
        this.messageService.clear('bottom-right');
        this.isRefreshing = false;
        if (newData) {
          this.updated = new Date();
          this.usedCache = false;
          this.processData(newData.hotel, newData.data);
        } else {
          this.updated = undefined;
          this.usedCache = false;
          this.processData({}, []);
        }
      })
    );
  }

  loadData(forceReload = false) {
    const clearTimer = () => {
      if (this.timer$) {
        this.timer$.unsubscribe();
        this.timer$ = undefined;
      }
    };

    const handleErrorAndRefresh = () => {
      this.isRefreshing = false;
      this.messageService.clear('bottom-right');
      this.messageService.add({
        id: 'update_schedules',
        key: 'bottom-right',
        severity: 'warn',
        summary: 'Unable to retrieve statistics, will retry later.',
        icon: 'pi-exclamation-triangle',
        sticky: true,
      });
      this.$refreshData.next();
    };

    clearTimer();

    this.rows = [];
    // only query for data if we have departments otherwise clear the data
    this.isLoading = true;
    if (this.departments && this.departments.length > 0) {
      this.scheduleData
        .getCapturedScheduleStatusWithStats(this.weekStart, this.weekEnd, null, true)
        .pipe(first())
        .subscribe((data) => {
          if (data) {
            this.updated = new Date();
            this.usedCache = true;
            this.processData(data.hotel, data.data);
          } else {
            this.rows = [];
            this.total = {};
            this.updated = undefined;
            this.usedCache = false;
          }
          this.firstLoad = false;
          this.isLoading = false;

          // every time we are told to refresh we wait some time and then start the refresh process again
          this.$refreshData.subscribe(() => {
            clearTimer();
            this.timer$ = timer(60 * 1000)
              .pipe(
                first(),
                mergeMap(() => this.refreshData())
              )
              .subscribe(
                () => this.$refreshData.next(),
                () => handleErrorAndRefresh()
              );
          });

          clearTimer();

          // kickstart the refresh process with a shorter timer since our first query uses the cache
          this.timer$ = timer(forceReload ? 10 : 1000)
            .pipe(
              first(),
              mergeMap(() => this.refreshData())
            )
            .subscribe(
              () => this.$refreshData.next(),
              () => handleErrorAndRefresh()
            );
        });
    } else {
      this.processData({}, []);
    }
  }

  processData(hotelDetail, data) {
    //make a row for every department
    this.rows = this.departments
      .filter((dept) => {
        //filter out depart,ents that are not open these months
        return this.isDepartmentOpen(hotelDetail, dept);
      })
      .map((dept) => {
        //filter by department
        let d = data.filter(
          (l) =>
            dept.name === l.departmentName &&
            (dept.outletType ? dept.outletType === l.outletType && dept.outletIndex === l.outletIndex : !l.outletType) &&
            (dept.subDeptName ? dept.subDeptName === l.subDeptName : !l.subDeptName)
        );
        return {
          order: (d && d[0]?.departmentOrder) || dept.defaultOrder,
          department: dept.label,
          fulldepartment: dept,
          classIndex: ['ROOMS', 'F_AND_B', 'OTHER', 'NON_OPS'].findIndex((v) => dept.class == v),
          scheduledHours: d.reduce((a, r) => a + r.scheduledHours, 0),
          actualHours: d.reduce((a, r) => a + r.actualHours, 0),
          forecastHours: d.reduce((a, r) => a + r.forecastHours, 0),
          capturedHours: d.reduce((a, r) => a + r.capturedHours, 0),
          allWOTParts: d.reduce(
            (a, r) => a + (r.forecastReport ? this.scheduleData.calcOptimalHours(r.forecastReport, dept.class, dept.name) : 0),
            0
          ),
        };
      })
      .filter((row) => row.allWOTParts > 0 || row.scheduledHours > 0)
      .sort((a, b) => {
        if (a.classIndex == b.classIndex) return a.order > b.order ? 1 : -1;
        return a.classIndex > b.classIndex ? 1 : -1;
      });
    let nullTot = {
      last: -1,
      scheduledHours: 0,
      actualHours: 0,
      forecastHours: 0,
      capturedHours: 0,
      depCount: 0,
    };
    let last = { ...nullTot };
    this.total = { ...nullTot };
    this.rows.forEach((r) => {
      if (r.classIndex != last.last) {
        last = { ...nullTot }; //clear totals
        r.newDivision = last;
      } else r.newDivision = null;

      last.last = r.classIndex;
      last.depCount += 1;
      last.actualHours += r.actualHours;
      last.forecastHours += r.forecastHours;
      last.capturedHours += r.capturedHours;
      last.scheduledHours += r.scheduledHours;

      this.total.depCount += 1;
      this.total.actualHours += r.actualHours;
      this.total.forecastHours += r.forecastHours;
      this.total.capturedHours += r.capturedHours;
      this.total.scheduledHours += r.scheduledHours;
    });
  }

  isDepartmentOpen(hotel, dept) {
    if (dept.name == 'RESTAURANTS') {
      //we need to check for it and
      let ri = hotel.restaurants.find((out) => out.index == dept.outletIndex);
      if (ri.fullYear) return true; //always open
      let mns = dayjs(this.weekStart).format('MMMM').toLowerCase();
      let mne = dayjs(this.weekEnd).format('MMMM').toLowerCase();
      return ri[mns] || ri[mne]; //is open at the start/end of the week
    } else if (dept.name == 'BARS') {
      //we need to check for it and
      this.log.debug('data', { hotel, dept });
      let ri = hotel.bars.find((b) => b.index == dept.outletIndex);
      if (ri.fullYear) return true; //always open
      let mns = dayjs(this.weekStart).format('MMMM').toLowerCase();
      let mne = dayjs(this.weekEnd).format('MMMM').toLowerCase();
      return ri[mns] || ri[mne]; //is open at the start/end of the week
    } else return true;
  }

  getDivisionName(index) {
    return ['Rooms Division', 'F and B Division', 'Other Operational', 'Non-Operations Division'][index];
  }

  ngOnDestroy(): void {
    if (this.hotel$) {
      this.hotel$.unsubscribe();
      this.hotel$ = undefined;
    }
  }

  dateChange() {
    clearTimeout(this.dateChangeTimerID);
    this.dateChangeTimerID = setTimeout(() => this.dateChangeDebounced(), 1000);
  }

  dateChangeDebounced() {
    switch (this.showTime) {
      case 0:
        this.weekStart = dayjs(this.weekStart).startOf('week').add(1, 'day').toDate();
        this.weekEnd = dayjs(this.weekStart).add(6, 'day').toDate();
        break;
      case 1:
        this.weekStart = dayjs(this.weekStart).startOf('week').toDate();
        this.weekEnd = dayjs(this.weekStart).add(6, 'day').toDate();
        break;
      case 2:
        this.weekStart = dayjs(this.weekStart).startOf('month').toDate();
        this.weekEnd = dayjs(this.weekStart).endOf('month').toDate();
        break;
    }
    this.loadData();
  }

  setToday() {
    this.weekStart = new Date();
    this.dateChange();
  }

  setCal(diff: number) {
    this.weekStart = new Date(+this.weekStart + diff * 24 * 60 * 60 * 1000);
    this.dateChange();
  }

  openDetail(fulldepartment, useActual) {
    this.router.navigate([
      useActual ? '/main/captureshifts' : '/main/scheduleshifts',
      {
        showTime: this.showTime,
        startDate: +this.weekStart,
        department: fulldepartment.name,
        outletIndex: fulldepartment.outletIndex,
        outletType: fulldepartment.outletType,
        subDeptName: fulldepartment.subDeptName,
        backRoute: 'main/performancedashboard',
      },
    ]);
  }
}
