import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import * as dayjs from 'dayjs';
import { UIService } from 'src/services/ui.service';



@Component({
  selector: 'app-confirmation-dialog',
  styleUrls: ['./confirmation-dialog.component.scss'],
  templateUrl: './confirmation-dialog.component.html',
})
export class ConfirmationDialogComponent implements OnInit {

  constructor(public ui:UIService) { }

  ngOnInit() {
  }

  clicked(b) {
    // hide the confirmation first since the command may launch a new confirmation
    this.ui.hideConfirmation();
    if (b.command){
      b.command();
    }
  }


}
