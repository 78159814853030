import { NgModule } from '@angular/core';
import { RouterModule, Routes, Route } from '@angular/router';

import { RouteGuardService } from './../services/route-guard.service';
import { Scope } from './../commontypes/util';
import { Role } from './../services/auth.service';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ManningComponent } from './manning/manning.component';
import { DailyDriversComponent } from './dailydrivers/dailydrivers.component';
import { ScheduleShiftsComponent } from './scheduleshifts/scheduleshifts.component';
import { CaptureShiftsComponent } from './captureshifts/captureshifts.component';
import { ReportExportsComponent } from './reportexports/reportexports.component';
import { StatusDashboardComponent } from './statusdashboard/statusdashboard.component';
import { HelpComponent } from './help/help.component';
import { ReleaseNotesComponent } from './releasenotes/releasenotes.component';

import { PerformanceDashboardComponent } from './performancedashboard/performancedashboard.component';
import { ActualsImportComponent } from './actualsimport/actualsimport.component';
import { DevicesComponent } from './devices/devices.component';

/**
 * we use our own route type to enforce the correct structure on data
 * use 'other' for further data
 */
export interface IRouteData {
  title: string;
  roles?: Role[];
  anyRoles?: Role[];
  other?: { [name: string]: any };
}

interface IAppRoute extends Route {
  data?: IRouteData;
}

const routes: IAppRoute[] = [
  {
    path: 'home',
    component: HomeComponent,
    data: { title: 'Home Page' },
    pathMatch: 'full',
    canActivate: [RouteGuardService],
  },
  {
    path: 'login',
    component: LoginComponent,
    data: { title: 'Welcome' },
    pathMatch: 'full',
  },
  {
    path: 'login/manual',
    component: LoginComponent,
    data: { title: 'Welcome', other: { manual: true } },
    pathMatch: 'full',
  },
  {
    path: 'login/new-password',
    component: LoginComponent,
    data: { title: 'Welcome', other: { newPassword: true } },
    pathMatch: 'full',
  },
  {
    path: 'change-password',
    component: LoginComponent,
    data: { title: 'Change Password', other: { changePassword: true } },
    pathMatch: 'full',
  },
  {
    path: 'change-password/:email',
    component: LoginComponent,
    data: { title: 'Change Password', other: { changePassword: true } },
    pathMatch: 'full',
  },
  {
    path: 'reset-password/request/:reqemail',
    component: LoginComponent,
    data: { title: 'Reset Password', other: { resetPassword: true } },
    pathMatch: 'full',
  },
  {
    path: 'reset-password/set/:token',
    component: LoginComponent,
    data: { title: 'Reset Password', other: { resetPassword: true } },
    pathMatch: 'full',
  },
  // {
  //   path: 'reset-password/set/:token',
  //   component: ResetPasswordComponent,
  //   data: { title: 'Reset Password' },
  //   pathMatch: 'full',
  // },
  {
    path: 'main/manning',
    canActivate: [RouteGuardService],
    component: ManningComponent,
    data: {
      title: 'Hotel Employees',
      anyRoles: [
        [Scope.REGION_ADMIN, '*'],
        [Scope.SCHEDULE_ADMIN, '*'],
        [Scope.SCHEDULE_MANNING, '*'],
        [Scope.SCHEDULE_USER, '*'],
      ],
    },
    pathMatch: 'full',
  },
  {
    path: 'main/devices',
    canActivate: [RouteGuardService],
    component: DevicesComponent,
    data: {
      title: 'Check in Devices',
      anyRoles: [
        [Scope.REGION_ADMIN, '*'],
        [Scope.SCHEDULE_ADMIN, '*'],
      ],
    },
    pathMatch: 'full',
  },
  {
    path: 'main/manning/:personId',
    canActivate: [RouteGuardService],
    component: ManningComponent,
    data: {
      title: 'Hotel Employees',
      anyRoles: [
        [Scope.REGION_ADMIN, '*'],
        [Scope.SCHEDULE_ADMIN, '*'],
        [Scope.SCHEDULE_MANNING, '*'],
        [Scope.SCHEDULE_USER, '*'],
      ],
    },
    pathMatch: 'full',
  },
  {
    path: 'main/help',
    component: HelpComponent,
    data: { title: 'Help and Support' },
    pathMatch: 'full',
    canActivate: [RouteGuardService],
  },
  {
    path: 'releasenotes',
    component: ReleaseNotesComponent,
    data: { title: 'Version Release Notes' },
    pathMatch: 'full',
  },
  {
    path: 'main/driverdaily',
    component: DailyDriversComponent,
    data: {
      title: 'Daily Activity Drivers',
      anyRoles: [
        [Scope.REGION_ADMIN, '*'],
        [Scope.SCHEDULE_ADMIN, '*'],
        [Scope.SCHEDULE_ACTUAL, '*'],
        [Scope.SCHEDULE_FORECAST, '*'],
      ],
    },
    pathMatch: 'full',
    canActivate: [RouteGuardService],
  },
  {
    path: 'main/scheduleshifts',
    component: ScheduleShiftsComponent,
    data: {
      title: 'Scheduled Hours',
      anyRoles: [
        [Scope.REGION_ADMIN, '*'],
        [Scope.SCHEDULE_ADMIN, '*'],
        [Scope.SCHEDULE, '*'],
      ],
    },
    pathMatch: 'full',
    canActivate: [RouteGuardService],
  },
  {
    path: 'main/captureshifts',
    component: CaptureShiftsComponent,
    data: {
      title: 'Actual Hours',
      anyRoles: [
        [Scope.REGION_ADMIN, '*'],
        [Scope.SCHEDULE_ADMIN, '*'],
        [Scope.SCHEDULE_CAPTURE, '*'],
      ],
    },
    pathMatch: 'full',
    canActivate: [RouteGuardService],
  },
  {
    path: 'main/reportexports',
    component: ReportExportsComponent,
    data: {
      title: 'Reports',
      anyRoles: [
        [Scope.REGION_ADMIN, '*'],
        [Scope.SCHEDULE_ADMIN, '*'],
        [Scope.SCHEDULE_CAPTURE, '*'],
        [Scope.SCHEDULE, '*'],
        [Scope.SCHEDULE_MANNING, '*'],
      ],
    },
    pathMatch: 'full',
    canActivate: [RouteGuardService],
  },
  {
    path: 'main/actualsimport',
    component: ActualsImportComponent,
    data: {
      title: 'Import Actuals',
      anyRoles: [
        [Scope.REGION_ADMIN, '*'],
        [Scope.SCHEDULE_ADMIN, '*'],
        [Scope.SCHEDULE_CAPTURE, '*'],
        [Scope.SCHEDULE, '*'],
      ],
    },
    pathMatch: 'full',
    canActivate: [RouteGuardService],
  },
  {
    path: 'main/statusdashboard',
    component: StatusDashboardComponent,
    data: {
      title: 'Status Dashboard',
      anyRoles: [
        [Scope.REGION_ADMIN, '*'],
        [Scope.SCHEDULE_ADMIN, '*'],
        [Scope.SCHEDULE_CAPTURE, '*'],
        [Scope.SCHEDULE, '*'],
      ],
    },
    pathMatch: 'full',
    canActivate: [RouteGuardService],
  },
  {
    path: 'main/performancedashboard',
    component: PerformanceDashboardComponent,
    data: {
      title: 'Performance Dashboard',
      anyRoles: [
        [Scope.REGION_ADMIN, '*'],
        [Scope.SCHEDULE_ADMIN, '*'],
        [Scope.SCHEDULE_CAPTURE, '*'],
        [Scope.SCHEDULE, '*'],
      ],
    },
    pathMatch: 'full',
    canActivate: [RouteGuardService],
  },

  // final fallback
  {
    path: '**',
    redirectTo: '/home',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
