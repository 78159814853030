import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CentralDataService } from 'src/services/central-data.service';
import { ContextService } from 'src/services/context.service';
import { LoggingService } from 'src/services/logging.service';
import { UIService } from 'src/services/ui.service';
import { UserService } from 'src/services/user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  schedulerName = environment.names.scheduler;
  $authRoute = new BehaviorSubject<boolean>(false);
  sub$: Subscription;
  displaySidebar = false;
  available = true;
  closedMessage: string;
  closed = false;
  env = '';

  constructor(
    public contextService: ContextService,
    public route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private log: LoggingService,
    private centralData: CentralDataService,
    private uiService: UIService
  ) {}

  ngOnInit() {
    if (!environment.production) {
      this.env = ` [${environment.sentryEnv === 'develop' ? 'dev' : 'stg'}]`;
    }
    this.sub$ = new Subscription();
    this.sub$.add(
      this.router.events.subscribe((event) => {
        this.displaySidebar = false;
        if (event instanceof NavigationStart) {
          this.$authRoute.next(!UserService.isUnauthenticatedUrl(event.url));
        }
      })
    );
    this.userService.monitorExpiries();
    this.userService.monitorClaimsDeprecated();
    this.$authRoute
      .pipe(
        filter((auth) => auth),
        first()
      )
      .subscribe(() => {
        this.log.debug('auth route update');
        this.contextService.start();
        if (!environment.ignoreUnavailable) {
          this.sub$.add(
            this.centralData
              .watchGetHealth()
              .pipe(filter((health) => this.available !== (health.available && !health.schedulerUnavailable)))
              .subscribe((health) => {
                this.available = health.available && !health.schedulerUnavailable;
                if (this.available) {
                  this.closedMessage = undefined;
                  this.closed = false;
                  this.uiService.acknowledgeAction(
                    `${environment.names.scheduler} connection has been re-established.`,
                    undefined,
                    `${environment.names.scheduler} available`
                  );
                } else if (health.schedulerUnavailable) {
                  this.uiService.acknowledgeError(
                    health.schedulerMessage || `${environment.names.scheduler} is currently unavailable, please wait.`,
                    undefined,
                    `${environment.names.scheduler} unavailable`
                  );
                  this.closedMessage = health.schedulerMessage;
                  this.closed = true;
                } else if (health.error) {
                  this.closedMessage = 'Unable to connect to the Team planner, please check your internet connection.';
                  this.closed = false;
                } else if (!health.available) {
                  this.uiService.acknowledgeError('Team planner is currently unavailable, please check your internet connection.');
                  this.closed = true;
                }
              })
          );
        }
      });
  }

  ngOnDestroy() {
    if (this.sub$) {
      this.sub$.unsubscribe();
      this.sub$ = undefined;
    }
  }

  getRouteTitle() {
    if (this.route.snapshot.firstChild) {
      return this.route.snapshot.firstChild.data.title;
    }
    return '';
  }
}
