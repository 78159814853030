import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { ClipboardModule } from '@angular/cdk/clipboard';

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ApolloModule, APOLLO_OPTIONS } from 'apollo-angular';
import { HttpClientModule } from '@angular/common/http';

import * as Sentry from '@sentry/angular-ivy';

import { provideFirebaseApp, initializeApp, getApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { initializeAppCheck, provideAppCheck, ReCaptchaV3Provider } from '@angular/fire/app-check';
import { getStorage, provideStorage } from '@angular/fire/storage';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SentryErrorHandler } from '../services/sentry-error-handler.service';

import { environment } from './../environments/environment';

//primeng
import { MenuModule } from 'primeng/menu';
import { SidebarModule } from 'primeng/sidebar';
import { ButtonModule } from 'primeng/button';
import { PanelMenuModule } from 'primeng/panelmenu';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { FileUploadModule } from 'primeng/fileupload';
import { PanelModule } from 'primeng/panel';
import { ProgressBarModule } from 'primeng/progressbar';
import { CardModule } from 'primeng/card';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { ToastModule } from 'primeng/toast';
import { DialogModule } from 'primeng/dialog';
import { FieldsetModule } from 'primeng/fieldset';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { InputNumberModule } from 'primeng/inputnumber';
import { CalendarModule } from 'primeng/calendar';
import { MessageService, SharedModule } from 'primeng/api';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { CheckboxModule } from 'primeng/checkbox';
import { SelectButtonModule } from 'primeng/selectbutton';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { DragDropModule } from 'primeng/dragdrop';

//App screens
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ManningComponent } from './manning/manning.component';
import { DailyDriversComponent } from './dailydrivers/dailydrivers.component';
import { ScheduleShiftsComponent } from './scheduleshifts/scheduleshifts.component';
import { CaptureShiftsComponent } from './captureshifts/captureshifts.component';
import { ReportExportsComponent } from './reportexports/reportexports.component';
import { StatusDashboardComponent } from './statusdashboard/statusdashboard.component';

//Generic Components
import { MenuScreenComponent } from './../components/menu-screen/menu-screen.component';

import { MenuButtonComponent } from './../components/menu-button/menu-button.component';
import { DisplayTableComponent } from './../components/display-table/display-table.component';

import { ManningContractComponent } from './../components/manning-contract/manning-contract.component';
import { ScheduledShiftComponent } from './../components/scheduled-shift/scheduled-shift.component';
import { RoleDropDownComponent } from './../components/role-drop-down/role-drop-down.component';
import { FullDepartmentDropDownComponent } from './../components/full-department-drop-down/full-department-drop-down.component';
import { ShiftMarkerComponent } from './../components/shift-marker/shift-marker.component';
import { TimeRangeComponent } from './../components/time-range/time-range.component';
import { AccountComponent } from 'src/components/account/account.component';
import { ShiftDisplayComponent } from 'src/components/shift-display/shift-display.component';
import { CapturedShiftComponent } from 'src/components/captured-shift/captured-shift.component';
import { XLSService } from 'src/services/xls.service';
import { HelpComponent } from './help/help.component';
import { HelpOverlayComponent } from 'src/components/help-overlay/help-overlay.component';
import { PerformanceDashboardComponent } from './performancedashboard/performancedashboard.component';
import { CommentPanelComponent } from 'src/components/comment-panel/comment-panel.component';

//Services
import { ContextService } from './../services/context.service';
import { LocalService } from './../services/local.service';
import { ConfirmationDialogComponent } from 'src/components/confirmation-dialog/confirmation-dialog.component';
import { UIService } from 'src/services/ui.service';
import { VersionCheckService } from './../services/version-check.service';
import { UserService } from '../services/user.service';

//Directives
import { persistLocal } from 'src/directives/persist-local';
import { DayJsPipe } from 'src/directives/dayjspipe';
import { CloneShiftsComponent } from 'src/components/clone-shifts/clone-shifts.component';
import { ActualsImportComponent } from './actualsimport/actualsimport.component';
import { TextFilterComponent } from 'src/components/text-filter/text-filter.component';
import { ReleaseNotesComponent } from './releasenotes/releasenotes.component';
import { DevicesComponent } from './devices/devices.component';
import { DeviceDataService } from 'src/services/device-data.service';
import { CheckInMarkerComponent } from 'src/components/checkin-marker/checkin-marker.component';

@NgModule({
  declarations: [
    AppComponent,
    MenuScreenComponent,
    MenuButtonComponent,
    TextFilterComponent,
    HelpOverlayComponent,
    HelpComponent,
    ReleaseNotesComponent,
    HomeComponent,
    LoginComponent,
    ResetPasswordComponent,
    ManningComponent,
    DevicesComponent,
    ReportExportsComponent,
    ActualsImportComponent,
    StatusDashboardComponent,
    PerformanceDashboardComponent,
    DailyDriversComponent,
    ScheduleShiftsComponent,
    CaptureShiftsComponent,
    AccountComponent,
    ManningContractComponent,
    ConfirmationDialogComponent,
    CapturedShiftComponent,
    ScheduledShiftComponent,
    RoleDropDownComponent,
    FullDepartmentDropDownComponent,
    DisplayTableComponent,
    ShiftMarkerComponent,
    CheckInMarkerComponent,
    ShiftDisplayComponent,
    TimeRangeComponent,
    CommentPanelComponent,
    CloneShiftsComponent,

    persistLocal, //directive
    DayJsPipe, //format pipe
  ],
  imports: [
    //angular
    BrowserModule,
    FormsModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    ClipboardModule,

    //apollo
    ApolloModule,

    //firebase / anglar fire
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    provideAuth(() => getAuth()),
    provideStorage(() => getStorage()),

    ...(environment.appCheckKey //if we have an app check key
      ? [
          provideAppCheck(() =>
            initializeAppCheck(getApp(), {
              provider: new ReCaptchaV3Provider(environment.appCheckKey),
            })
          ),
        ]
      : []),
    //primeng
    ButtonModule,
    CardModule,
    CalendarModule,
    CheckboxModule,
    DialogModule,
    DragDropModule,
    DropdownModule,
    FileUploadModule,
    InputNumberModule,
    InputSwitchModule,
    InputTextModule,
    MultiSelectModule,
    MenuModule,
    SidebarModule,
    PanelMenuModule,
    ProgressBarModule,
    TieredMenuModule,
    PanelModule,
    OverlayPanelModule,
    MessageModule,
    MessagesModule,
    SharedModule,
    TableModule,
    ToastModule,
    TooltipModule,
    FieldsetModule,
    ToggleButtonModule,
    CheckboxModule,
    SelectButtonModule,
  ],
  providers: [
    ContextService,
    LocalService,
    MessageService,
    DeviceDataService,
    XLSService,
    UIService,
    UserService,
    VersionCheckService,
    {
      provide: ErrorHandler,
      useClass: SentryErrorHandler,
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
