import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';

export interface ConfirmOptions {
  title: string;
  icon?: string;
  message: string;
  cssStyle?: string;
  options: {
    text: string;
    icon: string;
    class?: string;
    command?: () => void;
  }[];
}

@Injectable({
  providedIn: 'root',
})
export class UIService {
  confirmOps: ConfirmOptions = null;

  constructor(private messageService: MessageService) {}

  error(message: string, detail: string) {
    this.messageService.add({
      severity: 'error',
      summary: message,
      detail: detail,
      sticky: true,
    });
  }

  info(message: string, detail: string = '') {
    this.messageService.add({
      severity: 'info',
      summary: message,
      detail: detail,
    });
  }

  security(message: string, detail: string = '') {
    this.messageService.add({
      severity: 'warn',
      summary: message,
      detail: detail,
    });
  }

  confirmAction(message: string, onYes: () => void, onNo?: () => void, yesText = 'Yes', noText = 'No', title = 'Confirm') {
    this.showConfirmation({
      title,
      message,
      icon: 'pi-question-circle',
      options: [
        {
          text: yesText,
          icon: 'pi-check',
          command: onYes,
        },
        {
          text: noText,
          icon: 'pi-times',
          class: 'p-button-secondary',
          command: onNo,
        },
      ],
    });
  }

  confirmActionDanger(message: string, actionText: string, onYes: () => void, onNo?: () => void, title = 'Confirm', cancelText = 'Cancel') {
    this.showConfirmation({
      title,
      message,
      icon: 'pi-exclamation-triangle',
      options: [
        {
          text: actionText,
          icon: 'pi-check',
          class: 'p-button-danger',
          command: onYes,
        },
        {
          text: cancelText,
          icon: 'pi-times',
          class: 'p-button-secondary',
          command: onNo,
        },
      ],
    });
  }

  acknowledgeError(message: string, onYes?: () => void, title = 'Error') {
    this.showConfirmation({
      title,
      message,
      icon: 'pi-exclamation-triangle',
      options: [
        {
          text: 'OK',
          icon: 'pi-check',
          class: 'p-button-Primary',
          command: onYes,
        },
      ],
    });
  }

  acknowledgeAction(message: string, onYes?: () => void, title = 'Note') {
    this.showConfirmation({
      title,
      message,
      icon: 'pi-exclamation-triangle',
      options: [
        {
          text: 'OK',
          icon: 'pi-check',
          class: 'p-button-Primary',
          command: onYes,
        },
      ],
    });
  }

  showConfirmation(options: ConfirmOptions) {
    this.confirmOps = options;
  }

  hideConfirmation() {
    this.confirmOps = null;
  }
}
