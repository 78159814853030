import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { first } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { CentralDataService } from 'src/services/central-data.service';
import { UIService } from 'src/services/ui.service';

@Component({
  selector: 'app-help',
  styleUrls: ['./help.component.scss'],
  templateUrl: './help.component.html',
})
export class HelpComponent implements OnInit {
  constructor(private centralDataService: CentralDataService, private ui: UIService, private sanitizer: DomSanitizer) {}

  public languages = [
    { code: 'en', name: 'English' },
    { code: 'ar', name: 'Arabic' },
    { code: 'th', name: 'Thai' },
    { code: 'id', name: 'Bahasa' },
    { code: 'vi', name: 'Vietnamese' },
  ];

  public language = 'en';
  public showUrl = null;
  public showFile = null;
  public showLan = null;

  public helpMenu = [
    {
      label: 'Help And Support',
      items: [
        {
          label: 'The Foundations of Workforce Optimisation',
          icon: 'pi ihg20',
          items: [
            {
              label: 'MyLearning Course',
              icon: 'pi ihg20',
              command: () =>
                this.openWeb(
                  'https://MYLEARNING.sumtotal.host/core/pillarRedirect?relyingParty=LM&url=app%2fmanagement%2fLMS_ActDetails.aspx%3FActivityId%3D123%26UserMode%3D0'
                ),
            },
            { label: 'User Guide', icon: 'pi ihg20', command: () => this.openDoc('foundations', true) },
          ],
        },
        {
          label: `Using the ${environment.names.schedulerFull}`,
          icon: 'pi ihg25',
          items: [
            {
              label: 'MyLearning Course',
              icon: 'pi ihg25',
              command: () =>
                this.openWeb(
                  'https://MYLEARNING.sumtotal.host/core/pillarRedirect?relyingParty=LM&url=app%2fmanagement%2fLMS_ActDetails.aspx%3FActivityId%3D3391%26UserMode%3D0'
                ),
            },
            { label: 'User Guide', icon: 'pi ihg25', command: () => this.openDoc('usingwot', true) },
          ],
        },
        { label: 'Scheduling Fundamentals', icon: 'pi ihg4', command: () => this.openDoc('schedfundamentals', true) },
        { label: 'Building Successful Habits', icon: 'pi ihg31', disabled: true },
        { label: 'User Access - Guidelines', icon: 'pi ihg12', command: () => this.openDoc('useraccess', false) },
        { label: 'Scheduling Process', icon: 'pi ihg33', command: () => this.openDoc('scheduling', false) },
      ],
    },
  ];

  ngOnInit() {}

  langChange() {
    if (this.showUrl) this.showDoc();
  }

  openDoc(file, uselan) {
    this.showFile = file;
    this.showLan = uselan;
    this.showDoc();
  }
  showDoc() {
    const docPath = '/sched_docs/' + this.showFile + (this.showLan ? '_' + this.language : '') + '.pdf';

    this.centralDataService
      .getHelpDocUrl(docPath)
      .pipe(first())
      .subscribe(
        (url) => {
          this.showUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
        },
        (error) => {
          this.ui.error('Error loading document', 'The document you requested is not currently available.');
        }
      );
  }

  openWeb(url) {
    window.open(url);
  }

  closeHelp() {
    this.showUrl = null;
  }
}
