import semver from 'semver';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { LoggingService } from './logging.service';
import { BehaviorSubject } from 'rxjs';
import { UIService } from './ui.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class LocalService {
  constructor(private log: LoggingService, private ui: UIService, private router: Router) {
    this.loadProfile();
  }

  private profileKey: string;
  private static KEY = 'IHGScheduler';
  private _profile: any = {};
  private _globalProfile: any = {};
  private $ready = new BehaviorSubject<boolean>(false);

  public resetStore() {
    this._profile = {};
    localStorage.removeItem(this.profileKey);
  }

  public get(key: string, defaultVal: any = null, global = false) {
    if (global) {
      return this._globalProfile[key] ?? defaultVal;
    }
    if (!this.profileKey) {
      return defaultVal;
    }
    return this._profile[key] ?? defaultVal;
  }

  public set(key: string, value: any, global = false) {
    if (global) {
      if (JSON.stringify(this._globalProfile[key]) !== JSON.stringify(value)) this._globalProfile[key] = value;
      localStorage.setItem(LocalService.KEY, JSON.stringify(this._globalProfile));
    } else if (this.profileKey && JSON.stringify(this._profile[key]) !== JSON.stringify(value)) {
      // only save if things are different
      this._profile[key] = value;
      localStorage.setItem(this.profileKey, JSON.stringify(this._profile));
    }
  }

  private loadProfile() {
    this._profile = {};
    this._globalProfile = {};
    const rawGlobal = localStorage.getItem(LocalService.KEY);
    const globalData = rawGlobal && JSON.parse(rawGlobal);
    if (globalData) {
      this._globalProfile = globalData;
    }
    if (this.profileKey) {
      const rawData = localStorage.getItem(this.profileKey);
      const data = rawData && JSON.parse(rawData);
      if (data) {
        const version = data.__version__;
        // all versions older than 1.0.10 we clear the local storage
        if (!version || (semver.valid(version) && semver.lt(version, '1.0.10'))) {
          localStorage.clear();
        } else {
          this._profile = data;
        }
        if (version !== environment.version) {
          this.ui.confirmActionDanger('A new version of the application is available. View release notes now?', 'Yes', () =>
            this.router.navigate(['/releasenotes'])
          );
        }
      }
      this.set('__version__', environment.version);
      this.$ready.next(true);
    } else {
      this.$ready.next(false);
    }
  }

  isReady() {
    return this.$ready.asObservable();
  }

  setUser(email?: string) {
    if (email) {
      this.profileKey = `${LocalService.KEY}.${btoa(email)}`;
    } else {
      this.profileKey = undefined;
    }
    this.loadProfile();
  }

  getRecentlyUsedList(key: string, maxLength: number = 10): any[] {
    let d = this.get(key);
    return (d && Array.isArray(d) ? d : []).slice(0, maxLength);
  }

  addRecentlyUsedList(key: string, data: any, compare: (d1) => boolean, maxLength = 10) {
    let list = this.getRecentlyUsedList(key);
    this.log.debug(`list ${key}`, list);
    let i = list.findIndex((d) => compare(d)); //check if we already have this one
    if (i >= 0) list.splice(i, 1);
    list.unshift(data);
    list = list.slice(0, maxLength);
    this.set(key, list);
  }
}
