import { Injectable } from '@angular/core';

import { LinkService } from './link.service';
import { ContextService } from './context.service';
import { RegionDataService } from './region-data.service';

import { UIService } from './ui.service';
import { gql } from 'apollo-angular';
import { LoggingService } from './logging.service';
import { first, map, mergeMap, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DeviceDataService {
  constructor(
    private linkService: LinkService,
    private log: LoggingService
  ) {}


  public getDevices() {
    return this.linkService.GQLRegion().pipe(
        switchMap((link) => this.linkService.awaitCurrentHotel(null).pipe(map((hotelId) => ({ hotelId, link })))),
        switchMap(({ link, hotelId }) =>
          link
            .query({
              query: gql`
                query getDevices($hotelId: ID!) {
                  allCheckInDevices(hotelId: $hotelId) {
                    id
                    name
                    description
                    lastCheckIn
                    disabled
                  }
                }
              `,
              variables: {
                hotelId,
              },
              fetchPolicy: 'network-only',
            })
            .pipe(
              first(),
              map(({ data }: any) => data.allCheckInDevices)
            )
        )
      );
    }


  
    createDevice(device: any) {
      let data= {
          name: device.name,
          description: device.description
      };      
      return this.linkService.GQLRegion().pipe(
        first(),
        mergeMap((link) =>
          link
            .mutate({
              mutation: gql`
                mutation createCheckInDevice($hotelId: ID!,$data: CheckInDeviceInput!) {
                  createCheckInDevice(hotelId: $hotelId, data: $data) {
                    id
                    name
                    description
                    lastCheckIn
                    disabled
                  }
                }                
              `,
              variables: {
                hotelId: this.linkService.getCurrentHotelId(),
                data,
              },
            })
            .pipe(map(({ data }: any) => data.createCheckInDevice))
        )
      );
      
  }
  
    
  removeDevice(deviceId: any) {
    return this.linkService.GQLRegion().pipe(
      first(),
      mergeMap((link) =>
        link
          .mutate({
            mutation: gql`
              mutation deleteCheckInDevice($deviceId: ID!) {
                deleteCheckInDevice(deviceId: $deviceId,confirm:true) 
              }                
            `,
            variables: {
              deviceId,              
            },
          })
          .pipe(map(({ data }: any) => data.deleteCheckInDevice))
      )
    );
  }

  updateDevice(device: any) {    
    let data= {
      name: device.name,
      description: device.description,
      disabled: device.disabled,
    };      
    return this.linkService.GQLRegion().pipe(
      first(),
      mergeMap((link) =>
        link
          .mutate({
            mutation: gql`
              mutation updateCheckInDevice($deviceId: ID!,$data: CheckInDeviceUpdate!) {
                updateCheckInDevice(deviceId: $deviceId, data: $data) {
                  id
                  name
                  description
                  lastCheckIn
                  disabled
                }
              }                
            `,
            variables: {
              deviceId:device.id,
              data,
            },
          })
          .pipe(map(({ data }: any) => data.updateCheckInDevice))
        )
    );
  

  }

  generateToken(device:any) {
    return this.linkService.GQLRegion().pipe(
      first(),
      mergeMap((link) =>
        link
          .mutate({
            mutation: gql`
              mutation createCheckInDeviceToken($deviceId: ID!,$start: DateTime) {
                createCheckInDeviceToken(deviceId: $deviceId, start: $start) {
                  accessToken
                }
              }                
            `,
            variables: {
              deviceId:device.id,
              start: (new Date()).toISOString(),
            },
          })
          .pipe(map(({ data }: any) => data.createCheckInDeviceToken))
        )
    );
  
  }
  
  

}
