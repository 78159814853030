
  <p-fieldset>
    <ng-template pTemplate="header"><label><b>{{start|dayjs : 'ddd DD MMM YYYY'}}</b></label></ng-template>

    <div class="p-d-flex p-jc-start p-ai-center">
      <p-inputNumber mode="decimal" [(ngModel)]="startH" styleClass="lightinlinespinner" [showButtons]="true" buttonLayout="vertical" spinnerMode="vertical" (ngModelChange)="startChange()" min="0" max="23" size="1" maxlength="2" [disabled]="disabled"></p-inputNumber>
      <label><b>:</b></label>
      <p-inputNumber mode="decimal" [(ngModel)]="startM" [showButtons]="true" buttonLayout="vertical" spinnerMode="vertical" styleClass="lightinlinespinner" (ngModelChange)="startChange()" [min]="0" [max]="59" [step]="5" size="1" maxlength="2" [disabled]="disabled">
      </p-inputNumber>
      <label><b>&nbsp;-&nbsp;</b></label>
      <p-inputNumber mode="decimal" [(ngModel)]="endH" [showButtons]="true" buttonLayout="vertical" spinnerMode="vertical" styleClass="lightinlinespinner" (ngModelChange)="endChange()" min="0" max="23" size="1" maxlength="2" [disabled]="disabled"></p-inputNumber>
      <label><b>:</b></label>
      <p-inputNumber mode="decimal" [(ngModel)]="endM" [showButtons]="true" buttonLayout="vertical" spinnerMode="vertical" styleClass="lightinlinespinner" (ngModelChange)="endChange()" [min]="0" [max]="59" [step]="5" size="1" maxlength="2" [disabled]="disabled">
      </p-inputNumber>
      <label *ngIf="newDay">({{end|dayjs : 'ddd DD'}})</label>
    </div>
    <div class="p-d-flex p-jc-end p-ai-center">
      <label>Length</label>
      <p-inputNumber mode="decimal" [(ngModel)]="lenH" [showButtons]="true" buttonLayout="vertical" spinnerMode="vertical" styleClass="lightinlinespinner" (ngModelChange)="lenChange()" min="0" max="23" size="1" maxlength="2" [disabled]="disabled"></p-inputNumber>
      <label>hrs</label>
      <p-inputNumber mode="decimal" [(ngModel)]="lenM" [showButtons]="true" buttonLayout="vertical" spinnerMode="vertical" styleClass="lightinlinespinner" (ngModelChange)="lenChange()" [min]="0" [max]="59" [step]="5" size="1" maxlength="2" [disabled]="disabled">
      </p-inputNumber>
      <label>mins</label>
    </div>
  </p-fieldset>
