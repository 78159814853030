export enum AuthErrorType {
  MISSING_DATA = 'missingData',
  WRONG_PASSWORD = 'wrongPassword',
  DISABLED = 'disabled',
  INVALID_EXP = 'invalidExp',
  TOO_MANY_REQUESTS = 'tooManyRequests',
  INTERNAL_ERROR = 'internalError',
}

export class AuthError extends Error {
  constructor(message?: string, private type?: AuthErrorType, public readonly wrappedError?: Error) {
    super(message);
    Object.setPrototypeOf(this, new.target.prototype);
  }
  get errorType() {
    return this.type;
  }
}
